<template>
  <div class="container">
    <Banner :banner="banner" type="robot" />
    <div class="container-1200px">
      <TitleAssembly :title="modular1.title" titleClass="titleStyle1" />
      <div class="dxjqr-md1"></div>
      <div class="flexCenterBtn">
        <apply-dialog buttonColor='slot'>
          <template slot="btn">
            <button class="applyBtn">免费试用</button>
          </template>
        </apply-dialog>
      </div>
      <TitleAssembly :title="modular4.title" style="margin-bottom:40px;" titleClass="titleStyle1" />
      <ImgTextList :ITlistObj="modular4.list" ulListStyle="fourshineList" />
      <div class="flexCenterBtn">
        <apply-dialog buttonColor='slot'>
          <template slot="btn">
            <button class="applyBtn">免费试用</button>
          </template>
        </apply-dialog>
      </div>
    </div>
    <!-- 我们的选择 -->
    <TitleAssembly :title="modular2.title" titleClass="titleStyle1" />
    <div class="our-choice-box">
      <ul class="our-choice container-1200px">
        <li v-for="(item,index) in modular2.list" :key="index">
          <h4 v-if="item.p1">{{item.p1}}</h4>
          <div class="childrenList">
            <p v-for="(it,ind) in item.childrenList" :key="ind" v-html="it"></p>
          </div>
        </li>
      </ul>
    </div>
    <div class="container-1200px">
      <TitleAssembly :title="modular5.title" titleClass="titleStyle1" />
      <ImgTextList :ITlistObj="modular5.list" ulListStyle="zxcjlist" />
      <div class="flexCenterBtn" style="margin-top: 50px;">
        <apply-dialog buttonColor='slot'>
          <template slot="btn">
            <button class="applyBtn">免费试用</button>
          </template>
        </apply-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./components/Banner.vue";
// import UpTitleImgBtn from "@/components/layoutStyle/upTitleImgBtn";
import TitleAssembly from './components/TitleAssembly'
import ImgTextList from "./components/imgTextList";
import { robot } from "@public/static/json/htmlContent";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
export default {
  components: { Banner, ApplyDialog,TitleAssembly, ImgTextList },
  data() {
        return {
              banner: {},
              modular1: robot.modular1,
              modular2: robot.modular2,
              modular4: robot.modular4,
              modular5: robot.modular5,

        };
  },
  created() {
        this.banner = { ...robot.banner };
  },

}
</script>
<style lang="scss">
.our-choice-box{
  background-color: #f9f9f9;
}
.our-choice{
    display: flex;
    justify-content: space-between;
    padding-top:45px;
    li{
      display: flex;
      flex-direction: column;
      width:388px;
      height:446px;
      border-radius: 5px;
      background: url('../../../assets/images/dxjqr/choice.png') no-repeat center 0;
      box-sizing: border-box;
      h4{
        font-size:22px;
        color:#fff;
        height: 83px;
        line-height: 83px;
        margin-top: 15px;
        text-align: center;
      }
      .childrenList{
        font-size: 16px;
        color: #808080;
        margin: 45px 45px 0;
        line-height: 28px;
        p{
          span{
            padding-left: 50px;
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
.flexCenterBtn {
  display: flex;
  justify-content: center;
  margin: 100px auto 70px;
}
.applyBtn {
  width:200px;
  height: 51px;
  font-size: 24px;
  border: 1px solid #1FA3FF;
  background: none;
  color: #1FA3FF;
  cursor: pointer;
  display: block;
  text-align: center;
  line-height: 51px;
  border-radius: 8px;
}
.dxjqr-md1 {
  margin: 100px auto;
  background: url("../../../assets/images/dxjqr/beijing1.png") top center no-repeat;
  width: 687px;
  height:289px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
}
/deep/.upTIB {
  margin-top: 60px;
}
</style>


