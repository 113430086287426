<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPos"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap wx-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="多种场景轻松满足"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <p-lr-module title="全网营销 . 商机在手" :titleText="titleText1" :jsonObj="jsonObj"></p-lr-module> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <show-module title="使用场景" titleText="能满足不同用户的多种场景" :bg="showModuleBg" :liText="liText"></show-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>  
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import ImgList from './components/imgListModule'
import PLrModule from './components/ProductLrModule'
import ShowModule from './components/ShowModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    ImgList,
    PLrModule,
    ShowModule,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/showBanner.png'),
        moduleImg1:require('../../assets/img/show-img1.png'),
        showModuleBg:require('../../assets/img/show-img5.png'),
        titleText1:"场景秀是依托易企秀平台的海量H5模板制作各类精美的H5广告，通过微信，实现产品或服务的推广、用户的引流、数据的管理以及客户的二次营销、续费。",
        listText:[{'title':'极简操作','text':'30秒制作精美H5'},{'title':'海量模板','text':'20万+全行业模板实时更新'} ,{'title':'一站式服务','text':'制作、推广、引流、管理、二次营销'}],
        jsonObj:[
          {title:'在线编辑 • 轻松上手','text':'通过易企秀在线编辑器，对选定的精美模板进行替换或修改，鼠标轻轻拖拽即可进行图文替换编辑，轻轻松松在模板中添加文字、图片、音乐等元素，创作一个H5作品操作就是如此简单，创作成功后即可分享',imgSrc:require('../../assets/img/show-img2.png'),'imgTitle':this.$store.state.imgJson.wxMarket.img1.title,'imgAlt':this.$store.state.imgJson.wxMarket.img1.alt},
          {title:'海量资源 • 快速更新','text':'6大类产品，20多万的海量模板支持全行业应用，优质素材快速更新，满足用户在不同场景、不同用途下的使用需求',imgSrc:require('../../assets/img/show-img3.png'),'imgTitle':this.$store.state.imgJson.wxMarket.img2.title,'imgAlt':this.$store.state.imgJson.wxMarket.img2.alt},
           {title:'客户管理 • 跟进开发','text':'通过场景秀收集的用户数据，系统自动同步到慧营销CRM，快速收集管理高意向客户，销售自主对接客户，快速响应意向客户需求，提升客户的服务满意度',imgSrc:require('../../assets/img/show-img4.png'),'imgTitle':this.$store.state.imgJson.wxMarket.img3.title,'imgAlt':this.$store.state.imgJson.wxMarket.img3.alt}
        ],
        liText:['金融行业','房产行业','机械行业','教育行业','餐饮行业','服务行业']
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss'>
 .btnPos .apply-btn{
   margin-top:420px;
   color:#333;
   border-color:#000;
   font-size:16px;
 }
</style>