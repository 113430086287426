<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPos"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap customer-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="沟通从未如此愉悦"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <p-lr-module title="高效管理 . 智能会话" :jsonObj="jsonObj"></p-lr-module> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <show-module title="使用场景" titleText="在1个工作台上同时处理多渠道客服新消息，避免客服人员后台来回切换，有效提高消息响应速率。" :bg="customerModuleBg" :liText="liText"></show-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>  
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import ImgList from './components/imgListModule'
import PLrModule from './components/ProductLrModule'
import ShowModule from './components/ShowModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    ImgList,
    PLrModule,
    ShowModule,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/customerBanner.png'),
        moduleImg1:require('../../assets/img/customer-img1.png'),
        customerModuleBg:require('../../assets/img/customer-img4.png'),
        titleText1:"场景秀是依托易企秀平台的海量H5模板制作各类精美的H5广告，通过微信，实现产品或服务的推广、 用户的引流、数据的管理以及客户的二次营销、续费。",
        listText:[{'title':'沟通便捷','text':'多种话术内容、多种沟通方式，智能应答'},{'title':'多渠道管理','text':'多公众号便捷对接，沟通信息完整保留'} ,{'title':'智能客服','text':'自动接入、智能分配、智能关怀'}],
        jsonObj:[
          {title:'多公众号• 精细化管理','text':'• 不同公众号的素材、客服话术统一管理<br />• 用户扫描绑定微信公众号，多公众号同时管理，同步公众号粉丝信息<br />• 客服依据标签对粉丝进行分类管理，便于客服精准定位、筛选粉丝群，进行二次营销或售后服务',imgSrc:require('../../assets/img/customer-img2.png'),'imgTitle':this.$store.state.imgJson.customer.img1.title,'imgAlt':this.$store.state.imgJson.customer.img1.alt},
          {title:'智能会话 • 高效沟通','text':'• 微信用户关注公众号、主动咨询客服、触发关键词系统自动发起回复，及时响应，提升客服满意度<br />• 企业定制业务知识库，快捷回复，降低客服人员的学习成本，提升沟通效率',imgSrc:require('../../assets/img/customer-img3.png'),'imgTitle':this.$store.state.imgJson.customer.img2.title,'imgAlt':this.$store.state.imgJson.customer.img2.alt}
        ],
        liText:['教育行业','电子商务','机械行业','服务行业','健身行业','房产行业']
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.customer-module{
  .product-module:last-child{
    margin-top:150px;
  }
}
</style>