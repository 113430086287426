<!-- IA数智 -->
<template>
  <div class='home-container'>
    <div v-for="(item,index) in aiszbannerList" :key="index">
      <div class="AIszBanner" :style="{'background-image':'url('+item.bannerImg+')'}">
        <div style="margin:0 auto;width:1200px;">
          <div class="banner-text">
            <h2>{{ item.title }}</h2>
            <p v-html="item.text"></p>
          </div>
          <button
            class="btnDefault applayBtnAiszBanner mt100"
            @click="$store.commit('CHANGE_MODAL_TRUE')"
          >在线开通</button>
        </div>
      </div>
    </div>    <AiTitle :titleObj='aiszMod1.titleObj'></AiTitle>
    <ul class="con1200 ulflex-between listlicenter2 aiszMd1List"> 
      <li v-for="(item,index) in aiszMod1.list" :key="index"> 
         <img :src="item.img" alt="">
         <h3>{{item.title}}</h3>
         <p>{{item.text}}</p>
      </li>
    </ul>
    <div class="btnCenter mbt100">
      <button class="btnDefault applayBtnHome" @click = "$store.commit('CHANGE_MODAL_TRUE')">立即申请</button>
    </div>
    <AiTitle :titleObj='aiszMod2.titleObj'></AiTitle>
    <ul class="con1200 aiszMd2List"> 
      <li v-for="(item,index) in aiszMod2.list" :key="index"> 
         <h3><em>{{index+1}}</em>{{item.title}}</h3>
         <p v-html="item.text"></p>
      </li>
    </ul>
    <div class="btnCenter mbt100">
      <button class="btnDefault applayBtnHome" @click = "$store.commit('CHANGE_MODAL_TRUE')">立即申请</button>
    </div>

    <div class="aiszMd3List">  
      <AiTitle :titleObj='aiszMod3.titleObj'></AiTitle>
      <ul class="con1200 listlicenter2"> 
        <li v-for="(item,index) in aiszMod3.list" :key="index"> 
          <img :src="item.img" alt="">
         <h3>{{item.title}}</h3>
         <p v-html="item.text"></p>
        </li>
      </ul>
    </div>
    <div class="btnCenter mbt100">
      <button class="btnDefault applayBtnHome mt20" @click = "$store.commit('CHANGE_MODAL_TRUE')">立即申请</button>
    </div>


    <AiTitle :titleObj='aiszMod4.titleObj'></AiTitle>
    <ul class="con1200 ulflex-between listlicenter2 aiszMd4List"> 
      <li v-for="(item,index) in aiszMod4.list" :key="index"> 
         <img :src="item.img" alt="">
         <h3>{{item.title}}</h3>
         <p v-html="item.text"></p>
      </li>
    </ul>
    <div class="btnCenter mbt100">
      <button class="btnDefault applayBtnHome mt20" @click = "$store.commit('CHANGE_MODAL_TRUE')">立即申请</button>
    </div>

    <AiTitle :titleObj='aiszMod5.titleObj'></AiTitle>
    <div class="imgcenter mbt100"> 
      <img :src="aiszMod5.img" alt="">
    </div>


    <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
  </div>
</template>
<script>
import AiTitle from "@views/product/components/aiTitle.vue"
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import { aisz } from "@public/static/json/htmlContent";
import BannerSwiper from '@views/index/components/BannerSwiper.vue'
import {mapState,mapActions } from 'vuex'
export default {
  name:'home',
  components: {
    BannerSwiper,
    ApplyDialog,
    AiTitle
  },
  data() {
     return {
      aiszbannerList:aisz.bannerList,
      aiszMod1:aisz.aiszMod1,
      aiszMod2:aisz.aiszMod2,
      aiszMod3:aisz.aiszMod3,
      aiszMod4:aisz.aiszMod4,
      aiszMod5:aisz.aiszMod5,
     };
  },
  computed: mapState([
    'headParams'
  ]),
  watch: {},
  created() {
    this.GetSystemData();
  },
  mounted() {

  },
  //方法集合
  methods: {
    ...mapActions([
      'GetSystemData', // 将 `this.increment()` 映射为 `this.$store.dispatch('increment')`
    ]),
  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .AIszBanner{
   width:100%;
   height: 578px;
   background-position:center top;
   background-repeat: no-repeat;
}
.banner-text {
  padding-top: 130px;
  h2 {
    margin-bottom: 42px;
    font-size: 48px;
    color: #333;
    line-height: 28px;
    font-weight: bold;
  }
  p{
    font-size:20px;
    color: #333;
    line-height: 40px;
  }
}
 .aiszMd1List{
  margin-top:30px;
  margin-bottom:50px;
  li{
    padding-top:30px;
    width: 286px;
    height: 324px;
  }
}
.aiszMd2List{
  margin:0 auto;
  position: relative;
  background: url('../../assets/images/AIsz/part2.jpg') center;
  width:1210px;
  height:540px;
  li{
    position:absolute;
    h3{
      font-size:20px;
      color:#fff;
      height:40px;
      padding-left:30px;
      em{
        font-weight: bold;
        font-style: italic;
        font-size: 24px;
        padding-right:10px;
      }
    }
    p{
      font-size:14px;
      color:#333;
      line-height: 24px;
      margin-top:10px;
    }
  }
  & li:nth-child(1){
    top:13px;left:500px;
    h3{
      padding-left:0px;
    }
  }
  & li:nth-child(2){
    top:98px;
    left:33px;
  }
  & li:nth-child(3){
    top:145px;
    right:42px;
  }
  & li:nth-child(4){
    bottom:83px;
    left:27px;
    h3{
      padding-left:15px;
    }
  }
  & li:nth-child(5){
    bottom:83px;
    right:33px;
    h3{
      padding-left:15px;
    }
  }
}

.aiszMd3List{
  margin:0 auto;
  background: url('../../assets/images/AIsz/part3.png') center;
  height:441px;
  padding-top:1px;
  ul{
    margin-top:150px;
    display:flex;
    li{
      width:25%;
    }
  }
}
.aiszMd4List{
  li{
    width:290px;
    height: 292px;
    padding-top:42px;
   h3{
    margin:15px 0;
   }
  }
}
</style>