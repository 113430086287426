<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>呼叫中心系统</h4>
        <ul>
          <li>弹性扩充，稳定性高，低成本高转化</li>
          <li>实现一体化的交互、自定义流程设计</li>
          <li>全面数据展现，高效完成客户的沟通</li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="sec1">
      <h1>是否面临这些问题</h1>
      <ul>
        <li v-for="(item, index) in sec1.lis" :key="index">{{ item }}</li>
      </ul>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button>
    <div class="sec2">
      <div>
        <h1>智能化的一站式高效解决方案<br />效能提升 高效管理 及时营销</h1>
        <ul class="ul1">
          <li class="li1" v-for="(item, index) in sec2" :key="index">
            <div>
              <img :src="item.img" alt="" />
              <h2>{{ item.tit }}</h2>
              <p>{{ item.text }}</p>
            </div>
            <ul>
              <li v-for="(item, index) in item.lis" :key="index">{{ item }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button>
    <div class="sec3">
      <h1>呼叫中心系统亮点</h1>
      <ul>
        <li v-for="(item, index) in sec3.lis" :key="index">
          <img
            class="text1"
            src="./../../assets/img/caseImg/cs/sec2.3.png"
            alt=""
          />
          <img class="text2 h2-active" :src="sec3.img3" alt="" />
          <h2>{{ item.tit }}</h2>
          <p>{{ item.text }}</p>
          <img class="p1" :src="sec3.img1" alt="" />
          <img class="p2 h2-active" :src="sec3.img2" alt="" />
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button>
    <div class="sec4">
      <!-- <img src="./../../assets/img/callCenter/4.png" alt=""> -->
      <div>
        <h1>为什么选择我们</h1>
        <ul class="ul1">
          <li class="li1" v-for="(item, index) in sec4" :key="index">
            <img :src="item.img" alt="">
            <ul>
              <li v-for="(item, index) in item.lis" :key="index">{{ item }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button>
    <div class="sec5">
      <h1>核心功能</h1>
      <ul>
        <li v-for="(item, index) in sec5" :key="index">
          <img :src="item.img" alt="" />
          <p>{{ item.text }}</p>
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button>
    <div class="sec6">
      <h1>使用场景</h1>
      <Swiper></Swiper>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button>
    <apply-dialog-btn
      :modelVisible="$store.state.applyModal"
      @cancle="$store.commit('CHANGE_MODAL_FALSE')"
    ></apply-dialog-btn>
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import Swiper from './components/robotSwiper.vue'
export default {
  data() {
    return {
      bannerImg: require("../../assets/img/callCenterBanner.png"),
      sec1: {
        lis: [
          "客户咨询回访量大，多场景需求，用户体量大，人力成本高，流动大。",
          "传统营销方式效率低，上门推广、发传单的模式，受到地域性、人力的限制，很难在短期内快速的挖掘意向客户。",
          "销售及服务过程难把控，不合规词汇层出不穷，客户资料无法保证安全。",
          "手拨电话效率低，无效拨打多，有效通话时长短，销售人员客户撞单难以判断。",
          "坐席响应时间长，业务知识不熟悉，影响客户满意度。",
        ],
      },
      sec2: [
        {
          img: require("./../../assets/img/callCenter/zhinengwaihufuwu.png"),
          tit: "智能外呼服务",
          text:
            "灵活部署，4G全网通与SIP固话信号，通话清晰，有效提高接通率。号码状态检测，筛选出异常号码，提高坐席拨打效率。为客户提供优质来电体验，实现全国分布式坐席即开即用。",
          lis: [
            "队列管理",
            "来电弹屏",
            "多级IVR语音",
            "多层级客户流转",
            "智能语音机器人",
          ],
        },
        {
          img: require("./../../assets/img/callCenter/gaoxiaodezhudongyingxiao.png"),
          tit: "高效的主动营销",
          text:
            "外呼数据批量导入，自定义规则自动分配。通话记录及录音，时刻把握商机。销售业务微信监控，实时了解客户进展，满足企业外呼或更多营销需求。",
          lis: [
            "数据权限设置",
            "自动分配",
            "跟进记录",
            "批量外呼",
            "任务回访计划",
          ],
        },
        {
          img: require("./../../assets/img/callCenter/shujuduoweiduguanli.png"),
          tit: "数据多维度管理",
          text:
            "管理人员可以对在线坐席实时监控，时刻了解工作情况。同时随时查看数据报表统计，针对工作量、满意度、通话时长等进行考核管理。",
          lis: [
            "坐席监控",
            "工作量统计",
            "跟进记录",
            "任务数据统计",
            "通话记录管理",
          ],
        },
      ],
      sec3: {
        img1: require("./../../assets/img/caseImg/cm/sec2.1.png"),
        img2: require("./../../assets/img/caseImg/cm/sec2.2.png"),
        img3: require("./../../assets/img/caseImg/cm/sec2.4.png"),
        lis: [
          {
            tit: "多渠道服务对接",
            text: "电话、微信、网页、APP等多渠道客户请求都能在呼叫中心中接入。",
          },
          {
            tit: "智能语音客服",
            text:
              "进行场景式智能交互，精准理解语义，基于行业提问并自主学习坐席话术。",
          },
          {
            tit: "知识库",
            text:
              "将优秀/问题话务添加知识库，员工可共享知识、规范服务，降低坐席人员学习和培训成本。",
          },
          {
            tit: "来电弹屏",
            text:
              "来电时在服务人员的电脑上弹出客户的详细资料，来电管理系统能更好地对客户关系管理。",
          },
          {
            tit: "自动关怀",
            text: "定期发送关怀短信，电话回访客户，方便客服了解客户的需求。",
          },
          {
            tit: "强大的工单系统",
            text:
              "通话与工单结合，支持工单流程自定义、跨部门流转、多渠道入口统一工单等功能。",
          },
          {
            tit: "电话录音存储",
            text:
              "电话录音实时存储，随时回听，监督工作情况，顺利衔接工作。客户资料、通话记录一端管理。",
          },
          {
            tit: "客户自定义标签",
            text:
              "支持自定义标签，发掘更全面、更个性化的客户信息，提供更具针对性的服务。",
          },
        ],
      },
      sec4: [
        {
          img:require('./../../assets/img/callCenter/01.png'),
          lis: [
            "应用运行在云端，数据存储在企业本地的模式从机制上解决数据安全问题。",
            "用户数据可以分级管理，用户操作数据可以追溯，关键信息只有客户自己能够查看。",
            "人员离职调整数据保护机制，确保外部因素导致的数据安全问题。",
          ],
        },
        {
          img:require('./../../assets/img/callCenter/02.png'),
          lis: [
            "统一定价、公开透明",
            "统一支付、简单方便",
            "网厅支付、安全放心",
          ],
        },
        {
          img:require('./../../assets/img/callCenter/03.png'),
          lis: [
            "免费在线安装指导；免费在线培训；2小时故障承诺",
            "客服中心：全国统一，7*12小时，实时在线",
            "免费升级：功能升级、新增功能、永久免费",
            "技术支持：应用咨询、技术咨询、永久免费",
          ],
        },
      ],
      sec5: [
        {
          img: require("./../../assets/img/callCenter/zhinengtanping.png"),
          text: "智能弹屏",
        },
        {
          img: require("./../../assets/img/callCenter/IVR-yuyin.png"),
          text: "IVR语音",
        },
        {
          img: require("./../../assets/img/callCenter/haomashaixuan.png"),
          text: "号码筛选",
        },
        {
          img: require("./../../assets/img/callCenter/guajiduanxin.png"),
          text: "挂机短信",
        },
        {
          img: require("./../../assets/img/callCenter/shujubaobiao.png"),
          text: "数据报表",
        },
        {
          img: require("./../../assets/img/callCenter/zidongluyin.png"),
          text: "自动录音",
        },
        {
          img: require("./../../assets/img/callCenter/zhinengfenpei.png"),
          text: "智能分配",
        },
        {
          img: require("./../../assets/img/callCenter/laidianshibie.png"),
          text: "来电识别",
        },
        {
          img: require("./../../assets/img/callCenter/zidonglainbo.png"),
          text: "自动连拨",
        },
        {
          img: require("./../../assets/img/callCenter/yijianbohao.png"),
          text: "一键拨号",
        },
      ],
    };
  },
  components: {
    Banner,
    ApplyDialog,
    Swiper,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
h1 {
  font-size: 36px;
  text-align: center;
  margin: 100px 0 50px;
}
.sec1 {
  width: 1200px;
  margin: 0 auto;
  ul {
    width: 100%;
    height: 800px;
    background: url("./../../assets/img/callCenter/sec1.png") no-repeat;
    background-size: 1200px 690px;
    position: relative;
    li {
      width: 337px;
      height: 52px;
      font-size: 20px;
      position: absolute;
    }
    li:nth-of-type(1) {
      top: 15px;
      left: 250px;
      color: #fff;
    }
    li:nth-of-type(2) {
      width: 500px;
      top: 160px;
      left: 180px;
      color: #666;
    }
    li:nth-of-type(3) {
      top: 290px;
      left: 130px;
      color: #fff;
    }
    li:nth-of-type(4) {
      top: 430px;
      left: 240px;
      color: #666;
      width:360px;
    }
    li:nth-of-type(5) {
      width: 360px;
      top: 575px;
      left: 300px;
      color: #fff;
    }
  }
}
.sec2 {
  background: url("./../../assets/img/callCenter/beijing-1.png") no-repeat;
  // background-size: 100% 100%;
  background-position: center;
  padding-top: 50px;
  div {
    width: 1200px;
    margin: 0 auto;
    h1 {
      color: #fff;
    }
    ul.ul1 {
      display: flex;
      justify-content: space-between;
      li.li1 {
        background-color: #fff;
        box-shadow: 0px 2px 10px 0px rgba(94, 105, 169, 0.26);
        border-radius: 8px;
        width: 370px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          width: 310px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 100px;
          }
          h2 {
            font-size: 22px;
            font-weight: bold;
            margin: 20px 0;
          }
          p {
            text-align: center;
            line-height: 30px;
            font-size: 18px;
          }
        }

        ul {
          // position: absolute;
          // bottom: 20px;
          margin-top: 20px;
          background-color: #eef2fc;
          padding: 20px 20px 10px;
          border-radius: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            font-size: 16px;
            margin-bottom: 10px;
            color: #4d7ddd;
          }
        }
      }
    }
  }
}
.sec3 {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    li {
      width: 280px;
      height: 340px;
      // background-color: wheat;
      position: relative;
      border: 1px solid rgb(228, 227, 227);
      overflow: hidden;
      margin-bottom: 30px;
      h2 {
        width: 280px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
      }

      p {
        height: 280px;
        padding: 20px 15px;
        text-align: center;
        line-height: 30px;
        text-align: left;
      }
      img.text1,
      img.text2 {
        width: 280px;
        height: 100px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
      }
      img.p1,
      img.p2 {
        width: 200px;
        position: absolute;
        bottom: 0;
        right: -60px;
      }

      .h2-active {
        display: none;
      }
    }
    li:hover {
      .h2-active {
        display: block;
      }
      h2 {
        color: #fff;
      }
    }
  }
}
.sec4 {
  overflow: hidden;
  width: 100%;
  height: 800px;
  background: url("./../../assets/img/callCenter/beijing-2.png") no-repeat;
  background-size: 1920px 800px;
  background-position: center;
  padding-top: 30px;
  div {
    width: 1200px;
    margin: 0 auto;
    h1 {
      color: #fff;
      margin-top: 20px;
    }
    ul.ul1 {
      position: relative;
      li.li1 {
        img{
          height: 66px;
        }
        ul {
          margin-left: 85px;
          li {
            color: #fff;
            line-height: 30px;
          }
        }
      }
      li.li1:nth-of-type(1) {
        position: absolute;
        top: 80px;
        left: 0;
      }
      li.li1:nth-of-type(2) {
        position: absolute;
        top: 80px;
        left: 60%;
        ul{
          margin-left: 93px;
        }
      }
      li.li1:nth-of-type(3) {
        position: absolute;
        top: 350px;
        left: 0;
      }
    }
  }
}
.sec5 {
  width: 1200px;
  margin: 0 auto;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
      }
      p {
        margin: 20px 0 90px;
      }
    }
  }
}
.sec6 {
  width: 1200px;
  margin: 0 auto;
}
</style>
