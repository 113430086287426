import axios from 'axios'
export default {
    fetchSaveChannel(params) {
        return axios.post('/hzk_applyChan.do', ObjectToUrlString(params))
    },
    fetchSaveCompany(params) {
        return axios.post('/hzk_applyComp.do', ObjectToUrlString(params))
    },
    fetchBaidu(params){
      return axios.post('/hzk_baidu.do', ObjectToUrlString(params))
    }
}
function ObjectToUrlString(param) {
    let keys = Object.keys(param);
    let values = Object.values(param);
    let paramArray = [];
    for(let i=0;i<keys.length;i++) {
        paramArray.push(keys[i]+"="+values[i])
    }
    return paramArray.join("&");
}