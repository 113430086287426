<template>
  <div>
    <div class="LoopListProd">
      <h4>{{loopListProd.topic}}</h4>
      <ul>
        <li v-for="(item,index) in loopListProd.list" :key="index">
          <div class="looplist">
            <h5 v-if="item.h5">{{item.h5}}</h5>
            <TopicBg v-else :title="item.title"></TopicBg>
            <p v-for="(itm,idx) in item.li" :key="idx+'o'">{{itm}}</p>
            <div v-if="loopListProd.type">
              <router-link :to="item.link">了解详情</router-link>
              <router-link to="/">开通申请</router-link>
            </div>
          </div>
          <div class="loopimg">
            <img :src="item.src" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import TopicBg from "./TopicBg";
export default {
  components: {
    TopicBg
  },
  data() {
    return {};
  },
  props: {
    loopListProd: Object
  }
};
</script>
<style lang="scss" scoped>
.home-container {
  .LoopListProd h4 {
    font-weight: 600;
  }
  .looplist h5 {
    font-size: 30px;
  }
}
.LoopListProd {
  margin-top: 30px;
}
.LoopListProd h4 {
  text-align: center;
  color: #333;
  font-size: 30px;
  font-weight: normal;
  line-height: 60px;
}
.LoopListProd ul {
  margin-top: 40px;
}
.LoopListProd ul li {
  display: flex;
  margin-bottom: 84px;
  justify-content: space-between;
}
.LoopListProd ul li:nth-child(even) {
  flex-direction: row-reverse;
}
.looplist {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loopimg {
  width: 650px;
}
.looplist p {
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  line-height: 36px;
}
.looplist h5 {
  font-size: 34px;
  font-weight: 400;
  color: #333;
  margin-bottom: 30px;
}
a {
  color: #4db8fb;
  margin-right: 10px;
}
</style>
