<!-- 电销线路 -->
<template>
  <div class="dx">
    <Banner :bgImg="bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>电销线路</h4>
        <ul>
          <li>拥有多条优质线路，规避封号风险，</li>
          <li>企业呼叫中心、业务系统统一接入运营商中继线，</li>
          <li>多种通信方式任选择。</li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="sec1">
      <div class="tit">企蜂线路优势</div>
      <ul>
        <li v-for="(item, index) in sec1List" :key="index">
          <img :src="item.img" alt="" />
          <h2>{{ item.h2 }}</h2>
          <p>{{ item.p }}</p>
        </li>
      </ul>
    </div>
    <button
      class="applayBtnRed"
      :style="{
        color: '#5b94f8',
        backgroundColor: '#fff',
        border: '2px solid #5b94f8',
      }"
      @click="$store.commit('CHANGE_MODAL_TRUE')"
    >
      开通申请
    </button>
    <div class="sec2">
      <div>
        <div class="tit">高效解决四个方面问题</div>
        <div class="sec2_content">
          <ul>
            <li v-for="(item, index) in sec2List" :key="index">{{ item }}</li>
          </ul>
          <img src="../../assets/img/caseImg/dx/center.png" alt="" />
        </div>
      </div>
    </div>
    <div class="sec3">
      <div class="tit">智能化呼叫中心</div>
      <ul>
        <li v-for="(item, index) in sec3List" :key="index">
          <img :src="item.img" alt="" />
          <p>{{ item.text }}</p>
        </li>
      </ul>
    </div>
    <button
      class="applayBtnRed"
      :style="{
        color: '#5b94f8',
        backgroundColor: '#fff',
        border: '2px solid #5b94f8',
      }"
      @click="$store.commit('CHANGE_MODAL_TRUE')"
    >
      开通申请
    </button>
    <apply-dialog-btn
      :modelVisible="$store.state.applyModal"
      @cancle="$store.commit('CHANGE_MODAL_FALSE')"
    ></apply-dialog-btn>
  </div>
</template>

<script>
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";

export default {
  data() {
    return {
      bannerImg: require("../../assets/img/caseImg/dx/topBanner.png"),
      sec1List: [
        {
          img: require("../../assets/img/caseImg/dx/1.png"),
          h2: "全国落地",
          p: "全国省份额重点城市运营商落地资源",
        },
        {
          img: require("../../assets/img/caseImg/dx/2.png"),
          h2: "混合组网",
          p: "不同运营商和线路类型混合组网接入",
        },
        {
          img: require("../../assets/img/caseImg/dx/3.png"),
          h2: "弹性扩容",
          p: "根据业务需求可弹性扩容及时生效",
        },
        {
          img: require("../../assets/img/caseImg/dx/4.png"),
          h2: "在线调度",
          p: "在线调度不同地区的线路路由和指向",
        },
      ],
      sec2List: [
        "线路稳定，外呼次数效率比普通电话翻倍，可根据企业需求，设定相应的线路拨打时间。",
        "主叫变成接电话，销售员每天更换为接电话模式，避免高频呼出容易封卡问题，降低风险。",
        "针对有需求的企业，全国归属地可选，号码可定制，号码池可更新，接通率大大增加，工作效率提高。",
        "CRM管理模式，权限分明，方便简单操作，有利于企业电销团队的管理，客户资源整理更方便。",
      ],
      sec3List: [
        {
          img: require("../../assets/img/caseImg/dx/5.png"),
          text:
            "为企业用户提供免费测试服务+免费技术对接服务+免费售后跟踪服务。支持VOS/SIP协议对接等对接方式，可体验精准营销带来的业绩倍增。",
        },
        {
          img: require("../../assets/img/caseImg/dx/6.png"),
          text:
            "只需一个按钮就可以实现呼叫客户电话。同时，实现多地接多个号码转换。系统为电销人员设置任务呼叫，一个客户沟通结束后，系统自动进行下一个呼叫，坐席只需等待电话接通即可。",
        },
        {
          img: require("../../assets/img/caseImg/dx/7.png"),
          text:
            "支持每个电话沟通状态记录，客户忙、客户无意向、空号、关机、停机等状态都可以实现标记，方便销售每一条线索跟进。",
        },
        {
          img: require("../../assets/img/caseImg/dx/8.png"),
          text:
            "可视化任务进度，销售人员可以看到自己的外呼任务情况，实时了解任务进展，更高效的沟通工作。 电销CRM系统，自定义弹屏字段，可以沉淀用户关键有效信息，助力企业进行客户分级管理，进行行之有效的用户运营与维护，及时将潜在用户转化为客户。",
        },
      ],
    };
  },
  components: {
    Banner,
    ApplyDialog,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.dx {
  .banner-text {
    padding-top: 120px;
    color: #fff;
    h4 {
      font-size: 40px;

      line-height: 80px;
    }
    li {
      // height:50px;
      font-size: 18px;
      line-height: 36px;
    }
    li.tit1 {
      font-size: 26px;
      margin-bottom: 10px;
    }
    p {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
  .tit {
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
  }
  .sec1,
  .sec3 {
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
  }
  .sec1 {
    ul {
      display: flex;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        h2 {
          font-weight: bolder;
          padding: 20px 0;
        }
      }
    }
  }
  .sec2 {
    padding: 70px 0;
    background: url("./../../assets/img/caseImg/dx/centerBanner.png") no-repeat;
    background-size: cover;
    color: #fff;
    & > div {
      width: 1200px;
      margin: 0 auto;
      div.sec2_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul {
          li {
            list-style: outside;
            padding: 20px 0;
          }
        }
        img {
          width: 512px;
          margin-top: -100px;
          margin-left: 200px;
        }
      }
    }
  }
  .sec3 {
    overflow: hidden;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 43%;
        display: flex;
        align-items: flex-start;
        padding: 20px 0;
        img {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>