<!-- banner -->
<template>
  <div class="banner" :style="{'background-image':'url('+bgImg+')',backgroundPosition:'center','color':bgColor}">
      <div class="banner-wrap">
        <slot></slot>
      </div>
  </div>
</template>

<script>

export default {
  name:'',
  components: {}, 
  props:{
    bgImg:String,
    bgColor:String,
  },
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  methods:{

  }
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .banner{
    // background:url('../../assets/img/homeBanner.png') center center;
    background-position: center top;
    height: 575px;
    background-repeat:no-repeat;
    .banner-wrap{
      position:relative;
      margin:0 auto;
      width:1200px;
      height:575px; 
      img{
        width: 100%;
        position: absolute;
        bottom:0;
        right:0;
      }
    }
  }

  
</style>