<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="dxxlBanner.bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>{{ dxxlBanner.h3 }}</h4>
        <ul>
          <li v-for="(item, index) in dxxlBanner.ul" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="dxxl1">
      <h1 class="bigTitle">{{ dxxlMod1.title }}</h1>
      <ul>
        <li
          v-for="(item, index) in dxxlMod1.list"
          :key="index"
          :style="{ 'background-image': 'url(' + item.imgsrc + ')' }"
        >
          <div>
            <h3>{{ item.h4 }}</h3>
            <p>{{ item.p }}</p>
          </div>
        </li>
      </ul>
    </div>
    <h1 class="bigTitle" v-html="dxxlMod2.title"></h1>
    <ListMoDule
      :listdata="dxxlMod2.list"
      listClassName="listDxxl2"
    ></ListMoDule>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>

    <h1 class="bigTitle" v-html="dxxlMod3.title"></h1>
    <ListMoDule
      :listdata="dxxlMod3.list"
      listClassName="listDxxl3"
    ></ListMoDule>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>

    <div class="listDxxl4">
      <div>
        <h1>{{ dxxlMod4.title }}</h1>
        <ul class="ul1">
          <li class="li1" v-for="(item, index) in dxxlMod4.list" :key="index">
            <img :src="item.img" />
            <h3>{{ item.h4 }}</h3>
            <p>{{ item.p }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import Swiper from "@views/product/components/robotSwiper.vue";
import { dxxl } from "@public/static/json/htmlContent";
import ListMoDule from "@/list.vue";

export default {
  data() {
    return {
      dxxlBanner: dxxl.banner,
      dxxlMod1: dxxl.modular1,
      dxxlMod2: dxxl.modular2,
      dxxlMod3: dxxl.modular3,
      dxxlMod4: dxxl.modular4,
    };
  },
  components: {
    Banner,
    ApplyDialog,
    Swiper,
    ListMoDule,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    // list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.dxxl1 {
  width: 1200px;
  margin: 0 auto;
  ul {
    display: flex;
    justify-content: center;
    li {
      width: 387px;
      height: 284px;
      background-repeat: no-repeat;
      margin: 0 10px;
      div {
        margin: 75px 0 0 50px;
        width: 180px;
      }
      h3 {
        margin-bottom: 10px;
        font-size: 20px;
        color: #3f85ff;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        color: #666;
      }
    }
  }
}
.listDxxl4 {
  overflow: hidden;
  width: 100%;
  height: 550px;
  background: url("../../assets/images/dxxl/tu3.png") no-repeat;
  padding-top: 60px;
  div {
    width: 1200px;
    margin: 0 auto;
    h1 {
      color: #fff;
      font-size: 30px;
      margin-top: 20px;
      font-weight: bold;
      text-align: center;
    }
    .ul1 {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      li {
        background: #fff;
        width: 290px;
        height: 334px;
        border-radius: 8px;
        box-shadow: -2px 0px 18px 2px rgba(119, 141, 251, 0.31);
        img {
          display: block;
          margin: 44px auto 0;
          width: 100px;
        }
        h3 {
          font-size: 20px;
          color: #1262d4;
          line-height: 70px;
          text-align: center;
          font-weight: bold;
        }
        p {
          margin: 0 33px;
          text-align: center;
        }
      }
    }
  }
}
</style>