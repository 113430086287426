<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="wxyxBanner.bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>{{ wxyxBanner.h3 }}</h4>
        <ul>
          <li v-for="(item, index) in wxyxBanner.ul" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <h1 class="bigTitle" v-html="wxyxMd1.title"></h1>
    <div class="wxyxMd1">
      <ul>
        <li v-for="(item, index) in wxyxMd1.list" :key="index">
          <h3>{{item.h3}}</h3>
          <p>{{item.p}}</p>
        </li>
      </ul>
    </div>
    <div class="wxyxMd2">
      <h1 class="bigTitle"  v-html="wxyxMd2.title"></h1>
      <img :src="wxyxMd2.imgsrc" />
    </div>

    <div class="wxyxMd3">
      <h1 class="bigTitle" v-html="wxyxMd3.title"></h1>
      <ul>
        <li v-for="(item, index) in wxyxMd3.list" :key="index">
          <img :src="item.imgsrc" />
          <div class="text">
            <h3>{{ item.h4 }}</h3>
            <div v-for="(it, idx) in item.plist" :key="idx">
              <span>{{ it.span || "" }}</span>
              <p v-html="it.p"></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="btnArea wxyxbtnArea"><apply-dialog :buttonColor="'blueline'"></apply-dialog></div>
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ListMoDule from "@/list.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import Swiper from "@views/product/components/robotSwiper.vue";
import { wxyx } from "@public/static/json/htmlContent";
export default {
  data() {
    return {
      wxyxBanner: wxyx.banner,
      wxyxMd1: wxyx.modular1,
      wxyxMd2: wxyx.modular2,
      wxyxMd3: wxyx.modular3,
    };
  },
  components: {
    Banner,
    ApplyDialog,
    Swiper,
    ListMoDule,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    // list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.wxyxMd1{
  margin:0 auto;
  width:1116px;
  height:562px;
  background:url("../../assets/images/wxyx/bg.png") left center no-repeat;
  ul {
    position: relative;
   li{
     width:472px;
     height:184px;
     padding:20px 38px;
     box-sizing: border-box;
     position: absolute;
     color:#fff;
     h3{
       font-size:24px;
       margin-bottom:10px;
     }
     p{
       width:414px;
     }
   }
   li:nth-child(1){
     top:0;
     left:0;
   }
    li:nth-child(2){
      top:265px;
      right:0px;
   }
   li:nth-child(3){
     top:395px;
     left:80px;
   }
  }
}
.wxyxMd2 {
  width: 1200px;
  margin: 0 auto;
  img {
    display: block;
    margin: 80px auto 0;
  }
}

.wxyxMd3 {
  width: 1200px;
  margin: 100px auto 0;
  h1 {
    color: #333;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
  }
  ul {
    margin-top: 100px;
    li {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      margin-bottom: 100px;
      img {
        // margin-top: 50px;
      }
      h3 {
        margin-top:20px;
        font-size: 28px;
        margin-bottom: 20px;
        // background: linear-gradient(41deg,#09bbfe 0%, #1684f4 100%);
        // border-radius: 30px 0px 30px 0px;
        color: #333333;
        width: fit-content;
      }
      .text {
        width: 550px;
        span {
          margin-top: 20px;
          padding-left:25px;
          background:url("../../assets/images/wxyx/icon.png") left center no-repeat;
          color: #21CEC6;
          display: block;
        }
        p {
          font-size: 16px;
          color: #808080;
          padding-left:25px;
       
        }
      }
    }
    li:nth-child(odd) {
      flex-direction: row-reverse;
    }
    li:nth-child(2){
      em{
        color:#21CEC6;
      }
    }
  }
}
.crm5 {
  .tp {
    font-size: 18px;
    color: #999;
    text-align: center;
    margin-top: -20px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
</style>
<style lang='scss'>
.wxyxMd3{
  ul{
  li:nth-child(2){
    p{
      background:url("../../assets/images/wxyx/icon.png") left center no-repeat;
    }
      em{
        color:#21CEC6;
      }
    }
  }
}
.wxyxbtnArea{
  .applayBtnBlue{
    border-color:#21CEC6;
    color:#21CEC6;
  }
}
</style>