<template>
    <div class="">
      <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> 
        <div class="btnRobot"><apply-dialog></apply-dialog></div>
      </Banner>
      <div class="robotImglist">
        <h4>企业销售是否面临以下问题</h4>
        <img :src="moduleImg1" alt="">
        <ul>
          <li v-for="(item,index) in listText" :key="index">
            <span>{{item.title}}</span>
            <p>{{item.text}}</p>
          </li>
        </ul>
        <div class="tips">这些问题不解决，企业业绩永远原地踏步</div>
        <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
       </div>
       <div class="solveProblem-wrap">
         <div class="solveProblem">
            <div class="solveTitle">
              <h5>慧营销电销系统帮您解决难题</h5>
              <p>让电销更简单，更轻松，更高效</p>
            </div>
            <ul>
              <li v-for="(item,index) in solveList" :key="index">
                <!-- <span>{{item.title}}</span> -->
                <p>{{item.text}}</p>
              </li>
            </ul>
         </div>
          <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
       </div>
       <div class="youshi">
         <h5>核心优势</h5>
          <ul>
            <li v-for="(item,index) in youshiList" :key="index">
              <span>{{item.title}}</span>
              <p>{{item.text}}</p>
            </li>
          </ul>
          <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
       </div>
      <div class="container-1200px">
        <div class="scene">
          <h4>应用场景</h4>
              <div style="width:1200px;margin:40px auto;">
        <Swiper></Swiper>
      </div>
        </div>
      </div>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn
        :modelVisible="$store.state.applyModal"
        @cancle="$store.commit('CHANGE_MODAL_FALSE')"
      ></apply-dialog-btn>
    </div>
</template>
<script>
import Banner from '@/modudel/Banner.vue'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
import ImgList from './components/imgListModule'
import Swiper from './components/robotSwiper.vue'
export default {
    name: "robot",
    components: {
      Banner,
      ApplyDialog,
      ImgList,
      Swiper
    },
    props:{},
    data() {
      return {
        bannerImg:require('../../assets/img/robot-banner.png'),
        moduleImg1:require('../../assets/img/robot-img1.png'),
        listText:[{'title':'工作效率低','text':'海量线索过滤难度大，客户难寻，挂断率高，人工时间限制'},{'title':'员工管理困难','text':'员工工作标准不统一，客户记录不规范，工作情绪波动大'} ,{'title':'人工成本高','text':'员工流动大，培训时间成本高，新人难培养，老人留不住'}],
        solveList:[{title:'CRM分析海量数据',text:'自动判断客户意向后划入资源库，分析拨打数据、通话时长、通话状态，数据统计高效、完整。'},{title:'自主营销话术配置',text:'根据用户实际情况方便快捷设置业务话术，训练系统，效率翻倍。'},
        {title:'自动外呼筛选',text:'自动拨打1000-1200通/天，自动客户筛选、分类记录，清晰明确，便于跟进。工作100%热情，无需人工培训。'},{title:'智能语音识别对答',text:'响应快达400-700毫秒，模拟精英与目标客户进行通话，回答多变，灵活性高，高度还原真实对话场景。'}
        ],
        youshiList:[{title:'流畅智能的对话体验',text:'有效的对话管理技术，多轮会话、知识库等对话管理模块。高度仿真人与人的对话方式，媲美真人语音合成，控制人机对话的过程'},
        {title:'标杆级客户体验',text:'7*12小时接待服务，99%接待率，重新定义用户体验，提高用户满意度，赢得忠实用户。'},
        {title:'强大的研发技术',text:'自主研发的ASR语育识别，NLP语义理解，DM技术，响应快达400-700毫秒，达到业界先进水平。帮助销售初筛客户，节省时间。AI自主学习,发现记录问题录音，高效工作、优化话术。'},
        {title:'私有化部署',text:'可以提供私有化部署方案，私人助理支持个人私人话术，私人录音，筛选、跟进统一声音，以更好地满足客户对数据安全需求。同时可以对企业自有平台进行智能化升级，大幅提升原有平台效率。'}],
        hxzb: {
        sceneList: [
          {
            title: "电商",
            src: require("../../assets/img/hxzb-img8.png"),
            p: "（营销、推广、广告、客户咨询）",
            icon:require('../../assets/img/robot-img4.png'),
          },
          {
            title: "教育",
            src: require("../../assets/img/hxzb-img9.png"),
            p: "（邀约、通知、营销、客户咨询）",
            icon:require('../../assets/img/robot-img5.png'),
          },
          {
            title: "房地产",
            src: require("../../assets/img/hxzb-img10.png"),
            p: "(营销、邀约、调查、装修、中介）",
            icon:require('../../assets/img/robot-img6.png'),
          },
        ]
      },
     }
    },
    created(){},
    mounted(){},
    methods: {}
}
</script>

<style lang="scss">
  .btnRobot .apply-btn{
    margin-top:350px;
    color:#fff;
    border-color:#fff; 
    font-size:16px;
    height:50px;
    line-height:50px;
    border-width:1px;
    border-radius:5px;
    margin-left:20px;
  }
   .robotImglist{
     margin:0 auto;
     width:1200px;
     img{
       display:block;
       margin:0 auto;
       width:1156px;
     }
     .tips{
       font-size:30px;
       color:#f57a21;
       line-height: 184px;
       text-align: center;
     }
     ul{
       margin-top:20px;
       display:flex;
       justify-content: space-around;
       li{
         width:316px;
         span{
           display: block;
          font-size:24px;
          font-weight: bold;
          text-align: center;
         }
         p{
           text-align: center;
           color:#666666;
           font-size:20px;
           line-height:35px;
         }
       }
       li:last-child{
         padding-left:10px;
         span{
           padding-left:5px; 
         }
       }
     }
   }
   .robotImglist h4{
    text-align: center;
    font-size: 36px;
    color: #333;
    height: 60px;
    margin: 80px 0 30px 0;
   }
   .solveProblem-wrap{
     background:url(../../assets/img/robot-img2.png) center top no-repeat;
     height:1065px;
     .solveProblem{
       margin:0 auto;
       width:1200px;
       .solveTitle{
         padding-top:85px;
         text-align: center;
         color:#fff;
         h5{
           font-size:36px;
         }
         p{
           font-size:22px;
         }
       }
       ul{
         margin-top:75px;
         display:flex;
         flex-wrap:wrap;
         li{
            font-size:20px;
            color:#fff;
            width:600px;
            height:300px;
            p{
              width:310px;
              margin-top:108px;
              box-sizing: content-box;
              line-height:32px;
            }
         }
         li:nth-child(2){
           p{
             padding-left:204px;
             margin-top:100px;
           }
         }
         li:nth-child(3){
           p{
             padding-left:95px;
           }
         }
         li:nth-child(4){
           p{
             padding-left:260px;
             margin-top:100px;
             
           }
         }
       }
     }
   }
   .youshi{
     margin:0 auto;
     width:1200px;
     h5{
       text-align: center;
       line-height:216px;
       font-size:36px;
       color:#333
     }
     ul{
        background:url(../../assets/img/robot-img3.png) left top no-repeat;
        height:400px;
        display: flex;
        flex-wrap:wrap;
        li{
          width:600px;
          padding-left:103px;
          height:230px; 
          span{
            display:block;
            font-weight: bold;
            font-size:22px;
          }
          p{
            font-size:20px;
            color:#666;
            line-height:32px;
            margin-top:5px;
          }
        }
        li:nth-child(even){
          padding-left:130px;
        }

     }
   }
.container-1200px {
  width: 1200px;
  margin: 0 auto;
  font-family: "Microsoft YaHei", "Arial Narrow", Arial, sans-serif;
}
   .scene {
    margin: 30px 0;
    p.whyText {
      font-size: 16px;
      color: #333;
      line-height: 48px;
      margin-bottom: 60px;
    }
    h4 {
      font-size: 30px;
      font-weight: normal;
      color: #333;
      margin: 100px 0 20px;
      text-align: center;
    }
    ul {
      display: flex;
      li {
        position: relative;
        width: 400px;
        height: 400px;
        cursor: pointer;
        overflow: hidden;
        h5,
        h6 {
          font-size: 24px;
          color: #fff;
          font-weight: normal;
        }
        h5 {
          position: absolute;
          height: 50px;
          line-height: 50px;
          text-align: center;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          width: 300px;
        }
        & > div {
          position: absolute;
          top: 400px;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          width: 400px;
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: top 1s;
          .box {
            padding-bottom:50px;
            .icon{
              display:block;
              margin: 0 auto;
              width:80px;
            }
            h6 {
              margin: 0 auto;
              height: 40px;
              line-height: 40px;
              width: 186px;
              font-weight: normal;
              text-align: center;
            }
            p {
              padding: 10px 25px;
              font-size:18px;
              color: #fff;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }
      li:hover > div {
        top: 0;
      }
      li:hover h5 {
        display: none;
      }
    }
  }
   
</style>
