<!--  -->
<template>
  <div class='whySelect'>
    <h4 class="whyTitle">为什么选择企蜂</h4>
    <p class="whyText">当信息化已经成为大中型企业的核心竞争力的时候，中小微企业的信息化却才刚刚起步。在全国4200多万户中小企业中，仅有30%能够利用信息技术开展生产管理和市场营销活动，另外有60%仅能够利用互联网获取市场信息。营销是解决中小企业销售困境的重要手段，提升企业营销力迫在眉睫，这是一个万亿级的企业互联网市场。以“云应用+云通信+智能硬件”的一站式SaaS模式数据化精准营销将独具特色，以人为本的营销设计思想将帮助企业在“互联网+”大背景下突破营销，实现转型，赢得未来</p>
    <ul class="whyImg">
      <li>
          <div class="blackBg">
             <img src="@img/whychooseus_logo1.png" alt="">
             <p>广阔市场前景</p>
          </div>
          <div class="blueBg">
            <p>帮助4200万家中小企业实现营销信息化落地，拥有万亿级市场规模</p>
          </div>
      </li>
      <li>
          <div class="blackBg">
             <img src="@img/whychooseus_logo2.png" alt="">
             <p>具有竞争产品</p>
          </div>
          <div class="blueBg">
            <p>成熟的产品每周更新、每月迭代市场占有率高</p>
          </div>
      </li>
      <li>
          <div class="blackBg">
             <img src="@img/whychooseus_logo3.png" alt="">
             <p>软硬件结合</p>
          </div>
          <div class="blueBg">
            <p>一站式SaaS模式数据化精准营销+智能硬件,疏通营销各个环节</p>
          </div>
      </li>
      <li>
          <div class="blackBg">
             <img src="@img/whychooseus_logo4.png" alt="">
             <p>高效收益</p>
          </div>
          <div class="blueBg">
            <p>运营慧营销SaaS平台收益稳健攀升，邀您共享</p>
          </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name:'',
  components: {},
  props:{},
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .whySelect{
    p.whyText{
      font-size:18px;
      color:#333;
      line-height:48px; 
    }
  }
  .whyImg{
    display: flex;
    margin-top:48px;
    width:1196px;
    height:359px;
    background:url("../../../assets/img/whybg.png") no-repeat;
    li{
      position: relative;
      display:flex;
      justify-content: center;
      align-items: center;
      width:25%;
      color:#fff;
      text-align:center;
      height:359px;
      overflow: hidden;
      div.blackBg{
        position: relative;
        z-index:100;
        transition: margin-top 0.5s;
        margin-top:0;
        p{
          margin:10px 0 30px;  
          font-size:24px;
        }
      }
      div.blueBg{
        position: absolute;
        top:359px;
        left:0;
        width:100%;
        height:359px;
        background:rgba(68,181,242,0.7);
        color:#fff;
        z-index: 99;
        transition: top 0.3s ease-out;
        p{
          margin:210px auto 0;
          width:245px;         
        }
      }
    }
    li:hover{
      div.blackBg{
        margin-top:-70px;
      }
      div.blueBg{
        top:0;
      }
    }
  }
</style>