
export const  prodAdvantage= [
  {
    'title':'快速部署',
    'text':'傻瓜式安装，1分钟即可轻松上手快捷使用'
  },
  {
   'title':'成本更低',
   'text':'无需购置服务器，无需专业运维人员按需添加营销人员',
  },
  {
    'title':'通信稳定',
    'text':'支持4G全网通与SIP固话信号无干扰，清晰稳定'
  },
  {
    'title':'高效工作',
    'text':'日均通话量达到600-800人次自动拨号，智能挂机'
  }
]

export const sec2List1= [
  {
    'title':'整合电话、微信、QQ等渠道的客户，企业CRM库一站式管理：通话录音自动上传，全程可追踪！',
    'text':'将各渠道零散的客户资源可统一批量导入慧营销CRM资源库，全面清晰地记录用户画像包括客户来源，沟通小记跟进、标签、客户资源分类、意向度分类等，通过慧营销CRM，真正实现数字化运营管理全流程。'
  } 
]

export const sec2List2 = [
  {
    'title':'高效率拓客智能外呼，多渠道营销触达客户',
    'text':'慧营销CRM无缝对接电销，通过呼叫中心一键拨号、通话录音、屏幕取号、来电弹屏、挂机短信等功能高效跟进触达客户，提升拓客开发效率。'
  }
]
export const sec2List3 = [
  {
    'title':'从资源转入意向，转化商机、进入销售漏斗、推进跟踪记录，设定销售计划任务慧营销CRM为销售签单赋能',
    'text':'全流程记录销售场景，精细化客户管理过滤无意向客户和风险号码，不浪费销售资源，客户保护查重，避免撞单飞单，详细跟进记录还原销售场景；客户公海池，及时提醒跟进，设置拜访计划电话号码隐藏功能，避免销售做私单，离职带走客户，保护企业财产。'
  }
]
export const sec2List4 = [
  {
    'title':'慧营销CRM提供可视化数据报表',
    'text':'电脑和慧营销手机APP协同办公，可以随时查看销售人员的工作数据，如客户开发数量、通话时长，管理报表等等，数据实时生成，员工工作随时掌握，销售团队透明管理，打造高效营销模式，根据销售目标或者业务流程形成可视化数据报表，为企业提供针对性预测和建议。'
  }
]



export const whyList = [
  {
    'img': require('./../../assets/img/crm1.png'),
    'title':'多渠道拓客',
    'text':'一站式打通电话，微信，qq，短信，邮件，旺旺，H5页面，AI智能系统，多渠道营销获客'
  },
  {
    'img':require('./../../assets/img/crm2.png'),
   'title':'客户高效转化',
   'text':'多种客户开发工具，大数据自动检测过滤无效电话数据自动抓取，屏幕取号多维度分析客情，助力精准定位目标客户',
  },
  {
    'img':require('./../../assets/img/crm3.png'),
    'title':'销售透明管理',
    'text':'制定PDCA销售计划，慧营销业绩目标与销售计划匹配执行，精准实现目标落地，随时随地穿透销售进度，助力销售自动化'
  },
  {
    'img':require('./../../assets/img/crm4.png',),
    'title':'可视化数据报表',
    'text':'通过电话数据统计，客开量，签约量，业绩统计，销售排行榜等数据报表，全方位赋能销售签单'
  }
]