<!--小峰助手 -->
<template>
  <div class>
    <Banner :bgImg="bannerImg" bgcolor="#2299ee">
      <div class="banner-text">
        <h4>微信营销</h4>
        <p></p>
        <ul>
          <li>T5专属智能屏，微信一键关联</li>
          <li>让沟通更便捷，让管理更高效</li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="container-1200px wechat-cards">
      <h4 class="whyTitle">社交网络精准化获客+SCRM提升营销效率</h4>
      <div class="cards">
        <div
          :class="item.name==currentCard?'card hover':'card'"
          v-for="(item,index) in cardsList"
          :key="'wechatCards'+index"
          @mouseover="handleCardsHover(item.name)"
          @mouseout="currentCard=''"
        >
          <div class="image">
            <img :src="item.image" alt />
          </div>
          <div class="title">{{item.title}}</div>
          <div class="content">{{item.content}}</div>
        </div>
      </div>
    </div>
    <div class="container-1200px wechat-tabs">
      <h4 class="whyTitle">企业发展离不开微信营销</h4>
      <div class="tabs-head">
        <div
          :class="item.name==currentTab?'tabs-name active':'tabs-name'"
          v-for="(item,index) in wechatTabs"
          :key="'wechatTabsName_'+index"
          v-html="item.title"
          @click="handleTabsChange(item.name)"
        ></div>
      </div>
      <Tabs :value="currentTab">
        <TabPane
          v-for="(item,index) in wechatTabs"
          :key="'wechatTabs_'+index"
          :label="item.title"
          :name="item.name"
        >
          <div class="describe">{{item.describe}}</div>
          <div class="img-box">
            <img :src="item.image" :alt="item.imageAlt" :title="item.imageTitle" />
          </div>
        </TabPane>
      </Tabs>
    </div>
    <div class="wrap">
      <h4 class="whyTitle">打通营销、转化、管理闭环</h4>
      <ul class="ulStyle">
        <li v-for="(item,index) in wechatFunList" :key="'wechatFunList_'+index">
          <div class="img-box">
            <img :src="item.image" />
          </div>

          <h5 v-html="item.text"></h5>
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class="container-1200px">
      <div class="scene">
        <h4>应用场景</h4>
        <p
          class="whyText"
        >我们常常能听企业说掌握了几千万的客户信息，拥有几百万条客户ID，但如果企业没有能力去与这些信息背后的“人”形成有效连接的话，那些信息就是一堆毫无意义数字。微信营销能通过建立客户联络、发展客户关系、提升客户体验、赢得客户忠诚、经营客户价值的方式，最终实现企业价值的提高。行业覆盖金融、教育、房产、汽车、电子商务、医疗美容等。</p>
        <ul>
          <li v-for="(item,index) in hxzb.sceneList" :key="index">
            <img :src="item.src" />
            <h5>{{item.title}}</h5>
            <div>
              <div class="box">
                <h6>{{item.title}}</h6>
                <p>{{item.p}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <apply-dialog-btn
      :modelVisible="$store.state.applyModal"
      @cancle="$store.commit('CHANGE_MODAL_FALSE')"
    ></apply-dialog-btn>
  </div>
</template>

<script>
import { Tabs, TabPane } from "iview";
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
export default {
  name: "",
  components: {
    Tabs,
    TabPane,
    Banner,
    ApplyDialog
  },
  props: {},
  data() {
    return {
      currentTab: "tabs1",
      currentCard: "",
      bannerImg: require("../../assets/img/wechatBanner.png"),
      wechatFunList: [
        {
          image: require("../../assets/img/wechat-img1.png"),
          text: "微信通话存语音<br />微信数据云储存"
        },
        {
          image: require("../../assets/img/wechat-img2.png"),
          text: "智能切换多微信账号<br />多个微信号统一管理"
        },
        {
          image: require("../../assets/img/wechat-img3.png"),
          text: "快捷回复<br />消息群发"
        },
        {
          image: require("../../assets/img/wechat-img4.png"),
          text: "微信好友管理<br />客户关联群组"
        },
        {
          image: require("../../assets/img/wechat-img5.png"),
          text: "随时查看销售情况<br />营销者制定任务"
        },
        {
          image: require("../../assets/img/wechat-img6.png"),
          text: "一站式管理微信生态圈"
        }
      ],
      wechatTabs: [
        {
          title: "操作简单<br/>关联性强",
          name: "tabs1",
          describe:
            "使用慧营销领航版+快话T5智能屏内置微信，即可完成手机APP和电脑端数据连接，基于业务系统发起微信电话、语音聊天交流无障碍，所有文字、图片、电话记录自动同步，及时备份存储，沟通数据完整呈现。提高获客效率及转化。",
          image: require("../../assets/img/wechat-tabs1.png"),
          imageAlt: "",
          imageTitle: ""
        },
        {
          title: "微信客户数据一键导入<br/>有效整合",
          name: "tabs2",
          describe:
            "个人微信、企业微信客户一键导入，实现数据整合。从微信中读取一手信息，帮助企业与客户建立有效快捷连接，筛选优质客户，高效管理。沟通中进一步实现深入洞察客户需求，逐步引导客户行为，最终实现获取更多新客户、留存老客户并逐步提高客户忠诚度的效果。",
          image: require("../../assets/img/wechat-tabs2.png"),
          imageAlt: "",
          imageTitle: ""
        },
        {
          title: "微信数据统计<br/>可视化管理",
          name: "tabs3",
          describe:
            "微信端口的管理与CRM完美融合，微信通话录音及聊天内容能在CRM库中查看，销售微信工作量实时统计，通话总时长、新增资源占比、意向情况等数据，通过可视化数据分析，企业管理者可随时查看销售与客户业务进展，掌握真实情况并考核，了解员工工作能力，便于提升销售业绩。",
          image: require("../../assets/img/wechat-tabs3.png"),
          imageAlt: "",
          imageTitle: ""
        },
        {
          title: "微信实时监管<br/>减少资源流失",
          name: "tabs4",
          describe:
            "工作微信、企业微信所添加的客户成员、客户群都归属于企业，即使员工离职也无法带走客户，可轻松将其对接的客户资源转移到其他员工，降低客户流失风险，避免数据泄露，客户数据永久存储，沟通过程全记录，保障客户数据安全。",
          image: require("../../assets/img/wechat-tabs4.png"),
          imageAlt: "",
          imageTitle: ""
        }
      ],
      hxzb: {
        sceneList: [
          {
            title: "金融服务",
            src: require("../../assets/img/hxzb-img8.png")
            // p: "通过直播引领企业进入数字化培训时代，降低内部培训的成本，提升培训的效率。"
          },
          {
            title: "教育资讯",
            src: require("../../assets/img/hxzb-img9.png")
            // p: "通过直播提升企业品牌的知名度，增加品牌露出，帮助企业业绩实现高速增长。"
          },
          {
            title: "电子商务",
            src: require("../../assets/img/hxzb-img10.png")
            // p: "通过直播赋能营销，扩大产品营销覆盖面，提升销售业绩及销售转化率。"
          },
          {
            title: "医疗美容",
            src: require("../../assets/img/hxzb-img11.png")
            // p: "通过直播连接客户，打造私域流量池，沉淀粉丝，把握销售线索。"
          }
        ]
      },
      cardsList: [
        {
          image: require("../../assets/img/wechat-cards1.png"),
          name: "cards1",
          title: "全渠道触达",
          content:
            "支持个人微信、企业微信，统一管理触达、互动、内容，形成全渠道一体化的营销方式，提供有效的连接工具，实现从获取到留存再到持续价值挖掘。"
        },
        {
          image: require("../../assets/img/wechat-cards2.png"),
          name: "cards2",
          title: "精细化管理",
          content:
            "客户数据和微信数据整合汇总，无缝对接CRM统一管理。客户与销售一键绑定，按客户分类及渠道查看，多维度客户标签及分群。"
        },
        {
          image: require("../../assets/img/wechat-cards3.png"),
          name: "cards3",
          title: "数据化驱动",
          content:
            "数据化评估营销各环节实施状况，大力提升流程化管理能力。把营销渗透到用户的每一个用户生命周期的节点上。"
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  //方法集合
  methods: {
    handleTabsChange(name) {
      this.currentTab = name;
    },
    handleCardsHover(name) {
      this.currentCard = name;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-1200px {
  width: 1200px;
  margin: 0 auto;
  font-family: "Microsoft YaHei", "Arial Narrow", Arial, sans-serif;
}
.whyTitle {
  font-size: 40px;
  margin-bottom: 100px;
}
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.scene {
  margin: 30px 0;
  p.whyText {
    font-size: 16px;
    color: #333;
    line-height: 48px;
    margin-bottom: 60px;
  }
  h4 {
    font-size: 30px;
    font-weight: normal;
    color: #333;
    margin: 100px 0 20px;
    text-align: center;
  }
  ul {
    display: flex;
    li {
      position: relative;
      width: 300px;
      height: 400px;
      cursor: pointer;

      overflow: hidden;
      h5,
      h6 {
        font-size: 24px;
        color: #fff;
        font-weight: normal;
      }
      h5 {
        position: absolute;
        height: 50px;
        line-height: 50px;
        text-align: center;
        bottom: 0;
        left: 0;
        // background: rgb(0, 0, 0);
        // opacity: 0.4;
        background: rgba(0, 0, 0, 0.4);
        width: 300px;
      }
      & > div {
        position: absolute;
        top: 400px;
        left: 0;
        // background: rgb(0, 0, 0);
        // opacity: 0.4;
        background: rgba(0, 0, 0, 0.4);
        width: 300px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: top 1s;
        .box {
          border: 3px solid #fff;
          width: 240px;
          height: 316px;
          h6 {
            margin: 0 auto;
            height: 80px;
            line-height: 80px;
            border-bottom: 3px solid #fff;
            width: 186px;
            font-weight: normal;
            text-align: center;
          }
          p {
            padding: 0 25px;
            color: #fff;
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }
    li:hover > div {
      top: 0;
    }
    li:hover h5 {
      display: none;
    }
  }
}
.ulStyle {
  width: 100%;
  background: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 638px;
  li {
    position: relative;
    width: 33.33%;
    text-align: center;
    color: #919191;
    font-size: 16px;
    padding-top: 0;
    height: 233px;
    box-sizing: border-box;
    h5 {
      font-size: 22px;
      color: #595959;
    }
    .img-box {
      // position: absolute;
      width: 160px;
      height: 160px;
      margin: 0 auto;
      text-align: center;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.wechat-tabs {
  .whyTitle {
    margin-bottom: 80px;
  }
  .tabs-head {
    display: flex;
    margin-bottom: 30px;
    .tabs-name {
      &::before {
        content: "";
        width: 4px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        left: 10px;
        top: 0;
      }
      &::after {
        content: "";
        width: 4px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        right: 10px;
        top: 0;
      }
      flex: 1;
      position: relative;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      margin-right: 20px;
      padding: 10px 0;
      color: #515a6e;
      cursor: pointer;
      box-shadow: 0px 5px 8px #ccc;
      background-color: #eee;
      &.active {
        background-image: linear-gradient(90deg, #4ea2df, #5e26fe);
        color: #fff;
      }
    }
    .tabs-name:last-child {
      margin-right: 0;
    }
  }
  .describe {
    font-size: 18px;
    line-height: 30px;
  }
  .img-box {
    margin-top: 40px;
    text-align: center;
  }
}
.wechat-cards {
  .cards {
    display: flex;

    .card {
      flex: 1;
      padding: 80px 30px;
      margin-right: 40px;
      color: #1fa3ff;
      border-radius: 10px;
      height: 356px;
      box-shadow: 0px 5px 8px #eee;
      cursor: pointer;

      .image {
        width: 160px;
        height: 160px;
        text-align: center;
        margin: 0 auto;
      }

      .title {
        text-align: center;
        font-size: 24px;
      }

      .content {
        display: none;
        font-size: 16px;
        margin-top: 20px;
      }

      &.hover {
        color: #fff;
        background-color: #fa8f9b;
        padding: 30px;

        .content {
            display: block;
        }
      }
    }
  }
}
</style>