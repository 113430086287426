<template>
  <div class="scroll robot-swiper">
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide v-for="(item,index) in swiperSlides" :key="'swiper'+index">
        <img :src="item.src" :alt="item.imgAlt" :title="item.imgTitle">
        <div class="slider-text">
          <div><img :src="item.icon"/></div>
          <h5>{{item.title}}</h5>
          <p>{{item.text}}</p>
        </div>
      </swiper-slide>
    </swiper> 
    <div class="swiper-pagination "  slot="pagination"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'  
export default {
  name: 'HelloWorld',
  components: {  
      swiper,  
      swiperSlide  
  },  
  data () {
    return {
       swiperOption: {  
          slidesPerView: 3,
          spaceBetween: 0,
          centeredSlides: true,
          loop:true,
          height:320,
          // width:490,
          //分页
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          //左右点击
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
        },
        swiperSlides:[
          {icon:require("@img/robotIcon-swiper-img1.png"),src:require("@img/robot-swiper-img1.png"),'text':'（营销、推广、广告、客户咨询）',title:'电商','imgTitle':'','imgAlt':''},
          {icon:require("@img/robotIcon-swiper-img2.png"),src:require("@img/robot-swiper-img2.png"),'text':'（邀约、通知、营销、客户咨询）',title:'教育','imgTitle':'','imgAlt':''},
          {icon:require("@img/robotIcon-swiper-img3.png"),src:require("@img/robot-swiper-img3.png"),'text':'(营销、邀约、调查、装修、中介）',title:'房地产','imgTitle':'','imgAlt':''},
          {icon:require("@img/robotIcon-swiper-img4.png"),src:require("@img/robot-swiper-img4.png"),'text':'(营销、回访、客户咨询、售后服务）',title:'汽车','imgTitle':'','imgAlt':''},
          {icon:require("@img/robotIcon-swiper-img5.png"),src:require("@img/robot-swiper-img5.png"),'text':'(民政、社保、医院、热线、调查）',title:'商业服务','imgTitle':'','imgAlt':'' },
          {icon:require("@img/robotIcon-swiper-img6.png"),src:require("@img/robot-swiper-img6.png"),'text':'(财税、推广、广告、招商、客户咨询）',title:'政府服务','imgTitle':'','imgAlt':''},
       ]
    }
  },
  computed: {  
    swiper() {  
      return this.$refs.mySwiper.swiper;  
    }  
  }, 
  created(){
    this.imgJsonHome = this.$store.state.imgJson.home
  },
  mounted () {  
    //可以使用swiper这个对象去使用swiper官网中的那些方法  

  }   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.robot-swiper.scroll{
  height:330px;
  position: relative;
   display: flex;
   justify-content: center;
   .swiper-pagination-bullet{
      margin:0 5px; 
   }

  .swiper-container {
      width: 100%;
      height:310px;
    }
    .swiper-slide {
      // width:490px;  
      height:310px;
      text-align: center;
      font-size: 14px;
      background: #fff;
      img{
        display:block;
        width:100%;
        height:100%;
      }
    }
    .swiper-slide:not(.swiper-slide-active){
        transform: scale(0.8);
    } 
    .slider-text{
      display: none;
      position: absolute;
      padding:10px 10px;
      width:400px;
      height:100%;
      color:#fff;
      line-height:26px;
      font-size:16px;
      box-sizing: border-box; 
      z-index:100;
      bottom:0; 
      left:0;
      text-align:left; 
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      background:rgba(0,73,134,0.7);
      h5{
        display:block;
          line-height:40px;
      }
      img{
        display:block;
        width:80px;
        height: 80px;
      }
    }
    .swiper-slide-active .slider-text{
        background:none;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-wrap: wrap;
      // flex-direction: column;
    }
  }  
  .scroll .swiper-pagination{
      bottom:-10px; 
      left:0;
      width:100%;
      text-align: center;
  }
</style>