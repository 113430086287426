<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPose"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap letter-moudule'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="多角度品牌传播"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg2" :moduleText="moduleText2" title="品牌宣传 • 更多花样" lineW="1200px" :titleStyle="false" :imgTitle='this.$store.state.imgJson.letterE.img1.title' :imgAlt='this.$store.state.imgJson.letterE.img1.alt'></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <show-module title="使用场景" titleText="能满足不同用户的多种场景" :bg="showModuleBg" :liText="liText"></show-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
import ImgList from './components/imgListModule'
import ShowModule from './components/ShowModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    TbModule,
    ImgList,
    ApplyDialog,
    ShowModule
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/letterEBanner.png'),
        moduleText2:'融e信是基于运营商的一种彩信形式，用户可制作e信模板便捷传播。通过e信多媒体融合展示的特性，用户打开即可多维度的看到品牌展示，基于运营商通道服务，到达率高、内容形式多样化，帮助用户高效的熟悉企业相关的服务产品',
        moduleText3:'可利用融e信展示性好的特点，多个角度助力品牌曝光、产品推广、服务通知、活动告知等各样企业商用场景',
        moduleImg1:require('../../assets/img/letter-img1.png'),
        moduleImg2:require('../../assets/img/letter-img2.png'),
        showModuleBg:require('../../assets/img/letter-img3.png'),
        listText:[{'title':'多媒体融合','text':'视频、图片、文本，多种类型'},{'title':'触达简单','text':'利用运营商通过直达手机'} ,{'title':'品牌曝光','text':'多视角展示品牌活动'}],
        liText:['保险行业','餐饮行业','房产行业','旅游行业','机械制造','快递行业']
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  .letter-moduel1{
    position: relative;
    ul{
      display: flex;
      justify-content: space-between;
      width:1200px;
      position: absolute;
      bottom:67px;
      left:0;
    }
    li{
      width:33.33%;;
      color:#333;
      font-size:12px;
      text-align: center;
      h5{
        font-size:30px;
      }
    }
  }
  .letter-module3{
    position: relative;
    margin-bottom:100px;
    ul{
      position: absolute;
      top:172px;left:0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        margin-bottom:50px;
        margin-top:360px;
        width:380px;
        text-align: center; 
      }

    }
  }
</style>
<style>
  .letter-moudule .titleS p{
      font-size:18px;
  }
  .btnPose .apply-btn{
    margin-top:385px;
    color:#333;
    border-color:#000; 
    font-size:16px;
  }
</style>