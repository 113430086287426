<!--小峰助手 -->
<template>
  <div class=''>
     <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosXf"><apply-dialog></apply-dialog></div></Banner>
     <div class="wrap xiaofeng" >
       <h4 class="whyTitle">人机对比</h4>
       <div class="perToCom">
         <div>
            <h5>传统</h5>
            <ul>
              <li>拓客效率低，挂断率高，承担率低，电话时长，无效电话多，每天100-500通</li>
              <li>多种因素影响，情绪波动大</li>
              <li>新人培训周期长，短期内专业程度低</li>  
              <li>成本高，招人难、管理难，人员流动大，培训周期长</li>
            </ul>
         </div>
         <img src="../../assets/img/xiaofeng-img2.png"  :alt="imgJsonXf.img1.alt" :title="imgJsonXf.img1.title">
         <div>
          <h5>小蜂助手</h5>
          <ul>
            <li>高效拓客，海量呼出，4-5倍于人工效率，外呼话术定制，贴近真人对话，外呼时间自主设定</li>
            <li>全年无休，12小时全天候工作，情绪稳定，状态饱满，永不疲劳</li>
            <li>销售精英话术，智能优化话术，强大的CRM系统，销售的漏斗分层管理</li>
            <li>无需考虑人员变动，号码批量导入，机器自动外呼，主动营销，自动转化，远远低于人工成本</li>
          </ul>
         </div>
       </div>
     </div>
     <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
     <div class="bgGray">
        <div class="wrap xiaofeng">
          <h4 class="whyTitle">小蜂助手</h4>
          <ul class="ulStyle">
            <li>
              <h5>AI自主学习</h5>
              <p>发现记录问题录音，<br />高效学习、优化话术</p>
            </li>
            <li>
              <h5>私人助理</h5>
              <p>支持个人私人话术，私人录音，<br />筛选、跟进统一声音</p>
            </li>
             <li>
              <h5>自动拨打</h5>
              <p>客户资料批量导入，按需设定自<br />动拨打，省事又省力</p>
            </li>
            <li>
              <h5>识别精准</h5>
              <p>阿里云语音识别技术，<br />语音识别率达到业界高水平</p>
            </li>
            <li>
              <h5>多轮会话</h5>
              <p>先进的多轮会话技术，<br />适用多样化对话应用场景</p>
            </li>
            <li>
              <h5>真人语音</h5>
              <p>给客户营造真实舒心的沟通体验，<br /> 提升服务品质</p>
            </li>

            <li>
              <h5>灵活打断</h5>
              <p>在对话过程中，用户可以<br /> 随时打断通话，灵活插话</p>
            </li>
            <li>
              <h5>自动分类</h5>
              <p>后台自动客户分类，<br /> 准确判断客户状态和意向程度</p>
            </li>
            <li>
              <h5>自动记录</h5>
              <p>对话过程自动录音，<br /> 并精准转化成文字，可随时追溯</p>
            </li>
          </ul>
        </div>
        <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
     </div>
     <div class="wrap xiaofeng">
        <h4 class="whyTitle">功能与特点</h4>
        <img src="@img/xiaofeng-img4.png" class="xFun"   :alt="imgJsonXf.img2.alt" :title="imgJsonXf.img2.title">
     </div>
     <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
     <div class="bgGray">
        <div class="wrap xiaofeng">
          <h4 class="whyTitle">使用场景</h4>
          <ul class="ulStyle ulBg">
            <li>
              <h5>电商</h5>
              <p>（营销、推广、广告、客户咨询）</p>
            </li>
            <li>
              <h5>教育</h5>
              <p>（邀约、通知、营销、客户咨询）</p>
            </li>
            <li>
              <h5>房地产</h5>
              <p>(营销、邀约、调查、装修、中介）</p>
            </li>
            <li>
              <h5>汽车</h5>
              <p>（营销、回访、客户咨询、售后服务）</p>
            </li>
            <li>
              <h5>政府服务</h5>
              <p>（民政，社保，医院，热线，调查）</p>
            </li>
            <li>
              <h5>商业服务</h5>
              <p>（财税，推广，广告，招商，客服咨询）</p>
            </li>
          </ul>
        </div>
        <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
     </div>
     <div class="wrap">
      <div class="priceModule">
        <h4>全新计价模式，全国低价</h4>
        <span>每天仅需1元</span>
        <img src="@img/xiaofeng-img7.png" width="1026" height=409   :alt="imgJsonXf.img3.alt" :title="imgJsonXf.img3.title">
        <p>您的收入来源于您的每一份投入!</p>
      </div>
     </div>
     <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/xiaofengBanner.jpg'),
        imgJsonXf:this.$store.state.imgJson.xiaofeng
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss'>
  //@import url(); 引入公共css类
  .xiaofeng .whyTitle{
    margin-top:75px;
  }
  .perToCom{
    display:flex;
    justify-content: space-around;
    //  padding-bottom:80px;
    img{
        display:block;
        height:547px;
        width:82px;
        margin-top:60px;
      }
    div{
      width:414px;
       
      h5{
        width:100%;
        font-size:22px;
        color:#595959;
        text-align: center;
      }
      li{
        height:145px;
        border-bottom:1px solid #e6e6e6;
        display:flex; 
        align-items:center
      }
    }
  }
  .bgGray{
    background:#f4f5f6;
    padding-top:1px;
    padding-bottom:50px;
  }
  .ulStyle{
    width:100%;
    background:url('../../assets/img/xiaofeng-img3.png')  center top no-repeat;
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height:638px;
    li{
      width:33.33%;
      text-align: center;
      color:#919191;
      font-size:16px;
      padding-top:100px;
      height:233px;
      box-sizing: border-box;
      h5{
        font-size:22px;
        color:#595959;
      }
    }
  }
  .xFun{
    display: block;
    width:1135px;
    height:531px;
    margin-bottom:95px;
  }
  .ulBg{
      background:url('../../assets/img/xiaofeng-img5.png')  center top no-repeat;
      li{
        height:330px;
        padding-top:152px;
      }
  }
.btnPosXf .apply-btn{
  border-color:#fff;
  margin-top:412px;
  color:#fff;
  font-size:16px;
  margin-left:26px;
}
.priceModule{
    text-align:center;
    h4{
      margin-top:96px;
      margin-bottom:40px;
      font-size:36px;
    }
    span{
      display:block;
      font-size:22px;
      color:#1f8cec;
      margin-top:-10px;
      margin-bottom:50px;
    }
    p{
      height:65px;
      line-height: 65px;
      background:url('../../assets/img/xiaofeng-img6.png') left center no-repeat;
      font-size:30px;
      color:#1f8cec;
      width:520px;
      margin:75px auto 0;
      padding-left:50px;
    }
}
</style>