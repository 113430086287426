<template>
  <div class="list" :class="{'listBg':listBg}">
    <ul :class="ulListStyle" class="container-1200px">
      <li v-for="(item,index) in ITlistObj" :key="index" :style="{ 'background-color': item.bg||'' }">
        <img :src="item.img" :alt="item.alt" :title="item.imgTitle" />
        <h4 v-if="item.p1">{{item.p1}}</h4>
        <p v-if="item.p2" v-html="item.p2"></p>
        <p class="p3" v-if="item.p3" v-html="item.p3"></p>
        <p class="childrenList" v-if="item.childrenList" v-for="(it,ind) in item.childrenList" :key="ind">
          <!-- <b><span class="round"></span>{{ it.title }}：</b><span class="desp">{{ it.titleP }}</span> -->
          <span v-if="it.title">{{ it.title }}：</span>{{ it.titleP }}
        </p>
      </li>
    </ul>
  </div> 
</template>
<script>
export default {
  name:'imgTextList',
  props:{
    ITlistObj:{
      type:Array,
      default:[]
    },
    ulListStyle:{
      type:String,
      default:'itUlList'
    },
    listBg:{
      type:Boolean,
      default:false
    }
  },
  components:{},
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
// .listBg{
//   background-repeat: no-repeat;
//   background-position: top center;
//   background-image:url('../../../../assets/images/dxjqr/listBg.png');
//   height:577px;
// }
  .itUlList{
    display: flex;
    justify-content: space-around;
    li{
      width:225px;
      text-align: center;
    }
    h4{
      font-size:28px;
      color:#333;

    }
    p{
      font-size:14px;
      color:#808080
    }
  }
  .zxcjlist{
    margin-top:50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      margin-bottom:30px;
      width:386px;
      height:373px;
      box-shadow: 1px 1px 10px 5px #eaf2ff;
      border-radius:10px;
      overflow:hidden;
      img{  
        display: block;
        width:100%;
      }
      h4{
        margin-top:10px;
        font-weight: bold;
        color:#333;
        line-height:42px;
        padding-left:26px;
        font-size:20px;
      }
      p{
        padding-left:26px;
        color:#808080;
        font-size:16px;
      }
    }
    & li:hover{
      background:#407ef8;
      p,h4{
        color:#fff;
      }
    }
  }
  //引流获客
  .ylhklist{
    margin:50px 0;
    display: flex;
    justify-content: space-between;
    h4{
      margin-top:20px;
      font-size:30px;
      color:#666;
      text-align: center;
    }
  }
  // 智能筛选
  .znsxList{
    margin:80px auto;
    display: flex;
    justify-content: space-around;
    h4{
      margin-top:20px;
      font-size:30px;
      color:#666;
      text-align: center;
    }
  }
  // 智能名片
  .zncardlist{
    display: flex;
    justify-content: space-between;
    margin-top:35px;
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width:294px;
      height:331px;
      border:1px solid #e7e7e7;
      border-radius: 5px;
      cursor: pointer;
      box-sizing: border-box;
      h4{
        margin-top:55px;
        font-size:22px;
        color:#666;
      }
      p{
        display: none;
      }
    }
    li:hover{
    box-shadow: 1px 4px 6px 2px #e2e9fc;
    h4{
     margin-top:15px;
    }
    p{
      display: block;
    }
    }
  }
  //新媒体
  .newMedia{
    margin-top:30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li{
        width:32%;
        border:1px solid #e7eaed;
        position: relative;
        margin-bottom:50px;
        height:320px;
        cursor: pointer;
  
  
    h4{
      background:#f4f6fb;
      font-size:20px;
      text-align: center;
      line-height:94px;
      font-weight:bold;
      border-bottom:1px solid #e7eaed;
    }
    p{ 
      margin:0 auto;
      width:265px;
      padding-top:28px;
      font-size:16px;
      color:#333;
      line-height:30px;
      position: relative;
      z-index: 100;
    }
    img{
      position:absolute;
      bottom:0;
      right:0;
      z-index:0;
    }
      }
    li:hover{
    border:2px solid #2a74fc;
    transform: scale(1.1);
    box-shadow: 0 3px 6px 2px #c0cff3;
      h4{
        color:#fff;
        background:#2299ee;
        border-bottom:2px solid #2299ee
      }
    }
  }
  .fourshineList{
    display: flex;
    justify-content: space-between;
    margin-top:35px;
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width:290px;
      height:382px;
      border-radius: 5px;
      box-shadow: -1px 0px 9px 1px rgba(119,141,251,0.31); 
      box-sizing: border-box;
      h4{
        margin-top:55px;
        margin-bottom: 11px;
        font-size:22px;
        color:#666;
      }
    }
  }
  .tggzxlList{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top:35px;
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      width:555px;
      height:156px;
      position: relative;
      margin-bottom: 26px;
      .p3{
        position: absolute;
        color: #fff;
        font-size: 18px;
        margin-bottom: 0;
      }
    }
  }
  .serviceList{
    display: flex;
    justify-content: space-between;
    padding-top:45px;
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width:388px;
      height:486px;
      border-radius: 5px;
      // box-shadow: -1px 0px 9px 1px rgba(119,141,251,0.31); 
      box-sizing: border-box;
      h4{
        margin-top:55px;
        margin-bottom: 11px;
        font-size:22px;
        color:#666;
      }
      .childrenList{
        font-size: 14px;
        margin: 0 30px;
        position: relative;
        padding-left: 15px;
        span{
          font-weight: bold;
        }
      }
      .childrenList:after{
        position: absolute;
        top: 6px;
        left: 0;
        content: "";
        background: linear-gradient(87deg,#2375cc 0%, #7ab5ec 100%);
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
      }
    }
  }
  
</style>
<style lang="less" scoped>

</style>