<template>
  <div style="height: 68px" v-show="isShow">
    <div class="navWrap">
      <div class="nav">
        <h1 @click="toHome()">
          <img src="@img/logo2.png" alt="浙江企蜂" title="浙江企蜂" style="width:100px; height:30px;margin-top:10px;"/>
          <img src="@img/logo.png" alt="慧营销" title="慧营销" />
          <span style="font-size:20px;">慧营销</span>
        </h1>
        <ul class="menu">
          <li
            v-for="(item, index) in menu"
            :key="'m' + index"
            :id="item.className"
            :class="item.className"
          >
            <router-link
              v-if="!item.target"
              :to="item.tolink"
              :class="item.current"
              @click.native="refresh(item.tolink)"
              >{{ item.name }}</router-link>
            <a
              v-if="item.target"
              :href="item.url"
              :target="item.target"
              >{{ item.name }}</a>
          </li>
        </ul>
        <p class="phone">
           <Poptip placement="left-start" width="200" trigger="hover">
            <i class="iconfont icon-weixin1" style="color: green"></i>
            <div class="api" slot="content" style="margin-right:50px;">
              <img src="@img/home-wxcode.png" width="150" height="160" alt />
            </div>
          </Poptip>
          <Poptip placement="left-start" width="200" trigger="hover">
            <i class="iconfont icon-dianhua2"></i>
            <div
              class="api"
              slot="content"
              style="font-size: 16px; color: #333"
            >
              电话咨询
              <br />18958188698
            </div>
          </Poptip>
        </p>
        <div
          class="product"
          :class="{ dsp: pshow }"
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
        >
          <div style="width: 75%">
            <h2>营销服务</h2>
            <div class="p-list">
              <ul>
                <li
                  v-for="(item, index) in prodData"
                  :key="'h' + index"
                  @click="closeProduct"
                >
                  <router-link
                    :to="item.tolink"
                    class="prouter"
                    @click.native="refresh(item.tolink)"
                  >
                    <img :src="item.img" :alt="item.alt" />
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="yingjian">
            <h2>智能硬件</h2>
            <div clas="p-list">
              <ul>
                <!-- <li @click="closeProduct">
                  <router-link to="/product/huiTalk" class="prouter">
                    <img src="@img/p-icon4.png" alt="">
                    快话UH4
                  </router-link> 
                </li>-->
                <li @click="closeProduct">
                  <router-link to="/product/t5" class="prouter">
                    <img src="@img/p-icon4.png" alt="T5盒子" />
                    快话T5
                  </router-link>
                </li>
                <li @click="closeProduct">
                  <router-link to="/product/ipswitch" class="prouter">
                    <img src="@img/p-icon11.png" alt="IP交换机" />
                    IP交换机
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 行业案例 -->
        <div
          class="caseList"
          :class="{ dsc: cshow }"
          @mouseover="mouseOverCase"
          @mouseleave="mouseLeaveCase"
        >
          <ul>
            <li
              v-for="(item, index) in caseData"
              :key="'c' + index"
              @click="closeCase"
            >
              <router-link
                :to="item.tolink"
              >
                <!-- <img :src="item.img" alt /> -->
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <Button type="primary">Primary</Button> -->

        <!-- 试用 -->
        <div class="tryUse" v-if='tryUseShow'  @mouseleave="mouseLeaveTryUse">
          <p>扫码咨询或免费试用</p>
          <img src="@images/tryuse.jpeg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pList, menu, caseData } from "@/global/json/json";
import Api from "@api/system";
import { Tooltip, Poptip } from "iview";
export default {
  name: "Nav",
  components: {
    Tooltip,
    Poptip,
  },
  props: {
    msg: String,
    isShow: Boolean,
  },
  data() {
    return {
      prodData: [],
      caseData: [], //行业案例
      menu: [],
      pshow: false,
      cshow: false, //行业案例
      tryUseShow:false
    };
  },
  watch: {
    $route: {
      handler: function (newRouter, oldRouter) {
        this.pshow = false; // 路由改变  产品弹层消失
        this.cshow = false;
        const path = newRouter.path;
        for (let i in menu) {
          if (path.indexOf(menu[i].url) > -1) {
            menu[i].current = "current";
          } else {
            menu[i].current = "noCurrrent";
          }
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    this.caseData = caseData;
    this.prodData = pList;
    this.menu = menu;
    this.onLoadaddHover();
  },
  mounted() {
    const $this = this;
    const openProduct = document.getElementById("open");
    openProduct.onmousemove = function () {
      $this.pshow = true;
    };
    openProduct.onmouseout = function () {
      // $this.pshow = false;
    };

    console.log($this.cshow)

    const openCase = document.getElementById("opencase");
    openCase.onmousemove = function () {                    //鼠标移入行业案例
      $this.cshow = true;
      console.log($this.cshow)
    };
    openCase.onmouseout = function () {
      // $this.cshow = false;
    };


    // 试用
    const ptryUse = document.getElementById("ptryUse");
    ptryUse.onmousemove = function () {                    //鼠标移入行业案例
      $this.tryUseShow = true;
      console.log($this.cshow)
    };
    // ptryUse.onmouseout = function () {
    //   $this.tryUseShow = false;
    // };

  },
  methods: {
    toHome() {
      this.$router.push({ path: "/" });
      window.scrollTo(0, 0);
    },

    closeProduct() {
      this.pshow = false;
    },
    mouseOver() {},
    mouseLeave() {
      this.pshow = false;
    },

    mouseOverCase() {},
    mouseLeaveCase() {          //鼠标移出行业案例菜单
      this.cshow = false;
    },
    mouseLeaveTryUse(){
      this.tryUseShow = false;  // 移出试用
    },
    closeCase() {
      this.cshow = false;
      console.log(this.cshow)
    },

    refresh(url) {
      if (url == "/newsList.do") {
        window.location.href = window.location.origin + url;
      }
      if (url == this.$route.path) {
        window.scrollTo(0, 0);
      }
    },
    onLoadaddHover() {
      const path = this.$route.path;
      for (let i = 0; i < this.menu.length; i++) {
        if (path.indexOf(menu[i].url) > -1) {
          menu[i].current = "current";
        } else {
          menu[i].current = "noCurrrent";
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"  scoped>
$height: 48px;
$bgcolor: #44b5f2;
@mixin flex($space) {
  -js-display: flex;
  display: flex;
  justify-content: $space;
}
.navWrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 68px;
  background: #fff;
  z-index: 200;
  width: 100%;
  border-bottom: 2px solid #efefef;
}
.nav {
  /* flexibility */
  @include flex(space-between);
  width: 1200px;
  height: $height;
  line-height: $height;
  margin: 10px auto;
  align-items: center;
  background: #fff;
  position: relative;
  a,
  a:visited {
    color: #333;
  }
  a:actived {
    color: #333;
  }
  a:hover {
    color: #44b5f2;
  }
  a.current {
    color: #44b5f2;
  }
  a.noCurrrent {
    color: #333;
  }
  h1 {
    display: flex;
    height: $height;
    font-size: 28px;
    color: #333333;
    cursor: pointer;
    img {
      display: block;
      margin-right: 10px;
      width: 48px;
      height: 48px;
    }
  }
  ul.menu {
    @include flex(space-around);
    // margin-left: 160px;
    width: 720px;
    li {
      font-size: 16px;
    }
    li:hover {
      color: #44b5f2;
    }
  }
  .phone {
    display: flex;
    width: 175px;
    height: 43px;
    // background:$bgcolor;
    border-radius: 30px;
    line-height: 43px;
    color: #fff;
    text-align: center;
    color: $bgcolor;
    i {
      font-size: 22px;
      margin-left: 18px;
      margin-right: 5px;
      cursor: pointer;
      font-size: 32px;
    }
  }
  .product {
    display: flex;
    position: absolute;
    top: 57px;
    right: 30%;
    padding: 0 35px;
    width: 670px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-sizing: border-box;
    color: #333;
    visibility: hidden;
    z-index: 1000;
    a:hover {
      color: #44b5f2;
    }
    :before {
      position: absolute;
      top: -6px;
      left: 360px;
      content: "";
      border-style: solid;
      border-color: #dcdcdc transparent transparent #dcdcdc;
      border-width: 1px;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background: #fff;
    }
    h2 {
      border-bottom: 1px solid #dedede;
      line-height: 62px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    ul {
      @include flex(flex-start);
      width: 100%;
      flex-wrap: wrap;
      li {
        width: 33.33%;
        font-size: 14px;
        text-align: left;
      }
    }
    .yingjian {
      width: 25%;
      li {
        width: 100%;
      }
    }
  }
  .open {
    position: relative;
    padding: 0 10px;
    ::before {
      position: absolute;
      right: 0;
      top: 25px;
      // top:21px;
      content: "";
      border-style: solid;
      border-color: #bebebe transparent transparent #bebebe;
      // border-color:transparent #bebebe #bebebe transparent;
      border-width: 3px;
      width: 0;
      height: 0;
      transform: rotate(45deg);
    }
  }
  .openPrev::before {
    top: 21px;
    border-color: transparent #bebebe #bebebe transparent;
  }
  .prouter {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
      display: block;
      width: 18px;
      height: 18px;
    }
  }
  .dsp {
    display: flex;
    visibility: visible;
  }
  
}
.wxCont {
  width: 150px;
  height: 150px;
}


.opencase {
  position: relative;
  padding: 0 10px;
  ::before {
    position: absolute;
    right: 0;
    top: 25px;
    content: "";
    border-style: solid;
    border-color: #bebebe transparent transparent #bebebe;
    border-width: 3px;
    width: 0;
    height: 0;
    transform: rotate(45deg);
  }
}

.caseList {
  display: flex;
  position: absolute;
  top: 57px;
  right: 21%;
  padding: 0 35px;
  width: 500px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-sizing: border-box;
  color: #333;
  // z-index: 1000;
  visibility: hidden;
  :before {
    position: absolute;
    top: -6px;
    left: 240px;
    content: "";
    border-style: solid;
    border-color: #dcdcdc transparent transparent #dcdcdc;
    border-width: 1px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background: #fff;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    padding-bottom: 10px;
    li {
      width: 33%;
      text-align: left;
      padding-left: 20px;
    }
  }
  &.dsc {
    display: flex;
    visibility: visible;
  }
}
#ptryUse{
  a{
    border:1px solid #6696FF;
    padding:5px 5px 7px 5px;
    border-radius:5px;
    color:#fff;
    background:#6696FF;
  }
}
.tryUse{
  border-radius: 5px;
  // background:linear-gradient(top, #6fc4f5 0%, #5348F7 100%);
  border:1px solid #dcdcdc;
  background:#fff;
  padding:10px 20px;
  text-align:center;
  position: absolute;
  top: 50px;
  right:19%;
  p{
    margin:0;
    line-height: normal;
  }
  img{
    display:block;
    width:150px;
    height:150px;
  }
}
.tryUse::before{
  position: absolute;
  top: -6px;
  right: 57%;
  content: "";
  border-style: solid;
  border-color: #dcdcdc transparent transparent #dcdcdc;
  border-width: 1px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background: #fff;
}
</style>
