export const cityData = 
[{
	"children": [{
		"code": "110101000000",
		"value": "东城区",
		"label": "东城区"
	}, {
		"code": "110102000000",
		"value": "西城区",
		"label": "西城区"
	}, {
		"code": "110105000000",
		"value": "朝阳区",
		"label": "朝阳区"
	}, {
		"code": "110106000000",
		"value": "丰台区",
		"label": "丰台区"
	}, {
		"code": "110107000000",
		"value": "石景山区",
		"label": "石景山区"
	}, {
		"code": "110108000000",
		"value": "海淀区",
		"label": "海淀区"
	}, {
		"code": "110109000000",
		"value": "门头沟区",
		"label": "门头沟区"
	}, {
		"code": "110111000000",
		"value": "房山区",
		"label": "房山区"
	}, {
		"code": "110112000000",
		"value": "通州区",
		"label": "通州区"
	}, {
		"code": "110113000000",
		"value": "顺义区",
		"label": "顺义区"
	}, {
		"code": "110114000000",
		"value": "昌平区",
		"label": "昌平区"
	}, {
		"code": "110115000000",
		"value": "大兴区",
		"label": "大兴区"
	}, {
		"code": "110116000000",
		"value": "怀柔区",
		"label": "怀柔区"
	}, {
		"code": "110117000000",
		"value": "平谷区",
		"label": "平谷区"
	}, {
		"code": "110118000000",
		"value": "密云区",
		"label": "密云区"
	}, {
		"code": "110119000000",
		"value": "延庆区",
		"label": "延庆区"
	}],
	"code": "110100000000",
	"value": "北京",
	"label": "北京"
}, {
	"children": [{
		"code": "120101000000",
		"value": "和平区",
		"label": "和平区"
	}, {
		"code": "120102000000",
		"value": "河东区",
		"label": "河东区"
	}, {
		"code": "120103000000",
		"value": "河西区",
		"label": "河西区"
	}, {
		"code": "120104000000",
		"value": "南开区",
		"label": "南开区"
	}, {
		"code": "120105000000",
		"value": "河北区",
		"label": "河北区"
	}, {
		"code": "120106000000",
		"value": "红桥区",
		"label": "红桥区"
	}, {
		"code": "120110000000",
		"value": "东丽区",
		"label": "东丽区"
	}, {
		"code": "120111000000",
		"value": "西青区",
		"label": "西青区"
	}, {
		"code": "120112000000",
		"value": "津南区",
		"label": "津南区"
	}, {
		"code": "120113000000",
		"value": "北辰区",
		"label": "北辰区"
	}, {
		"code": "120114000000",
		"value": "武清区",
		"label": "武清区"
	}, {
		"code": "120115000000",
		"value": "宝坻区",
		"label": "宝坻区"
	}, {
		"code": "120116000000",
		"value": "滨海新区",
		"label": "滨海新区"
	}, {
		"code": "120117000000",
		"value": "宁河区",
		"label": "宁河区"
	}, {
		"code": "120118000000",
		"value": "静海区",
		"label": "静海区"
	}, {
		"code": "120119000000",
		"value": "蓟州区",
		"label": "蓟州区"
	}],
	"code": "110100000000",
	"value": "天津",
	"label": "天津"
}, {
	"children": [{
		"code": "130100000000",
		"value": "石家庄市",
		"label": "石家庄市"
	}, {
		"code": "130200000000",
		"value": "唐山市",
		"label": "唐山市"
	}, {
		"code": "130300000000",
		"value": "秦皇岛市",
		"label": "秦皇岛市"
	}, {
		"code": "130400000000",
		"value": "邯郸市",
		"label": "邯郸市"
	}, {
		"code": "130500000000",
		"value": "邢台市",
		"label": "邢台市"
	}, {
		"code": "130600000000",
		"value": "保定市",
		"label": "保定市"
	}, {
		"code": "130700000000",
		"value": "张家口市",
		"label": "张家口市"
	}, {
		"code": "130800000000",
		"value": "承德市",
		"label": "承德市"
	}, {
		"code": "130900000000",
		"value": "沧州市",
		"label": "沧州市"
	}, {
		"code": "131000000000",
		"value": "廊坊市",
		"label": "廊坊市"
	}, {
		"code": "131100000000",
		"value": "衡水市",
		"label": "衡水市"
	}],
	"value": "河北省",
	"label": "河北省"
}, {
	"children": [{
		"code": "140100000000",
		"value": "太原市",
		"label": "太原市"
	}, {
		"code": "140200000000",
		"value": "大同市",
		"label": "大同市"
	}, {
		"code": "140300000000",
		"value": "阳泉市",
		"label": "阳泉市"
	}, {
		"code": "140400000000",
		"value": "长治市",
		"label": "长治市"
	}, {
		"code": "140500000000",
		"value": "晋城市",
		"label": "晋城市"
	}, {
		"code": "140600000000",
		"value": "朔州市",
		"label": "朔州市"
	}, {
		"code": "140700000000",
		"value": "晋中市",
		"label": "晋中市"
	}, {
		"code": "140800000000",
		"value": "运城市",
		"label": "运城市"
	}, {
		"code": "140900000000",
		"value": "忻州市",
		"label": "忻州市"
	}, {
		"code": "141000000000",
		"value": "临汾市",
		"label": "临汾市"
	}, {
		"code": "141100000000",
		"value": "吕梁市",
		"label": "吕梁市"
	}],
	"value": "山西省",
	"label": "山西省"
}, {
	"children": [{
		"code": "150100000000",
		"value": "呼和浩特市",
		"label": "呼和浩特市"
	}, {
		"code": "150200000000",
		"value": "包头市",
		"label": "包头市"
	}, {
		"code": "150300000000",
		"value": "乌海市",
		"label": "乌海市"
	}, {
		"code": "150400000000",
		"value": "赤峰市",
		"label": "赤峰市"
	}, {
		"code": "150500000000",
		"value": "通辽市",
		"label": "通辽市"
	}, {
		"code": "150600000000",
		"value": "鄂尔多斯市",
		"label": "鄂尔多斯市"
	}, {
		"code": "150700000000",
		"value": "呼伦贝尔市",
		"label": "呼伦贝尔市"
	}, {
		"code": "150800000000",
		"value": "巴彦淖尔市",
		"label": "巴彦淖尔市"
	}, {
		"code": "150900000000",
		"value": "乌兰察布市",
		"label": "乌兰察布市"
	}, {
		"code": "152200000000",
		"value": "兴安盟",
		"label": "兴安盟"
	}, {
		"code": "152500000000",
		"value": "锡林郭勒盟",
		"label": "锡林郭勒盟"
	}, {
		"code": "152900000000",
		"value": "阿拉善盟",
		"label": "阿拉善盟"
	}],
	"value": "内蒙古自治区",
	"label": "内蒙古自治区"
}, {
	"children": [{
		"code": "210100000000",
		"value": "沈阳市",
		"label": "沈阳市"
	}, {
		"code": "210200000000",
		"value": "大连市",
		"label": "大连市"
	}, {
		"code": "210300000000",
		"value": "鞍山市",
		"label": "鞍山市"
	}, {
		"code": "210400000000",
		"value": "抚顺市",
		"label": "抚顺市"
	}, {
		"code": "210500000000",
		"value": "本溪市",
		"label": "本溪市"
	}, {
		"code": "210600000000",
		"value": "丹东市",
		"label": "丹东市"
	}, {
		"code": "210600000000",
		"value": "锦州市",
		"label": "锦州市"
	}, {
		"code": "210800000000",
		"value": "营口市",
		"label": "营口市"
	}, {
		"code": "210900000000",
		"value": "阜新市",
		"label": "阜新市"
	}, {
		"code": "211000000000",
		"value": "辽阳市",
		"label": "辽阳市"
	}, {
		"code": "211100000000",
		"value": "盘锦市",
		"label": "盘锦市"
	}, {
		"code": "211200000000",
		"value": "铁岭市",
		"label": "铁岭市"
	}, {
		"code": "211300000000",
		"value": "朝阳市",
		"label": "朝阳市"
	}, {
		"code": "211400000000",
		"value": "葫芦岛市",
		"label": "葫芦岛市"
	}],
	"value": "辽宁省",
	"label": "辽宁省"
}, {
	"children": [{
		"code": "220100000000",
		"value": "长春市",
		"label": "长春市"
	}, {
		"code": "220200000000",
		"value": "吉林市",
		"label": "吉林市"
	}, {
		"code": "220300000000",
		"value": "四平市",
		"label": "四平市"
	}, {
		"code": "220400000000",
		"value": "辽源市",
		"label": "辽源市"
	}, {
		"code": "220500000000",
		"value": "通化市",
		"label": "通化市"
	}, {
		"code": "220600000000",
		"value": "白山市",
		"label": "白山市"
	}, {
		"code": "220700000000",
		"value": "松原市",
		"label": "松原市"
	}, {
		"code": "220800000000",
		"value": "白城市",
		"label": "白城市"
	}, {
		"code": "222400000000",
		"value": "延边朝鲜族自治州",
		"label": "延边朝鲜族自治州"
	}],
	"value": "吉林省",
	"label": "吉林省"
}, {
	"children": [{
		"code": "230100000000",
		"value": "哈尔滨市",
		"label": "哈尔滨市"
	}, {
		"code": "230200000000",
		"value": "齐齐哈尔市",
		"label": "齐齐哈尔市"
	}, {
		"code": "230300000000",
		"value": "鸡西市",
		"label": "鸡西市"
	}, {
		"code": "230400000000",
		"value": "鹤岗市",
		"label": "鹤岗市"
	}, {
		"code": "230500000000",
		"value": "双鸭山市",
		"label": "双鸭山市"
	}, {
		"code": "230500000000",
		"value": "大庆市",
		"label": "大庆市"
	}, {
		"code": "230700000000",
		"value": "伊春市",
		"label": "伊春市"
	}, {
		"code": "230800000000",
		"value": "佳木斯市",
		"label": "佳木斯市"
	}, {
		"code": "230900000000",
		"value": "七台河市",
		"label": "七台河市"
	}, {
		"code": "231000000000",
		"value": "牡丹江市",
		"label": "牡丹江市"
	}, {
		"code": "231100000000",
		"value": "黑河市",
		"label": "黑河市"
	}, {
		"code": "231200000000",
		"value": "绥化市",
		"label": "绥化市"
	}, {
		"code": "232700000000",
		"value": "大兴安岭地区",
		"label": "大兴安岭地区"
	}],
	"value": "黑龙江省",
	"label": "黑龙江省"
}, {
	"children": [{
		"code": "310101000000",
		"value": "黄浦区",
		"label": "黄浦区"
	}, {
		"code": "310104000000",
		"value": "徐汇区",
		"label": "徐汇区"
	}, {
		"code": "310105000000",
		"value": "长宁区",
		"label": "长宁区"
	}, {
		"code": "310106000000",
		"value": "静安区",
		"label": "静安区"
	}, {
		"code": "310107000000",
		"value": "普陀区",
		"label": "普陀区"
	}, {
		"code": "310109000000",
		"value": "虹口区",
		"label": "虹口区"
	}, {
		"code": "310110000000",
		"value": "杨浦区",
		"label": "杨浦区"
	}, {
		"code": "310112000000",
		"value": "闵行区",
		"label": "闵行区"
	}, {
		"code": "310113000000",
		"value": "宝山区",
		"label": "宝山区"
	}, {
		"code": "310114000000",
		"value": "嘉定区",
		"label": "嘉定区"
	}, {
		"code": "310115000000",
		"value": "浦东新区",
		"label": "浦东新区"
	}, {
		"code": "310116000000",
		"value": "金山区",
		"label": "金山区"
	}, {
		"code": "310117000000",
		"value": "松江区",
		"label": "松江区"
	}, {
		"code": "310118000000",
		"value": "青浦区",
		"label": "青浦区"
	}, {
		"code": "310120000000",
		"value": "奉贤区",
		"label": "奉贤区"
	}, {
		"code": "310151000000",
		"value": "崇明区",
		"label": "崇明区"
	}],
	"code": "310100000000",
	"value": "上海",
	"label": "上海"
}, {
	"children": [{
		"code": "320200000000",
		"value": "无锡市",
		"label": "无锡市"
	}, {
		"code": "320300000000",
		"value": "徐州市",
		"label": "徐州市"
	}, {
		"code": "320400000000",
		"value": "常州市",
		"label": "常州市"
	}, {
		"code": "320500000000",
		"value": "苏州市",
		"label": "苏州市"
	}, {
		"code": "320600000000",
		"value": "南通市",
		"label": "南通市"
	}, {
		"code": "320700000000",
		"value": "连云港市",
		"label": "连云港市"
	}, {
		"code": "320800000000",
		"value": "淮安市",
		"label": "淮安市"
	}, {
		"code": "320900000000",
		"value": "盐城市",
		"label": "盐城市"
	}, {
		"code": "321000000000",
		"value": "扬州市",
		"label": "扬州市"
	}, {
		"code": "321100000000",
		"value": "镇江市",
		"label": "镇江市"
	}, {
		"code": "321200000000",
		"value": "泰州市",
		"label": "泰州市"
	}, {
		"code": "321300000000",
		"value": "宿迁市",
		"label": "宿迁市"
	}, {
		"code": "320100000000",
		"value": "南京市",
		"label": "南京市"
	}],
	"value": "江苏省",
	"label": "江苏省"
}, {
	"children": [{
		"code": "330100000000",
		"value": "杭州市",
		"label": "杭州市"
	}, {
		"code": "330200000000",
		"value": "宁波市",
		"label": "宁波市"
	}, {
		"code": "330300000000",
		"value": "温州市",
		"label": "温州市"
	}, {
		"code": "330400000000",
		"value": "嘉兴市",
		"label": "嘉兴市"
	}, {
		"code": "330500000000",
		"value": "湖州市",
		"label": "湖州市"
	}, {
		"code": "330600000000",
		"value": "绍兴市",
		"label": "绍兴市"
	}, {
		"code": "330700000000",
		"value": "金华市",
		"label": "金华市"
	}, {
		"code": "330800000000",
		"value": "衢州市",
		"label": "衢州市"
	}, {
		"code": "330900000000",
		"value": "舟山市",
		"label": "舟山市"
	}, {
		"code": "331000000000",
		"value": "台州市",
		"label": "台州市"
	}, {
		"code": "331100000000",
		"value": "丽水市",
		"label": "丽水市"
	}],
	"value": "浙江省",
	"label": "浙江省"
}, {
	"children": [{
		"code": "340100000000",
		"value": "合肥市",
		"label": "合肥市"
	}, {
		"code": "340200000000",
		"value": "芜湖市",
		"label": "芜湖市"
	}, {
		"code": "340300000000",
		"value": "蚌埠市",
		"label": "蚌埠市"
	}, {
		"code": "340400000000",
		"value": "淮南市",
		"label": "淮南市"
	}, {
		"code": "340500000000",
		"value": "马鞍山市",
		"label": "马鞍山市"
	}, {
		"code": "340600000000",
		"value": "淮北市",
		"label": "淮北市"
	}, {
		"code": "340700000000",
		"value": "铜陵市",
		"label": "铜陵市"
	}, {
		"code": "340800000000",
		"value": "安庆市",
		"label": "安庆市"
	}, {
		"code": "341000000000",
		"value": "黄山市",
		"label": "黄山市"
	}, {
		"code": "341100000000",
		"value": "滁州市",
		"label": "滁州市"
	}, {
		"code": "341200000000",
		"value": "阜阳市",
		"label": "阜阳市"
	}, {
		"code": "341300000000",
		"value": "宿州市",
		"label": "宿州市"
	}, {
		"code": "341500000000",
		"value": "六安市",
		"label": "六安市"
	}, {
		"code": "341600000000",
		"value": "亳州市",
		"label": "亳州市"
	}, {
		"code": "341700000000",
		"value": "池州市",
		"label": "池州市"
	}, {
		"code": "341800000000",
		"value": "宣城市",
		"label": "宣城市"
	}],
	"value": "安徽省",
	"label": "安徽省"
}, {
	"children": [{
		"code": "350100000000",
		"value": "福州市",
		"label": "福州市"
	}, {
		"code": "350200000000",
		"value": "厦门市",
		"label": "厦门市"
	}, {
		"code": "350300000000",
		"value": "莆田市",
		"label": "莆田市"
	}, {
		"code": "350400000000",
		"value": "三明市",
		"label": "三明市"
	}, {
		"code": "350500000000",
		"value": "泉州市",
		"label": "泉州市"
	}, {
		"code": "350600000000",
		"value": "漳州市",
		"label": "漳州市"
	}, {
		"code": "350700000000",
		"value": "南平市",
		"label": "南平市"
	}, {
		"code": "350800000000",
		"value": "龙岩市",
		"label": "龙岩市"
	}, {
		"code": "350900000000",
		"value": "宁德市",
		"label": "宁德市"
	}],
	"value": "福建省",
	"label": "福建省"
}, {
	"children": [{
		"code": "360100000000",
		"value": "南昌市",
		"label": "南昌市"
	}, {
		"code": "360200000000",
		"value": "景德镇市",
		"label": "景德镇市"
	}, {
		"code": "360300000000",
		"value": "萍乡市",
		"label": "萍乡市"
	}, {
		"code": "360400000000",
		"value": "九江市",
		"label": "九江市"
	}, {
		"code": "360500000000",
		"value": "新余市",
		"label": "新余市"
	}, {
		"code": "360600000000",
		"value": "鹰潭市",
		"label": "鹰潭市"
	}, {
		"code": "360700000000",
		"value": "赣州市",
		"label": "赣州市"
	}, {
		"code": "360800000000",
		"value": "吉安市",
		"label": "吉安市"
	}, {
		"code": "360900000000",
		"value": "宜春市",
		"label": "宜春市"
	}, {
		"code": "361000000000",
		"value": "抚州市",
		"label": "抚州市"
	}, {
		"code": "361100000000",
		"value": "上饶市",
		"label": "上饶市"
	}],
	"value": "江西省",
	"label": "江西省"
}, {
	"children": [{
		"code": "370100000000",
		"value": "济南市",
		"label": "济南市"
	}, {
		"code": "370200000000",
		"value": "青岛市",
		"label": "青岛市"
	}, {
		"code": "370300000000",
		"value": "淄博市",
		"label": "淄博市"
	}, {
		"code": "370400000000",
		"value": "枣庄市",
		"label": "枣庄市"
	}, {
		"code": "370500000000",
		"value": "东营市",
		"label": "东营市"
	}, {
		"code": "370600000000",
		"value": "烟台市",
		"label": "烟台市"
	}, {
		"code": "370700000000",
		"value": "潍坊市",
		"label": "潍坊市"
	}, {
		"code": "370800000000",
		"value": "济宁市",
		"label": "济宁市"
	}, {
		"code": "370900000000",
		"value": "泰安市",
		"label": "泰安市"
	}, {
		"code": "371000000000",
		"value": "威海市",
		"label": "威海市"
	}, {
		"code": "371100000000",
		"value": "日照市",
		"label": "日照市"
	}, {
		"code": "371200000000",
		"value": "莱芜市",
		"label": "莱芜市"
	}, {
		"code": "371300000000",
		"value": "临沂市",
		"label": "临沂市"
	}, {
		"code": "371400000000",
		"value": "德州市",
		"label": "德州市"
	}, {
		"code": "371500000000",
		"value": "聊城市",
		"label": "聊城市"
	}, {
		"code": "371600000000",
		"value": "滨州市",
		"label": "滨州市"
	}, {
		"code": "371700000000",
		"value": "菏泽市",
		"label": "菏泽市"
	}],
	"value": "山东省",
	"label": "山东省"
}, {
	"children": [{
		"code": "410100000000",
		"value": "郑州市",
		"label": "郑州市"
	}, {
		"code": "410200000000",
		"value": "开封市",
		"label": "开封市"
	}, {
		"code": "410300000000",
		"value": "洛阳市",
		"label": "洛阳市"
	}, {
		"code": "410400000000",
		"value": "平顶山市",
		"label": "平顶山市"
	}, {
		"code": "410500000000",
		"value": "安阳市",
		"label": "安阳市"
	}, {
		"code": "410600000000",
		"value": "鹤壁市",
		"label": "鹤壁市"
	}, {
		"code": "410700000000",
		"value": "新乡市",
		"label": "新乡市"
	}, {
		"code": "410800000000",
		"value": "焦作市",
		"label": "焦作市"
	}, {
		"code": "410900000000",
		"value": "濮阳市",
		"label": "濮阳市"
	}, {
		"code": "411000000000",
		"value": "许昌市",
		"label": "许昌市"
	}, {
		"code": "411100000000",
		"value": "漯河市",
		"label": "漯河市"
	}, {
		"code": "411200000000",
		"value": "三门峡市",
		"label": "三门峡市"
	}, {
		"code": "411300000000",
		"value": "南阳市",
		"label": "南阳市"
	}, {
		"code": "411400000000",
		"value": "商丘市",
		"label": "商丘市"
	}, {
		"code": "411500000000",
		"value": "信阳市",
		"label": "信阳市"
	}, {
		"code": "411600000000",
		"value": "周口市",
		"label": "周口市"
	}, {
		"code": "411700000000",
		"value": "驻马店市",
		"label": "驻马店市"
	}, {
		"code": "419000000000",
		"value": "济源市",
		"label": "济源市"
	}],
	"value": "河南省",
	"label": "河南省"
}, {
	"children": [{
		"code": "420100000000",
		"value": "武汉市",
		"label": "武汉市"
	}, {
		"code": "420200000000",
		"value": "黄石市",
		"label": "黄石市"
	}, {
		"code": "420300000000",
		"value": "十堰市",
		"label": "十堰市"
	}, {
		"code": "420500000000",
		"value": "宜昌市",
		"label": "宜昌市"
	}, {
		"code": "420600000000",
		"value": "襄阳市",
		"label": "襄阳市"
	}, {
		"code": "420700000000",
		"value": "鄂州市",
		"label": "鄂州市"
	}, {
		"code": "420800000000",
		"value": "荆门市",
		"label": "荆门市"
	}, {
		"code": "420900000000",
		"value": "孝感市",
		"label": "孝感市"
	}, {
		"code": "421000000000",
		"value": "荆州市",
		"label": "荆州市"
	}, {
		"code": "421100000000",
		"value": "黄冈市",
		"label": "黄冈市"
	}, {
		"code": "421200000000",
		"value": "咸宁市",
		"label": "咸宁市"
	}, {
		"code": "421300000000",
		"value": "随州市",
		"label": "随州市"
	}, {
		"code": "422800000000",
		"value": "恩施土家族苗族自治州",
		"label": "恩施土家族苗族自治州"
	}, {
		"code": "429000000000",
		"value": "省直辖县级行政区",
		"label": "省直辖县级行政区"
	}],
	"value": "湖北省",
	"label": "湖北省"
}, {
	"children": [{
		"code": "430100000000",
		"value": "长沙市",
		"label": "长沙市"
	}, {
		"code": "430200000000",
		"value": "株洲市",
		"label": "株洲市"
	}, {
		"code": "430300000000",
		"value": "湘潭市",
		"label": "湘潭市"
	}, {
		"code": "430400000000",
		"value": "衡阳市",
		"label": "衡阳市"
	}, {
		"code": "430500000000",
		"value": "邵阳市",
		"label": "邵阳市"
	}, {
		"code": "430600000000",
		"value": "岳阳市",
		"label": "岳阳市"
	}, {
		"code": "430700000000",
		"value": "常德市",
		"label": "常德市"
	}, {
		"code": "430800000000",
		"value": "张家界市",
		"label": "张家界市"
	}, {
		"code": "430900000000",
		"value": "益阳市",
		"label": "益阳市"
	}, {
		"code": "431000000000",
		"value": "郴州市",
		"label": "郴州市"
	}, {
		"code": "431100000000",
		"value": "永州市",
		"label": "永州市"
	}, {
		"code": "431200000000",
		"value": "怀化市",
		"label": "怀化市"
	}, {
		"code": "431300000000",
		"value": "娄底市",
		"label": "娄底市"
	}, {
		"code": "433100000000",
		"value": "湘西土家族苗族自治州",
		"label": "湘西土家族苗族自治州"
	}],
	"value": "湖南省",
	"label": "湖南省"
}, {
	"children": [{
		"code": "440100000000",
		"value": "广州市",
		"label": "广州市"
	}, {
		"code": "440200000000",
		"value": "韶关市",
		"label": "韶关市"
	}, {
		"code": "440300000000",
		"value": "深圳市",
		"label": "深圳市"
	}, {
		"code": "440400000000",
		"value": "珠海市",
		"label": "珠海市"
	}, {
		"code": "440500000000",
		"value": "汕头市",
		"label": "汕头市"
	}, {
		"code": "440600000000",
		"value": "佛山市",
		"label": "佛山市"
	}, {
		"code": "440700000000",
		"value": "江门市",
		"label": "江门市"
	}, {
		"code": "440800000000",
		"value": "湛江市",
		"label": "湛江市"
	}, {
		"code": "440900000000",
		"value": "茂名市",
		"label": "茂名市"
	}, {
		"code": "441200000000",
		"value": "肇庆市",
		"label": "肇庆市"
	}, {
		"code": "441300000000",
		"value": "惠州市",
		"label": "惠州市"
	}, {
		"code": "441400000000",
		"value": "梅州市",
		"label": "梅州市"
	}, {
		"code": "441500000000",
		"value": "汕尾市",
		"label": "汕尾市"
	}, {
		"code": "441600000000",
		"value": "河源市",
		"label": "河源市"
	}, {
		"code": "441700000000",
		"value": "阳江市",
		"label": "阳江市"
	}, {
		"code": "441800000000",
		"value": "清远市",
		"label": "清远市"
	}, {
		"code": "441900000000",
		"value": "东莞市",
		"label": "东莞市"
	}, {
		"code": "442000000000",
		"value": "中山市",
		"label": "中山市"
	}, {
		"code": "445100000000",
		"value": "潮州市",
		"label": "潮州市"
	}, {
		"code": "445200000000",
		"value": "揭阳市",
		"label": "揭阳市"
	}, {
		"code": "445300000000",
		"value": "云浮市",
		"label": "云浮市"
	}],
	"value": "广东省",
	"label": "广东省"
}, {
	"children": [{
		"code": "450100000000",
		"value": "南宁市",
		"label": "南宁市"
	}, {
		"code": "450200000000",
		"value": "柳州市",
		"label": "柳州市"
	}, {
		"code": "450300000000",
		"value": "桂林市",
		"label": "桂林市"
	}, {
		"code": "450400000000",
		"value": "梧州市",
		"label": "梧州市"
	}, {
		"code": "450500000000",
		"value": "北海市",
		"label": "北海市"
	}, {
		"code": "450600000000",
		"value": "防城港市",
		"label": "防城港市"
	}, {
		"code": "450700000000",
		"value": "钦州市",
		"label": "钦州市"
	}, {
		"code": "450800000000",
		"value": "贵港市",
		"label": "贵港市"
	}, {
		"code": "450900000000",
		"value": "玉林市",
		"label": "玉林市"
	}, {
		"code": "451000000000",
		"value": "百色市",
		"label": "百色市"
	}, {
		"code": "451100000000",
		"value": "贺州市",
		"label": "贺州市"
	}, {
		"code": "451200000000",
		"value": "河池市",
		"label": "河池市"
	}, {
		"code": "451300000000",
		"value": "来宾市",
		"label": "来宾市"
	}, {
		"code": "451400000000",
		"value": "崇左市",
		"label": "崇左市"
	}],
	"value": "广西壮族自治区",
	"label": "广西壮族自治区"
}, {
	"children": [{
		"code": "460100000000",
		"value": "海口市",
		"label": "海口市"
	}, {
		"code": "460200000000",
		"value": "三亚市",
		"label": "三亚市"
	}, {
		"code": "460300000000",
		"value": "三沙市",
		"label": "三沙市"
	}, {
		"code": "460400000000",
		"value": "儋州市",
		"label": "儋州市"
	}, {
		"code": "469000000000",
		"value": "省直辖县级行政区",
		"label": "省直辖县级行政区"
	}],
	"value": "海南省",
	"label": "海南省"
}, {
	"children": [{
		"code": "500101000000",
		"value": "万州区",
		"label": "万州区"
	}, {
		"code": "500102000000",
		"value": "涪陵区",
		"label": "涪陵区"
	}, {
		"code": "500103000000",
		"value": "渝中区",
		"label": "渝中区"
	}, {
		"code": "500104000000",
		"value": "大渡口区",
		"label": "大渡口区"
	}, {
		"code": "500105000000",
		"value": "江北区",
		"label": "江北区"
	}, {
		"code": "500106000000",
		"value": "沙坪坝区",
		"label": "沙坪坝区"
	}, {
		"code": "500107000000",
		"value": "九龙坡区",
		"label": "九龙坡区"
	}, {
		"code": "500108000000",
		"value": "南岸区",
		"label": "南岸区"
	}, {
		"code": "500109000000",
		"value": "北碚区",
		"label": "北碚区"
	}, {
		"code": "500110000000",
		"value": "綦江区",
		"label": "綦江区"
	}, {
		"code": "500111000000",
		"value": "大足区",
		"label": "大足区"
	}, {
		"code": "500112000000",
		"value": "渝北区",
		"label": "渝北区"
	}, {
		"code": "500113000000",
		"value": "巴南区",
		"label": "巴南区"
	}, {
		"code": "500114000000",
		"value": "黔江区",
		"label": "黔江区"
	}, {
		"code": "500115000000",
		"value": "长寿区",
		"label": "长寿区"
	}, {
		"code": "500116000000",
		"value": "江津区",
		"label": "江津区"
	}, {
		"code": "500117000000",
		"value": "合川区",
		"label": "合川区"
	}, {
		"code": "500118000000",
		"value": "永川区",
		"label": "永川区"
	}, {
		"code": "500119000000",
		"value": "南川区",
		"label": "南川区"
	}, {
		"code": "500120000000",
		"value": "璧山区",
		"label": "璧山区"
	}, {
		"code": "500151000000",
		"value": "铜梁区",
		"label": "铜梁区"
	}, {
		"code": "500152000000",
		"value": "潼南区",
		"label": "潼南区"
	}, {
		"code": "500153000000",
		"value": "荣昌区",
		"label": "荣昌区"
	}, {
		"code": "500154000000",
		"value": "开州区",
		"label": "开州区"
	}, {
		"code": "500155000000",
		"value": "梁平区",
		"label": "梁平区"
	}, {
		"code": "500156000000",
		"value": "武隆区",
		"label": "武隆区"
	}, {
		"code": "500229000000",
		"value": "城口县",
		"label": "城口县"
	}, {
		"code": "500230000000",
		"value": "丰都县",
		"label": "丰都县"
	}, {
		"code": "500231000000",
		"value": "垫江县",
		"label": "垫江县"
	}, {
		"code": "500233000000",
		"value": "忠县",
		"label": "忠县"
	}, {
		"code": "500235000000",
		"value": "云阳县",
		"label": "云阳县"
	}, {
		"code": "500236000000",
		"value": "奉节县",
		"label": "奉节县"
	}, {
		"code": "500237000000",
		"value": "巫山县",
		"label": "巫山县"
	}, {
		"code": "500238000000",
		"value": "巫溪县",
		"label": "巫溪县"
	}, {
		"code": "500240000000",
		"value": "石柱土家族自治县",
		"label": "石柱土家族自治县"
	}, {
		"code": "500241000000",
		"value": "秀山土家族苗族自治县",
		"label": "秀山土家族苗族自治县"
	}, {
		"code": "500242000000",
		"value": "酉阳土家族苗族自治县",
		"label": "酉阳土家族苗族自治县"
	}, {
		"code": "500243000000",
		"value": "彭水苗族土家族自治县",
		"label": "彭水苗族土家族自治县"
	}],
	"code": "500100000000",
	"value": "重庆",
	"label": "重庆"
}, {
	"children": [{
		"code": "510100000000",
		"value": "成都市",
		"label": "成都市"
	}, {
		"code": "510300000000",
		"value": "自贡市",
		"label": "自贡市"
	}, {
		"code": "510400000000",
		"value": "攀枝花市",
		"label": "攀枝花市"
	}, {
		"code": "510500000000",
		"value": "泸州市",
		"label": "泸州市"
	}, {
		"code": "510600000000",
		"value": "德阳市",
		"label": "德阳市"
	}, {
		"code": "510700000000",
		"value": "绵阳市",
		"label": "绵阳市"
	}, {
		"code": "510800000000",
		"value": "广元市",
		"label": "广元市"
	}, {
		"code": "510900000000",
		"value": "遂宁市",
		"label": "遂宁市"
	}, {
		"code": "511000000000",
		"value": "内江市",
		"label": "内江市"
	}, {
		"code": "511100000000",
		"value": "乐山市",
		"label": "乐山市"
	}, {
		"code": "511300000000",
		"value": "南充市",
		"label": "南充市"
	}, {
		"code": "511400000000",
		"value": "眉山市",
		"label": "眉山市"
	}, {
		"code": "511500000000",
		"value": "宜宾市",
		"label": "宜宾市"
	}, {
		"code": "511600000000",
		"value": "广安市",
		"label": "广安市"
	}, {
		"code": "511700000000",
		"value": "达州市",
		"label": "达州市"
	}, {
		"code": "511800000000",
		"value": "雅安市",
		"label": "雅安市"
	}, {
		"code": "511900000000",
		"value": "巴中市",
		"label": "巴中市"
	}, {
		"code": "512000000000",
		"value": "资阳市",
		"label": "资阳市"
	}, {
		"code": "513200000000",
		"value": "阿坝藏族羌族自治州",
		"label": "阿坝藏族羌族自治州"
	}, {
		"code": "513300000000",
		"value": "甘孜藏族自治州",
		"label": "甘孜藏族自治州"
	}, {
		"code": "513400000000",
		"value": "凉山彝族自治州",
		"label": "凉山彝族自治州"
	}],
	"value": "四川省",
	"label": "四川省"
}, {
	"children": [{
		"code": "520100000000",
		"value": "贵阳市",
		"label": "贵阳市"
	}, {
		"code": "520200000000",
		"value": "六盘水市",
		"label": "六盘水市"
	}, {
		"code": "520300000000",
		"value": "遵义市",
		"label": "遵义市"
	}, {
		"code": "520400000000",
		"value": "安顺市",
		"label": "安顺市"
	}, {
		"code": "520500000000",
		"value": "毕节市",
		"label": "毕节市"
	}, {
		"code": "520600000000",
		"value": "铜仁市",
		"label": "铜仁市"
	}, {
		"code": "522300000000",
		"value": "黔西南布依族苗族自治州",
		"label": "黔西南布依族苗族自治州"
	}, {
		"code": "522600000000",
		"value": "黔东南苗族侗族自治州",
		"label": "黔东南苗族侗族自治州"
	}, {
		"code": "522700000000",
		"value": "黔南布依族苗族自治州",
		"label": "黔南布依族苗族自治州"
	}],
	"value": "贵州省",
	"label": "贵州省"
}, {
	"children": [{
		"code": "530100000000",
		"value": "昆明市",
		"label": "昆明市"
	}, {
		"code": "530300000000",
		"value": "曲靖市",
		"label": "曲靖市"
	}, {
		"code": "530400000000",
		"value": "玉溪市",
		"label": "玉溪市"
	}, {
		"code": "530500000000",
		"value": "保山市",
		"label": "保山市"
	}, {
		"code": "530600000000",
		"value": "昭通市",
		"label": "昭通市"
	}, {
		"code": "530700000000",
		"value": "丽江市",
		"label": "丽江市"
	}, {
		"code": "530800000000",
		"value": "普洱市",
		"label": "普洱市"
	}, {
		"code": "530900000000",
		"value": "临沧市",
		"label": "临沧市"
	}, {
		"code": "532300000000",
		"value": "楚雄彝族自治州",
		"label": "楚雄彝族自治州"
	}, {
		"code": "532500000000",
		"value": "红河哈尼族彝族自治州",
		"label": "红河哈尼族彝族自治州"
	}, {
		"code": "532600000000",
		"value": "文山壮族苗族自治州",
		"label": "文山壮族苗族自治州"
	}, {
		"code": "532800000000",
		"value": "西双版纳傣族自治州",
		"label": "西双版纳傣族自治州"
	}, {
		"code": "532900000000",
		"value": "大理白族自治州",
		"label": "大理白族自治州"
	}, {
		"code": "533100000000",
		"value": "德宏傣族景颇族自治州",
		"label": "德宏傣族景颇族自治州"
	}, {
		"code": "533300000000",
		"value": "怒江傈僳族自治州",
		"label": "怒江傈僳族自治州"
	}, {
		"code": "533400000000",
		"value": "迪庆藏族自治州",
		"label": "迪庆藏族自治州"
	}],
	"value": "云南省",
	"label": "云南省"
}, {
	"children": [{
		"code": "540100000000",
		"value": "拉萨市",
		"label": "拉萨市"
	}, {
		"code": "540200000000",
		"value": "日喀则市",
		"label": "日喀则市"
	}, {
		"code": "540300000000",
		"value": "昌都市",
		"label": "昌都市"
	}, {
		"code": "540400000000",
		"value": "林芝市",
		"label": "林芝市"
	}, {
		"code": "540500000000",
		"value": "山南市",
		"label": "山南市"
	}, {
		"code": "542400000000",
		"value": "那曲地区",
		"label": "那曲地区"
	}, {
		"code": "542500000000",
		"value": "阿里地区",
		"label": "阿里地区"
	}],
	"value": "西藏自治区",
	"label": "西藏自治区"
}, {
	"children": [{
		"code": "610100000000",
		"value": "西安市",
		"label": "西安市"
	}, {
		"code": "610200000000",
		"value": "铜川市",
		"label": "铜川市"
	}, {
		"code": "610300000000",
		"value": "宝鸡市",
		"label": "宝鸡市"
	}, {
		"code": "610400000000",
		"value": "咸阳市",
		"label": "咸阳市"
	}, {
		"code": "610500000000",
		"value": "渭南市",
		"label": "渭南市"
	}, {
		"code": "610600000000",
		"value": "延安市",
		"label": "延安市"
	}, {
		"code": "610700000000",
		"value": "汉中市",
		"label": "汉中市"
	}, {
		"code": "610800000000",
		"value": "榆林市",
		"label": "榆林市"
	}, {
		"code": "610900000000",
		"value": "安康市",
		"label": "安康市"
	}, {
		"code": "611000000000",
		"value": "商洛市",
		"label": "商洛市"
	}],
	"value": "陕西省",
	"label": "陕西省"
}, {
	"children": [{
		"code": "620100000000",
		"value": "兰州市",
		"label": "兰州市"
	}, {
		"code": "620200000000",
		"value": "嘉峪关市",
		"label": "嘉峪关市"
	}, {
		"code": "620300000000",
		"value": "金昌市",
		"label": "金昌市"
	}, {
		"code": "620400000000",
		"value": "白银市",
		"label": "白银市"
	}, {
		"code": "620500000000",
		"value": "天水市",
		"label": "天水市"
	}, {
		"code": "620600000000",
		"value": "武威市",
		"label": "武威市"
	}, {
		"code": "620700000000",
		"value": "张掖市",
		"label": "张掖市"
	}, {
		"code": "620800000000",
		"value": "平凉市",
		"label": "平凉市"
	}, {
		"code": "620900000000",
		"value": "酒泉市",
		"label": "酒泉市"
	}, {
		"code": "621000000000",
		"value": "庆阳市",
		"label": "庆阳市"
	}, {
		"code": "621100000000",
		"value": "定西市",
		"label": "定西市"
	}, {
		"code": "621200000000",
		"value": "陇南市",
		"label": "陇南市"
	}, {
		"code": "622900000000",
		"value": "临夏回族自治州",
		"label": "临夏回族自治州"
	}, {
		"code": "623000000000",
		"value": "甘南藏族自治州",
		"label": "甘南藏族自治州"
	}],
	"value": "甘肃省",
	"label": "甘肃省"
}, {
	"children": [{
		"code": "630100000000",
		"value": "西宁市",
		"label": "西宁市"
	}, {
		"code": "630200000000",
		"value": "海东市",
		"label": "海东市"
	}, {
		"code": "632200000000",
		"value": "海北藏族自治州",
		"label": "海北藏族自治州"
	}, {
		"code": "632300000000",
		"value": "黄南藏族自治州",
		"label": "黄南藏族自治州"
	}, {
		"code": "632500000000",
		"value": "海南藏族自治州",
		"label": "海南藏族自治州"
	}, {
		"code": "632600000000",
		"value": "果洛藏族自治州",
		"label": "果洛藏族自治州"
	}, {
		"code": "632700000000",
		"value": "玉树藏族自治州",
		"label": "玉树藏族自治州"
	}, {
		"code": "632800000000",
		"value": "海西蒙古族藏族自治州",
		"label": "海西蒙古族藏族自治州"
	}],
	"value": "青海省",
	"label": "青海省"
}, {
	"children": [{
		"code": "640100000000",
		"value": "银川市",
		"label": "银川市"
	}, {
		"code": "640200000000",
		"value": "石嘴山市",
		"label": "石嘴山市"
	}, {
		"code": "640300000000",
		"value": "吴忠市",
		"label": "吴忠市"
	}, {
		"code": "640400000000",
		"value": "固原市",
		"label": "固原市"
	}, {
		"code": "640500000000",
		"value": "中卫市",
		"label": "中卫市"
	}],
	"value": "宁夏回族自治区",
	"label": "宁夏回族自治区"
}, {
	"children": [{
		"code": "650100000000",
		"value": "乌鲁木齐市",
		"label": "乌鲁木齐市"
	}, {
		"code": "650200000000",
		"value": "克拉玛依市",
		"label": "克拉玛依市"
	}, {
		"code": "650400000000",
		"value": "吐鲁番市",
		"label": "吐鲁番市"
	}, {
		"code": "650500000000",
		"value": "哈密市",
		"label": "哈密市"
	}, {
		"code": "652300000000",
		"value": "昌吉回族自治州",
		"label": "昌吉回族自治州"
	}, {
		"code": "652700000000",
		"value": "博尔塔拉蒙古自治州",
		"label": "博尔塔拉蒙古自治州"
	}, {
		"code": "652800000000",
		"value": "巴音郭楞蒙古自治州",
		"label": "巴音郭楞蒙古自治州"
	}, {
		"code": "652900000000",
		"value": "阿克苏地区",
		"label": "阿克苏地区"
	}, {
		"code": "653000000000",
		"value": "克孜勒苏柯尔克孜自治州",
		"label": "克孜勒苏柯尔克孜自治州"
	}, {
		"code": "653100000000",
		"value": "喀什地区",
		"label": "喀什地区"
	}, {
		"code": "653200000000",
		"value": "和田地区",
		"label": "和田地区"
	}, {
		"code": "654000000000",
		"value": "伊犁哈萨克自治州",
		"label": "伊犁哈萨克自治州"
	}, {
		"code": "654200000000",
		"value": "塔城地区",
		"label": "塔城地区"
	}, {
		"code": "654300000000",
		"value": "阿勒泰地区",
		"label": "阿勒泰地区"
	}, {
		"code": "659000000000",
		"value": "自治区直辖县级行政区划",
		"label": "自治区直辖县级行政区划"
	}],
	"value": "新疆维吾尔自治区",
	"label": "新疆维吾尔自治区"
}]




