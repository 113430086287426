<template>
	<div class="alltitlStyle">
		<h2 class="modTitlte">
			<span v-html="titleObj.title"></span>
		</h2>
		<p v-if="titleObj.p" v-html="titleObj.p"></p>
    <strong>{{titleObj.text}}</strong>
	</div>
</template>
<script>
export default {
	name: "TitleAssembly",
	props: {
		titleObj:{
      type:Object,
      default:{}
    }
	},
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>
<style lang="scss" scoped>
.alltitlStyle{
 margin:30px;
 p{
   text-align:center;
   font-size:18px;
 }
 strong{
    margin-top:10px;
    display: block;
    color: #333333;
    font-size:18px;
    text-align: center;
  }
}
.modTitlte {
	font-size: 30px;
	text-align: center;
	margin: 56px 0 25px 0;
  z-index:99;
  span{
    display: inline-block;
    position: relative;
    z-index:100;
    font-weight: 700;
    color:#333;
  }
}
.modTitlte span::after{
  content:'';
  width:100%;
  left:0;
  right:0;
  height:10px;
  border-radius: 10px;
  background:#3e7bf8;
  opacity: 0.17;
  position: absolute;
  display: inline-block;
  z-index:-1;
  bottom:3px;
}
</style>
