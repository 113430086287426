<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosf"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap form-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="获客如此简单"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg2" :moduleText="moduleText2" title="极简表单 . 更易传播" lineW="1200px" :titleStyle="false" :imgTitle="imgJsonFrom.img1.title" :imgAlt="imgJsonFrom.img1.alt"></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <show-module title="使用场景" :titleText="titleText1" :bg="showModuleBg" :liText="liText"></show-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
import ShowModule from './components/ShowModule'
import ImgList from './components/imgListModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    TbModule,
    ShowModule,
    ImgList,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/formMarketBanner.png'),
        titleText1:'表单主要是面向以C端业务为主的企业，业务的本地化、区域性属性较强，尤其适合以线下门店为主的教育培训类、健身类、美容等行业。',
        showModuleBg:require('../../assets/img/form-img3.png'),
        listText:[{'title':'设计便捷','text':'看到功能就会用，学习零成本'},{'title':'传播获客','text':'基于微信的快速传播，高效获客'}],
        liText:['广告行业','服务行业','旅游行业','餐饮行业','电子商务','快递行业'],
        moduleText2:'表单营销是极简的H5表单页面，主要面向服务于C端客户的服务类企业，表单制作简便，表单内容简洁、关键点突出，易于用户方便、快捷的大致了解企业，熟悉企业相关的服务产品；基于微信强大的社交资源，通过在好友、朋友圈之间传播，用户更容易接受产品，并形成有效获客',
        moduleText3:'表单主要是面向以C端业务为主的企业，业务的本地化、区域性属性较强，尤其适合以线下门店为主的教育培训类、健身类、美容等行业；',
        moduleImg1:require('../../assets/img/form-img1.png'),
        moduleImg2:require('../../assets/img/form-img2.png'),
        imgJsonFrom: this.$store.state.imgJson.fromImg
     };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  //方法集合
  methods: {

  },
}
</script>
<style>
  .form-module .module .modulec-con li{
    width:45%;
  }  
  .form-module .module .modulec-con ul{
    padding-top:245px;
  }
  .form-module .titleS p{
      font-size:18px;
  }
  .btnPosf .apply-btn{
    width:l82px;
    margin-top:418px;
    border-color:#fff;
    color:#fff;
  }
</style>