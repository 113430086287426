<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="mydata.banner.img" bgcolor="#ec6941">
      <div class="banner-text">
        <h4 :style="{color:topTextColor}">{{ mydata.banner.tit }}</h4>
        <ul>
          <li :style="{color:topTextColor}" v-for="(item, index) in mydata.banner.lis" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog :mycolor="topTextColor" ></apply-dialog>
    </Banner>

    <div class="sec1">
      <h1>行业难点</h1>
      <ul>
        <li v-for="(item, index) in mydata.sec1.lis" :key="index">
          <h2>{{ item.tit }}</h2>
          <p>{{ item.text }}</p>
        </li>
      </ul>
      <img :src="mydata.sec1.img" alt="" />
      <button class="applayBtnRed" :style="{color:mydata.color,backgroundColor:'#fff',border:'2px solid '+mydata.color}" @click="$store.commit('CHANGE_MODAL_TRUE')">
        开通申请
      </button>
    </div>

    <div class="sec2" v-if="$route.name !== 'jrfw'">
      <h1>功能亮点</h1>
      <ul>
        <li
          v-for="(item, index) in mydata.sec2.lis"
          :key="index"
        >
          <img
            class="text1"
            src="./../assets/img/caseImg/cs/sec2.3.png"
            alt=""
          />
          <img class="text2 h2-active" :src="mydata.sec2.img3" alt="" />
          <h2>{{ item.tit }}</h2>
          <p>{{ item.text }}</p>
          <!-- <p :style="{'background-image':'url('+mydata.sec2.img1+')'}">{{ item.text }}</p> -->
          <img class="p1" :src="mydata.sec2.img1" alt="" />
          <img class="p2 h2-active" :src="mydata.sec2.img2" alt="" />
        </li>
      </ul>
      <button class="applayBtnRed" :style="{color:mydata.color,backgroundColor:'#fff',border:'1px solid '+mydata.color}" @click="$store.commit('CHANGE_MODAL_TRUE')">
        开通申请
      </button>
    </div>

    <div class="sec3" :style="{background:'url('+mydata.sec3.img+') no-repeat',backgroundSize:'100% 100%'}">
      <h1>解决方案</h1>
      <ul>
        <li v-for="(item, index) in mydata.sec3.lis" :key="index" :class="[{'fdcjy':$route.name=='jy'||$route.name=='fdc'},{'zz':$route.name=='zz'},{'zx':$route.name=='zx'}]">
          <img :src="item.icon" alt />
          <div>
            <h2>{{ item.tit }}</h2>
            <p>{{ item.text }}</p>
          </div>
        </li>
      </ul>
      <button class="applayBtnRed" :style="{color:mydata.color,backgroundColor:'#fff',border:'1px solid '+mydata.color}" @click="$store.commit('CHANGE_MODAL_TRUE')">
        开通申请
      </button>
    </div>

    <apply-dialog-btn
      :modelVisible="$store.state.applyModal"
      @cancle="$store.commit('CHANGE_MODAL_FALSE')"
    ></apply-dialog-btn>
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
export default {
  props: ["mydata","topTextColor"],
  data() {
    return {};
  },
  components: {
    Banner,
    ApplyDialog,
  },
  created() {},
  mounted() {
    console.log(this.mydata, "00000000000000000000000000000");
    console.log(this.$route);
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 180px;
  overflow: hidden;
  h4 {
    margin-bottom: 22px;
    font-size: 48px;
    color: #ec6941;
    line-height: normal;
  }
  li {
    height: 50px;
    font-size: 26px;
    color: #ec6941;
  }
}
.box {
  // overflow: hidden;
  h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 100px;
  }
  .sec1 {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 230px;
      left: 460px;
    }
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 60px;
      li {
        width: 35%;
        margin-bottom: 60px;
        h2 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        p {
        }
      }
    }
  }
  .sec2 {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 60px;
      li {
        width: 280px;
        height: 340px;
        // background-color: wheat;
        position: relative;
        border: 1px solid rgb(228, 227, 227);
        overflow: hidden;
        h2 {
          width: 280px;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-weight: bold;
          font-size: 20px;
        }

        p {
          height: 280px;
          padding: 20px 15px;
          text-align: center;
          line-height: 30px;
          text-align: left;
        }
        img.text1,
        img.text2 {
          width: 280px;
          height: 100px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
        }
        img.p1,
        img.p2 {
          width: 200px;
          position: absolute;
          bottom: 0;
          right: -60px;
        }
        
        .h2-active {
          display: none;
        }
      }
      li:hover {
        .h2-active {
          display: block;
        }
        h2 {
          color: #fff;
        }
      }
    }
  }
  .sec3 {
    // background-image: url("./../../assets/img/caseImg/传媒/解决方案-背景.png");
    // overflow: hidden;
    margin-bottom: -50px;
    padding: 30px 0;
    h1 {
      color: #fff;
    }
    ul {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 60px;
      li {
        width: 49.5%;
        display: flex;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 6px;
        margin-bottom: 30px;
        padding: 30px 20px;
        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }
        div {
          color: #fff;
          h2 {
            font-weight: bold;
            margin-bottom: 15px;
            font-size: 20px;
          }
          p {
            line-height: 32px;
          }
        }
      }
      li.fdcjy{
        background-color: #ec69413a;
      }
      li.zz{
        background-color: #404f5c38;
      }
      li.zx{
        background-color: #716d6f4d;
      }
    }
  }
}
</style>