<!-- 左右文字图片布局-->
<template>
<div class="productTileList">
    <h4 class="title">{{title}}</h4>
    <p class="subtitle">{{titleText}}</p>
    <div class="product-list">
      <div v-for="(item,index) in productList" :key="index" class="product">
        <img :src="item.imgSrc" :alt="item.imgAlt" :title="item.imgTitle">
        <div class="title">
          {{item.title}}
        </div>
        <div v-html="item.content" class="content"></div>
      </div>
    </div>
</div>    
</template>

<script>
export default {
  name:'',
  components: {},
  props:{
    productList:{
      type:Array,
      default:[] 
    },
    title:String,
    titleText:String
  },
  data() {
     return {
     };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.productTileList{
  text-align: center;
  margin-top: 40px;
  color: #000;
  .title{
    margin-bottom: 25px;
    font-size: 36px;
  }
  .subtitle{
    margin-bottom: 25px;
  }
  .product-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    .product{
      width: 50%;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        display: block;
        height: 250px;
      }
      .title{
        font-size: 20px;
        font-weight: bold;
        margin: 20px 0;
      }
      .content{
        color: #666;
      }

    }
  }
}

 
</style>
<style lang='scss'>

</style>