<template>
  <div class="content-container">
    <div class="top-banner" :style="{'background-image':'url('+productBanners.t5+')'}">
      <div class="container-1200px">
        <div class="banner-info">
          <h2>快话T5</h2>
          <p>新一代智能营销服务终端<br />高效整合电话、微信、旺旺、QQ</p>
        </div>
      </div>
    </div>
    <div class="container-1200px">
      <ComplexProd :obj="t5.t5Complex"></ComplexProd>
      <LoopListProd :loopListProd="t5.loopListProd"></LoopListProd>
    </div>
    <div class="t5Fun">
      <h4>{{t5.t5Fun.topic}}</h4>
      <div>
        <ul class="container-1200px">
          <li v-for="(item,index) in t5.t5Fun.list" :key="index">
            {{item}}
          </li>
        </ul>
      </div>
    </div>
    <!-- <Main v-for="(item,index) in cards" :key="index" :cardItem="item" /> -->
  </div>
</template>
<script>
import { productBanners, t5 } from "../../../public/static/js/htmlContent";
import ComplexProd from  "./components/ComplexProd";
import LoopListProd from  "./components/LoopListProd";
export default {
  components: {
    // Main,
    ComplexProd,
    LoopListProd
  },
  data() {
    return {
      t5,
      productBanners
    };
  }
};
</script>
<style lang="scss" scoped>
.container-1200px {
  width: 1200px;
  margin: 0 auto;
  font-family: "Microsoft YaHei", "Arial Narrow", Arial, sans-serif;
}

.banner {
  height: 440px;

  .banner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
  }
}

.top-banner {
  height: 440px;
  display: flex;
  background-size: cover;
  background-position: 50%;
  margin-bottom: 100px;

  .banner-info {
    color: #fff;

    h2 {
      font-size: 48px;
      font-weight: 400;
      margin: 110px 0 24px;
    }

    p {
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 35px;
    }
  }
}
.t5Fun{
    // background: url("../../assets/img/t5-img7.png") no-repeat center 0;
}
.t5Fun h4{
  font-size:30px;
  color:#333;
  text-align: center;;
  margin-top:23px;
  height:260px;
  line-height:210px;
}
.t5Fun>div{
  // background:#f3f7fa;
  margin-bottom:50px;
}
.t5Fun ul{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  background: url("../../assets/img/t5-img6.png") no-repeat center 0;
  // height:407px;
}
.t5Fun ul li{
  font-size:16px;
  color:#333;
  padding-top:95px;
  height:168px;
  width:300px;
  text-align: center;
  overflow: hidden;
}
.t5Fun ul li:nth-child(odd){
  width:190px;
  text-align:left;
  margin-left:60px;
}
.t5Fun ul li:nth-child(3),.t5Fun ul li:nth-child(7){
    padding-left:65px;
    width:190px;
}
.t5Fun ul li:nth-child(4),.t5Fun ul li:nth-child(8){
  text-align:right;
  padding-right:40px;
}
.t5Fun ul li:nth-child(8){
  padding-right:60px;
}
</style>
