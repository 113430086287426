import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@api/system'
import imgJson from '../public/static/json/imgTitle'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headParams:{
      name:"浙江企蜂",
      keyword:"浙江企蜂",
      desc:"浙江企蜂"
    },
    applyModal:false,
    imgJson:imgJson
  },
  mutations: {
    GET_PARAMS: (state,systemParams) =>{
      state.headParams = Object.assign(systemParams)
    },
    CHANGE_MODAL_FALSE:(state) => {
      state.applyModal= false
    },
    CHANGE_MODAL_TRUE:(state) => {
      state.applyModal= true
    },
  },
  actions: {
    GetSystemData:({
      commit
    }) => {
      Api.fetchParamsList().then((res)=>{
        const data =  res.data.data
        const params = {
          name:data.name,
          keyword:data.keyword,
          desc:data.desc
        }
        commit('GET_PARAMS',params)
      })
      
    }
  }
})
