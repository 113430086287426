export const caseDataList = [
    {
        color:'#ec6941',
        banner: {
            img: require('./../../assets/img/caseImg/cs/top.png'),
            tit: '财税行业',
            lis: ['创新提高工作效率和流程，', '提高财税服务的专业度。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/cs/sec1.png'),

            lis: [
                {
                    tit: '传统模式数据不方便统计',
                    text: '传统的财税服务主要是手工模式，大量的中小涉税组织每个专业基层人员多依靠纸笔、案头查账或借助查账软件、拷贝资料等手工为主的操作模式，数据分散，统计不方便。'
                },
                {
                    tit: '企业获客难，竞争大，客户流失快',
                    text: '企业竞争也越来越激烈，获客成本也是居高不下，新客户引入不进来，老客户逐步流失。公司的客户数量下滑，资源质量不好，客户的服务粘性减弱。'
                },
                {
                    tit: '企业尝试网络推广，效果不佳',
                    text: '传统财税服务企业也开始逐渐转型，尝试着使用网络营销推广，成本在不断的浪费，投入不少却没有达到很好的收益。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/cs/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/cs/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/cs/sec2.4.png'),
            lis: [
                {
                    tit: '客户标签',
                    text: '展示意向客户、签约客户、沉默客户等客户信息，以及客户跟进的进程，通过今日已联系、今日待联系、重点客户等维度维护。'
                },
                {
                    tit: '一键外呼',
                    text: '无需手动拨号，系统一键拨打呼叫，免去人工拨号低效率的状况，进入系统后台在客户信息页面，配合CRM系统提高效率。'
                },
                {
                    tit: '通话记录',
                    text: '通过对销售人员的通话记录录音，通话效率分析进行工作考核，有效提高效率和积极性。'
                },
                {
                    tit: '知识库',
                    text: '优秀案例可通过知识库学习，集中培训，学习销售经验。看话术模板，迅速掌握销售话术。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/cs/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/cs/1.png'),
                    tit: '客户管理',
                    text: '在与代账客户建立初步联系后，会计专员通过代账客户管理模块，登记客户的基本信息和单位联系方式，注册地址续费提醒等。在需要查找客户信息的时候可以快速的找到客户并对他进行编辑更改。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cs/2.png'),
                    tit: '销售管理',
                    text: '设置销售任务，为每个销售人员设立销售指标额，并把每日销售额沉淀下来，全公司可见，激发员工的工作积极性，不断提高工作效率，以期达成销售目标，进而提升公司业绩。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cs/3.png'),
                    tit: '营销获客',
                    text: '百川引流与市场推广中多种工具完美应用各个营销场景，精准营销、慧获客、DM投放等功能实现客户引流。微信营销基于十亿微信流量有效拓客，企蜂名片针对不同部门进行品牌传播。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cs/4.png'),
                    tit: '信息自动留存',
                    text: '客户数据、沟通信息等自动留存至CRM系统，所有投放数据线索自动沉淀至CRM，并反哺投放的持续优化。各渠道沟通信息自动保存，留存客户各种记录。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cs/5.png'),
                    tit: '多渠道沟通',
                    text: '支持与客户通过电话、微信聊天、电子邮件、社交媒体等交流。聊天记录和语音同步储存，方便不同部门的人员查看，以便提供更好的客户体验。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cs/6.png'),
                    tit: '全方位客户画像',
                    text: '不仅提供客户资料、客户联系人、客户商机、客户活动的新增与查询、客户的交易概览、客户交易明细情况的统计与查询，还能帮助企业实现统一的、全面的360度全方位视图，从而加强与客户进行深入的沟通联系。'
                }
            ]
        }
    },
    {
        color:'#1f6be4',
        banner: {
            img: require('./../../assets/img/caseImg/cm/top.png'),
            tit: '传媒行业',
            lis: ['以业务场景为核心，', '构建行业差异化解决方案。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/cm/sec1.png'),

            lis: [
                {
                    tit: '销售管理不透明',
                    text: '传媒行业包含广告、公关、媒体、咨询、策划等众多上下游企业，产品形态繁多衍生出了庞大的产业生态系统。行业内的销售团队虽然获取销售线索难度低，但高质量可转化资源一般依赖少数有经验的销售精英人脉及资源，企业很难有效洞察，私单现象屡禁不止。'
                },
                {
                    tit: '社交数据无法与协同部门打通共享',
                    text: '传媒行业销售团队的销控一般依赖企业内部的资源管理系统，但协同通讯数据大都留存在与外部客户交互的社交工具中，社交数据无法与内部协同部门打通共享。'
                },
                {
                    tit: '售前、售后运营销售过程自动化程度较低',
                    text: '传媒行业的素材上下刊、媒体排期、广告点位盘点、客户预约档期等售前、售后运营业务琐碎繁杂，稍有不慎就会将客户张冠李戴发生误投、错投、少投等事故导致客户满意度下降。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/cm/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/cm/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/cm/sec2.4.png'),
            lis: [
                {
                    tit: '专属客户档案',
                    text: '依据客户分级、分类情况制定跟进策略、跟进阶段，再设置下次沟通时间和重点内容的提醒，来规划市场活动和推动营销。'
                },
                {
                    tit: '微信营销',
                    text: '销售专属T5智能屏，可实现微信营销，线上发送文件给客户，实时交流，并配有业内独有的微信电话通话录音，聊天记录同步，降低通信风险。'
                },
                {
                    tit: '企蜂名片',
                    text: '电子名片实时关注到客户的浏览轨迹，从而做出销售调整；同时，可在企蜂名片端口发起聊天交流。'
                },
                {
                    tit: '透明绩效考核',
                    text: '管理人员实时全面了解每个销售人员每天工作量数据、各类客户的联系结果、签约金额报表。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/cm/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/cm/1.png'),
                    tit: '线索管理',
                    text: '对销售流程设置关键节点，使各个节点有清晰的要求、阶段反馈资料等数据。在重要节点设置需要商机确认，让销售线索作为公司公有资源统一管理分配，实时录入报备审核，提高了客户关联的项目信息准确性。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cm/2.png'),
                    tit: '统一管理客户信息',
                    text: '帮助团队记录、积累沉淀，形成客户资源库，完善的信息共享；建立客户资源的更优化分配机制，有效降低高质量资源依赖少数有经验的销售精英人脉和资源，让企业有效监管把控客户资源不流失。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cm/3.png'),
                    tit: '建立客户360°数据库',
                    text: '客户可按地域、意向、成交、产品以及重要程度进行分类管理，随时可查看客户。建立从筛选、意向、拜访、签单、收款、交付产品等沟通信息全过程数据库，有助于企业提升销售能力和产品质量。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cm/4.png'),
                    tit: '协作办公',
                    text: '快速、敏捷地让企业的业务通讯化，将契合适配的业务场景适时展现在多业务部门进入、支撑体系配合销售团队的过程中。在协同办公场景中，核心经营数据的留存、讨论、分析、决策、推进都将快速高效地完成。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cm/5.png'),
                    tit: '销售管理',
                    text: '销售线索统一管理分配，实时录入报备审核制度，防重设置提高客户关联性，杜绝撞单、私单的发生。管理者可随时随地查看销售电话录音和沟通情况，进行工作考核。'
                },
                {
                    icon: require('./../../assets/img/caseImg/cm/6.png'),
                    tit: '服务流程规范化',
                    text: '严格按照企业组织架构对合同订单进行审批，有效规范广告位资源、客户排期、媒介排期等。PC端、移动端全方位提供媒体资源更新状态,空闲、预订、已售信息随时掌握；并可分地域分种类进行管理。'
                }
            ]
        }
    },
    {
        color:'#5e69a9',
        banner: {
            img: require('./../../assets/img/caseImg/dzsw/top.png'),
            tit: '电商行业',
            lis: ['精准定位用户人群，获取潜在客户，', '提供专业的智能化场景解决方案，提升留存率和转化率。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/dzsw/sec1.png'),

            lis: [
                {
                    tit: '业务人员成本高，咨询重复',
                    text: '电商行业日常运营活动促进消费，也带来大量售前、售中、售后问题。客服人员面临大量重复咨询和高负荷工作，服务成本居高不下。'
                },
                {
                    tit: '服务同质化，竞争力和转化率不够',
                    text: '电商行业同质化竞争严重，企业亟待通过对消费者的深入了解，对场景化服务的细致设计，提升个性化服务能力与转化率。'
                },
                {
                    tit: '服务监管效率低，客户满意度不高',
                    text: '多渠道、大并发的特性给电商服务监管效率带来很大的挑战，监管滞后导致的问题发现慢、触达慢、解决慢，直接影响着用户的满意度。'
                },
                {
                    tit: '缺乏有效的客户管理系统',
                    text: '电商企业大多没有系统管理自己的客户，客户资料混乱，资源利用率低，客户流失大。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/dzsw/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/dzsw/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/dzsw/sec2.4.png'),
            lis: [
                {
                    tit: '慧获客',
                    text: '慧获客可直接作为插件安装到浏览器里，在1688网站，天眼查，企查查，企信宝，搜客宝等网站上实现一 键智能获取客户资源。'
                },
                {
                    tit: '屏幕取号',
                    text: '在网页、文档上，只需鼠标选定或划经号码，点一下，电话即直接呼出，通话结束后，一键把客户保存进系统。'
                },
                {
                    tit: '通话录音及统计',
                    text: '坐席电话录音，每日通话数量、通话时长统计，便于制定目标计划，考核日常工作。'
                },
                {
                    tit: '数据分析提高转化',
                    text: '有些客户可能一段时间没有需求，但是过一段时间就有需求了，CRM系统分析客户阶段及需求变化，有利于客户留存。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/dzsw/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/dzsw/1.png'),
                    tit: '全生命周期服务',
                    text: '面向消费者全生命周期，结合企业用户画像，设计场景化服务。包括购物前通过消费者的行为洞察；购物中通过服务的精准触达，提升询单转化率；购物后通过售后的自助服务，提升服务效率。'
                },
                {
                    icon: require('./../../assets/img/caseImg/dzsw/2.png'),
                    tit: '多维度监督',
                    text: '多维度的监控指标，电话录音、聊天记录实时同步：敏感词报警、会话超时等。对海量的业务数据进行高效率分析，全面赋能企业监管效率，提高用户满意度。'
                },
                {
                    icon: require('./../../assets/img/caseImg/dzsw/3.png'),
                    tit: '智能语音客服增效',
                    text: '根据用户实际情况方便快捷设置业务话术，训练系统，效率翻倍。自动判断客户意向后划入资源库，分析拨打数据、通话时长、通话状态，数据统计高效、完整、客观。'
                },
                {
                    icon: require('./../../assets/img/caseImg/dzsw/4.png'),
                    tit: '客户管理',
                    text: '为每个客户建立专属的客户档案，商机、个人偏好、历史成交、跟进记录等信息清晰可见，通过智能客户分级，实现精细化运营。'
                },
                {
                    icon: require('./../../assets/img/caseImg/dzsw/5.png'),
                    tit: '数据分析提高转化',
                    text: '在电商行业中，有很多的客户是有分阶段的购买追求的，可能在一段时间内没有需求，但是过一段时间就会有需求了，CRM系统分析客户的分阶段原因及需求的变化，更有利于用户留存，用户再活化。'
                },
                {
                    icon: require('./../../assets/img/caseImg/dzsw/6.png'),
                    tit: '营销服务自动化',
                    text: '包括客户进入渠道、客户浏览轨迹等完整的线索分析，全渠道响应接待、客服主动邀约、客户信息中心、一键电销沟通等，轻松实现用户线索的流转及营销自动化，帮助企业优化决策执行环节，提高工作效率。'
                }
            ]
        }
    },
    {
        color:'#ec6941',
        banner: {
            img: require('./../../assets/img/caseImg/fdc/top.png'),
            tit: '房地产行业',
            lis: ['整合线上线下资源，发掘客户价值，', '规范营销管理体系提高客户满意度，','房地产企业信息化转型。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/fdc/sec1.png'),

            lis: [
                {
                    tit: '电销模式受限，效率低下',
                    text: '房产公司多，市场竞争激烈，仅通过电销引起客户反感，传统拓客模式不足以支撑企业发展，转化率和成单率都很低。'
                },
                {
                    tit: '销售流动大，资源流失多',
                    text: '房地产行业的销售人员众多，而且流动性十分大，所以企业的客户信息安全就很重要，如果没有措施很容易被销售人员带走，这样会导致企业一直都有客户流失。'
                },
                {
                    tit: '业务流程不规范，沟通不畅',
                    text: '业务销售流程比较乱，可能这个销售人员在跟进的客户，另一个销售人员也在跟进，多个销售人员跟进客户容易造成沟通不当。'
                },
                {
                    tit: '客户管理信息混乱',
                    text: '客户信息管理比较紊乱，没有进行客户的意向分析，不知道客户的偏向爱好等，另外就是房源信息比较乱，没有整体的规划。'
                },

            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/fdc/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/fdc/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/fdc/sec2.4.png'),
            lis: [
                {
                    tit: '销售业绩排行',
                    text: '团队与个人销售数据实时更行，排名激发竞争潜能，对比分析多维筛选，全面洞察销售能力，更好地进行销售考核。'
                },
                {
                    tit: '企蜂名片',
                    text: '客户通过销售分享的名片查看公司产品，销售人员可从浏览轨迹中提前分析出用户需求，针对性沟通，提高成交率。'
                },
                {
                    tit: '微信营销',
                    text: '分担电销压力，专属销售微信智能屏，微信聊天记录同步储存，微信电话及语音保留在CRM库内。'
                },
                {
                    tit: '客户标签',
                    text: '跟进人员进行开发维护时，通过给客户打标签，分类，销售设置权限等功能也能帮助客户实现。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/fdc/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/fdc/1.png'),
                    tit: '多渠道接入',
                    text: '聚合智能名片、官网注册、市场推广、微信、销售自拓等多渠道线索，实现对线索的统一管理，助力企业不断优化拓客渠道，提升效率和业绩目标。'
                },
                {
                    icon: require('./../../assets/img/caseImg/fdc/2.png'),
                    tit: '自定义线索流转',
                    text: '通过对线索的跟踪，包括短信联系、来电时间和通话记录的整合，实现高效的客户线索统一管理和分配。自定义线索分配、去重规则，提升线索使用率、降低撞单概率。'
                },
                {
                    icon: require('./../../assets/img/caseImg/fdc/3.png'),
                    tit: '数据整合分析',
                    text: '帮助房地产公司建立规范统一的客户数据中心，把所有的客户信息都导入到CRM中进行整合筛选，为统一高效的客户价值分析和挖掘打下基础。'
                },
                {
                    icon: require('./../../assets/img/caseImg/fdc/4.png'),
                    tit: '客户全生命周期管理',
                    text: '利用大数据分析系统对客户进行评估、建立用户画像、策划精准营销方案，提升客户的满意度，实现买房、物业、社会生活一体化，达成全客户生命周期价值的获取。'
                },
                {
                    icon: require('./../../assets/img/caseImg/fdc/5.png'),
                    tit: '销售过程管理',
                    text: '对房产销售人员工作状态和工作内容做完整的记录和实时监控，随时随地了解工作内容和工作进度，做到对销售工作的全程管理与监督。 销售目标全程数字化跟踪。'
                },
                {
                    icon: require('./../../assets/img/caseImg/fdc/6.png'),
                    tit: '标准化服务流程',
                    text: '规范房地产公司的房源管理、销售管理、客户管理、财务管理等流程，打造一体化、标准化的服务流程，提高房地产公司的核心竞争力。'
                }
            ]
        }
    },
    {
        color:'#1f6be4',
        banner: {
            img: require('./../../assets/img/caseImg/hlw/top.png'),
            tit: '互联网行业',
            lis: ['助力高科技企业盈利能力迅速提升，', '重塑企业与客户的连接，实现业绩增长。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/hlw/sec1.png'),

            lis: [
                {
                    tit: '获取资源成本高，缺乏有效营销',
                    text: '互联网公司将更多的注意力放在了”线上市场推广”结合“线下销售覆盖”的市场抢占策略上，传统管理系统缺失潜在客户与品牌沟通的场景。'
                },
                {
                    tit: '销售管理',
                    text: '管理层无法掌握地推团队每天的工作量、效果。绝大多数企业以粗放型管理为主，管理层只能被动的接受业绩结果而无法对外勤过程很好的管控。'
                },
                {
                    tit: '客户资源体量大，难管理',
                    text: '每天获取的大量销售线索，但是无法快速整理，有效利用筛选出更高品质更容易成单的客户。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/hlw/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/hlw/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/hlw/sec2.4.png'),
            lis: [
                {
                    tit: '线索智能筛选',
                    text: '实现线索智能筛选，系统直接过滤重复的、低质的线索。销售能通过电话、微信、QQ、邮件、短信进一步确认客户是否有培养价值。'
                },
                {
                    tit: '微信营销',
                    text: '销售专属T5智能屏，可实现微信营销，线上发送文件给客户，实时交流，并配有业内独有的微信电话通话录音，聊天记录同步，降低通信风险。'
                },
                {
                    tit: '通话记录录音',
                    text: '通过对销售人员的通话记录录音，通话效率分析进行工作考核，有效提高效率和积极性。'
                },
                {
                    tit: '知识库',
                    text: '优秀案例可通过知识库学习，集中培训、学习销售经验。看话术模板，迅速掌握销售话术。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/hlw/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/hlw/1.png'),
                    tit: '营销推广',
                    text: '整合线上、线下多种营销渠道，多触点进行营销推广，自动获取销售线索。连接微信生态，打通服务号、小程序进行社交裂变传播，构建企业私域流量池。'
                },
                {
                    icon: require('./../../assets/img/caseImg/hlw/2.png'),
                    tit: '客户管理',
                    text: '为每个客户建立专属的客户档案，商机、个人偏好、历史成交、跟进记录等信息清晰可见，通过智能客户分级，实现精细化运营。'
                },
                {
                    icon: require('./../../assets/img/caseImg/hlw/3.png'),
                    tit: '目标与绩效管理',
                    text: '定制目标考核的维度、指标，首页预置目标达成进度，可实时调整，确保目标高效达成。管理人员实时全面了解每个销售人员每天工作量数据、各类客户的联系结果、签约金额报表，真正实现管理透明化。'
                },
                {
                    icon: require('./../../assets/img/caseImg/hlw/4.png'),
                    tit: '商机管理',
                    text: '查看商机来源，和客户的联系纪要，信息透明化，掌握每个商机进度，实时更新商机进度，自定设置多个商机流程及赢单率，帮助观察公司整体销售情况。'
                },
                {
                    icon: require('./../../assets/img/caseImg/hlw/5.png'),
                    tit: '销售管理',
                    text: '设置销售任务，为每个销售人员设立销售指标额，并把每日销售额沉淀下来，激发员工的工作积极性，不断提高工作效率，以期达成销售目标，进而提升公司业绩。'
                },
                {
                    icon: require('./../../assets/img/caseImg/hlw/6.png'),
                    tit: '数据统计',
                    text: '通过系统提供的统计分析报表，为坐席代表提供业绩考核依据，工作量及业务水平的量化评定有利于企业挖掘业务骨干，对员工进行合理的绩效考核，同时，可为企业领导提供产品、业务调整等依据。'
                }
            ]
        }
    },
    {
        color:'#ec6941',
        banner: {
            img: require('./../../assets/img/caseImg/jy/top.png'),
            tit: '教育行业',
            lis: ['针对教育行业获客、咨询、售后服务、', '数据分析等场景，提供一站式解决方案。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/jy/sec1.png'),

            lis: [
                {
                    tit: '营销效果难评估，线索难转化',
                    text: '传统的财税服务主要是手工模式，大量的中小涉税组织每个专业基层人员多依靠纸笔、案头查账或借助查账软件、拷贝资料等手工为主的操作模式，数据分散，统计不方便。'
                },
                {
                    tit: '销售数据分散，客户质量参差不齐',
                    text: '企业竞争也越来越激烈，获客成本也是居高不下，新客户引入不进来，老客户逐步流失。公司的客户数量下滑，资源质量不好，客户的服务粘性减弱。'
                },
                {
                    tit: '自建系统成本高，管理混乱',
                    text: '传统财税服务企业也开始逐渐转型，尝试着使用网络营销推广，成本在不断的浪费，投入不少却没有达到很好的收益。'
                },
                {
                    tit: '数据不互通，线下业务缺乏管控',
                    text: '分散割裂的线下门店导致业务数据与线上数据无法互通，导致信息孤岛的产生。线下业务人员飞单情况严重，缺乏有效管控。'
                }  
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/jy/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/jy/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/jy/sec2.4.png'),
            lis: [
                {
                    tit: '线索智能过滤',
                    text: '实现线索智能筛选，系统直接过滤重复的、低质的线索。销售能通过比话、微信、QQ、邮件、短信进一步确认客户是否有培养价值。'
                },
                {
                    tit: '微信营销',
                    text: '分担电销压力，专属销售微信智能屏，微信聊天记录同步储存，微信电话及语音保留在CRM库内。'
                },
                {
                    tit: '一键外呼',
                    text: '智能外显归属地号码，拥有全国号码池资源，提升外呼接通率；智能语音系统外呼，覆盖课程邀约、回访、信息收集等场景，提效降本。'
                },
                {
                    tit: '设置客户标签',
                    text: '能清楚展示各阶段客户信息，对注册学员进行回访，记录有意向的学员，以完成后续课程推介和购买。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/jy/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/jy/1.png'),
                    tit: '线索追踪转化',
                    text: '线索索取实现全生命周期的转化闭环，当资源入库后，根据自定义规则自动进行线索的派发、建立外呼营销任务，线索跟进记录自动写入CRM系统，缩短转化链路，提升转化效率。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jy/2.png'),
                    tit: '数据报表分析',
                    text: '全方位数据报表与监控，完善数据分析报表，多维度质检分析，帮助清晰掌握客服动态和客户业务数据，提高决策效率。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jy/3.png'),
                    tit: '全渠道营销推广',
                    text: '整合线上、线下多种营销渠道，多触点进行营销推广，自动获取销售线索。连接微信生态，打通服务号、小程序进行社交裂变传播，构建企业私域流量池。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jy/4.png'),
                    tit: '精细化客户运营',
                    text: '为教育培训行业梳理出更合理高效的工作流程和管理标准，从招生到学员服务支持，形成了统一的管理体系和教学质量监控系统。轻松挖掘跟进潜在学员，实时把控学员状态，因材施教。合理安排课程，提高学员满意度。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jy/5.png'),
                    tit: '销售管理',
                    text: '教育机构一般课程类型复杂，分部众多。CRM通过报表和统计报表功能制定严格的数据共享规则，实现总部和分部之间数据沟通无障碍。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jy/6.png'),
                    tit: '智能系统提效',
                    text: '话务语音、文本智能质检，提升坐席能力； 挖掘海量数据价值，灵活客户画像，数据透视分析，帮助管理者决策；智能对话系统提高服务效率，降低成本，人机辅助效果更好。'
                }
            ]
        }
    },
    {
        color:'#906461',
        banner: {
            img: require('./../../assets/img/caseImg/jrfw/top.png'),
            tit: '金融服务行业',
            lis: ['以业务场景为核心，业务智能化、', '流程自动化、管理可视化，构建行业差异化解决方案。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/jrfw/sec1.png'),

            lis: [
                {
                    tit: '传统业务流程难以满足用户',
                    text: '金融行业在当前日益增长的消费环境中，其传统业务在满足客户需求方面捉襟见肘，很难实现一站式的服务整合。由于金融行业整体营销效率的下降，使得新一代金融消费者在新业务需求和产品体验上很难得到满足。'
                },
                {
                    tit: '人力运营成本高，系统跟不上影响客户',
                    text: '金融行业的系统和人力运营成本过高，企业利润逐年降低。很多机构采购的核心功能系统老旧、功能单一。跨部门之间信息碎片化，导致信息交互有空窗期，影响客户体验。'
                },
                {
                    tit: '海量客户分类筛选难，协同差',
                    text: '积累了大量的个人历史交易数据和客户资料数据。拥有线上和线下多门类渠道，其内部每天都会产生客户实时数据，但是由于部门之间的相互独立，无法有效整合线上线下客户信息。'
                },
                {
                    tit: '销售管理力不从心',
                    text: '各层级管理不到位，没有管理工具或工具不完善，导致目标和计划不清晰。领导针对各类数据统计不及时，决策缺乏大数据支撑，影响管理效率。'
                } 
            ]
        },
        // sec2: {
        //     img1: require('./../../assets/img/caseImg/jrfw/sec2.1.png'),
        //     img2: require('./../../assets/img/caseImg/jrfw/sec2.2.png'),
        //     img3: require('./../../assets/img/caseImg/jrfw/sec2.4.png'),
        //     lis: [
        //         {
        //             tit: '慧获客',
        //             text: '慧获客可直接作为插件安装到浏览器里，在1688网站，天眼查，企查查，企信宝，搜客宝等网站上实现一 键智能获取客户资源。'
        //         },
        //         {
        //             tit: '通话录音及统计',
        //             text: '坐席电话录音，每日通话数量、通话时长统计，便于制定目标计划，考核日常工作。'
        //         },
        //         {
        //             tit: '一键外呼',
        //             text: '无需手动拨号，系统一键拨打呼叫，免去人工拨号低效率的状况，进入系统后台在客户信息页面，配合CRM系统提高效率。'
        //         },
        //         {
        //             tit: '客户标签',
        //             text: '展示意向客户、签约客户、沉默客户等客户信息，以及客户跟进的进程，通过今日已联系、今日待联系、重点客户等维度维护。'
        //         }
        //     ]
        // },
        sec3: {
            img: require('./../../assets/img/caseImg/jrfw/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/jrfw/1.png'),
                    tit: '线索智能筛选',
                    text: '多维度的数据来源，在结合自身业务的情况下，通过CRM+电销系统，对线索深度过滤，优先触达高潜维度的线索，精准匹配目标客户，制定不同营销策略。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jrfw/2.png'),
                    tit: '多渠道协同工作',
                    text: '在涵盖从语音、短信，到在线客服、微信等多社交媒体渠道接入整合，以及IVR语音导航、自动外呼、全程电话录音等通信平台功能。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jrfw/3.png'),
                    tit: '数据统计分析',
                    text: '全面监督整个销售过程，每天时长考核及录音分析；快速准确响应客户的问题，提升客户满意度和忠诚度；呈现销售、市场、客户、服务等数据。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jrfw/4.png'),
                    tit: '客户全生命周期管理',
                    text: '从客户开发，到客户的分流，到客户的跟进，到签约成功整个流程全生命周期管理。销售可通过查看联系小记、通话及聊天记录，高效管理客户，为企业提供更全面的客户信息和数据。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jrfw/5.png'),
                    tit: '资源利用率提升',
                    text: '客户号码中间四位加密处理，保障安全性，销售离职也带不走。销售查看意向客户、签约客户等客户信息，以及客户跟进的进程争取签单。管理层能通过CRM系统客户资源分配实现优化。'
                },
                {
                    icon: require('./../../assets/img/caseImg/jrfw/6.png'),
                    tit: '营销服务自动化',
                    text: '包括用户进入渠道、用户浏览轨迹等完整的线索分析，全渠道响应接待、客服主动邀约、客户信息中心、一键电销沟通等，轻松实现用户线索的流转及营销自动化，帮助企业优化决策执行环节，提高工作效率。'
                }
            ]
        }
    },
    {
        color:'#916b48',
        banner: {
            img: require('./../../assets/img/caseImg/qc/top.png'),
            tit: '汽车行业',
            lis: ['汽车销售、租赁、服务流程自动化管理，', '与客户深度连接。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/qc/sec1.png'),

            lis: [
                {
                    tit: '线索获取难',
                    text: '汽车销售服务市场下沉明显、精准连接客户成为难题、线上活动无法精准投放，线下活动管控困难，营销数据无法做到实时分析。'
                },
                {
                    tit: '管理者管控有难度',
                    text: '面对庞大数据，是否充分挖掘客户需求、对业务进行科学预测、业务数据是否可及时查询、店面业绩进程是否可及时管控，数据背后意义和策略事物是否可及时被发现。'
                },
                {
                    tit: '售后服务不透明，客户管理混乱',
                    text: '汽车品牌及价格信息充分透明化，利润空间被一再压缩，客户触达不及时、无规律、不人性化，售后服务不透明等问题，都持续拉低客户满意度。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/qc/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/qc/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/qc/sec2.4.png'),
            lis: [
                {
                    tit: '微信营销',
                    text: '将微信与CRM打通，帮企业找到客户、连接客户、转化客户、维护客户。客户接收有效信息、只做有价值互动、监督服务过程。'
                },
                {
                    tit: '智能外显归属地号码',
                    text: '智能外呼语音通知、短信通知提升信息到达率；智能语音系统外呼，回访、信息收集等场景，提效降本。'
                },
                {
                    tit: '知识库',
                    text: '对每一辆车的卖点、优势、适用人群建立产品知识文档，定时开展内部员工培训。'
                },
                {
                    tit: '设置客户标签',
                    text: '智能描绘客户画像，客户购买车型/汽车质保时间/车辆保险等信息标签化确保推送信息，提升客户满意度。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/qc/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/qc/1.png'),
                    tit: '线索筛选分配',
                    text: '帮助团队从各种渠道收集销售线索并协助分配，以及管理从有效销售线索到试用体验再到销售的整个过程。'
                },
                {
                    icon: require('./../../assets/img/caseImg/qc/2.png'),
                    tit: '智能化客服服务',
                    text: '在涵盖从语音、短信，到在线客服、微信等多媒体渠道接入整合，以及IVR语音导航、自动外呼、全程电话录音等通信平台功能。实现服务咨询 、业务受理 、工单流转等。'
                },
                {
                    icon: require('./../../assets/img/caseImg/qc/3.png'),
                    tit: '流程自动化',
                    text: '销售跟进流程标准化，无纸化办公效率翻倍  电话/小程序/呼叫中心/微信，均可连客户到店试驾、签约等实现无纸化，快速满足客户需求  丰富审批场景、转移、多人、加签审批均可实现。'
                },
                {
                    icon: require('./../../assets/img/caseImg/qc/4.png'),
                    tit: '数据分析',
                    text: '可以看到某个潜客来源渠道所关注的车型情况，针对不同渠道制定不同的销售策略，推荐不同的车型。同时，对于热度比较高的车型，可以重点宣传，以获取更多的潜客；对于热度较低的车型，要结合库存情况开展促销活动。'
                },
                {
                    icon: require('./../../assets/img/caseImg/qc/5.png'),
                    tit: '销售管理',
                    text: '实时查看业绩、工单、回款等数据，便于管理者进行目标管理。通过智能数据分析，让策略不再“盲投”，帮助管理者实时监控业务进度科学制定、调整企业策略。'
                },
                {
                    icon: require('./../../assets/img/caseImg/qc/6.png'),
                    tit: '售后服务',
                    text: '针对车辆预检、维修进程、疑问解答、完工轿车实施播报，在线服务回访、电话补充服务信息全留存。销售回访、回访导出。'
                }
            ]
        }
    },
    {
        color:'#404f5c',
        banner: {
            img: require('./../../assets/img/caseImg/zz/top.png'),
            tit: '制造行业',
            lis: ['建立以客户为中心，以业务流程为主线，', '以数据分析为支撑的营销、业务管理体系。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/zz/sec1.png'),

            lis: [
                {
                    tit: '客户转化率低，流失高',
                    text: '产品同质化严重，市场竞争激烈。订单转化率低，客户个性化需求提升，老客户流失率高。'
                },
                {
                    tit: '获客成本不断上升',
                    text: '获客成本高，回款周期长，现金流压力大 ，市场细分越来越严重。相对销售周期长，一般涉及售后安装、调制、验收等过程。'
                },
                {
                    tit: '销售管理难度大',
                    text: '产品种类繁多或是产品参数较为专业或复杂，无有效的知识库管理体系支撑销售与服务，业务管理人员出现自然更迭，销售考核标准需要重新梳理。'
                },
                {
                    tit: '企业内部协同效率低',
                    text: '内部协同效率低下、部门墙严重；企业面临产品、价格、服务、资本实力的全方位竞争。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/zz/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/zz/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/zz/sec2.4.png'),
            lis: [
                {
                    tit: '客户跟进',
                    text: '展示意向客户、签约客户、沉默客户、流失客户的客户信息，以及客户跟进的进程，通过今日已联系、今日待联系、重点客户等维度维护。'
                },
                {
                    tit: '微信营销',
                    text: '销售专属T5智能屏，可实现微信营销，线上发送文件给客户，实时交流，并配有业内独有的微信电话通话录音，聊天记录同步，降低通信风险。'
                },
                {
                    tit: '企蜂名片',
                    text: '客户通过销售分享的名片查看公司产品，销售人员可从浏览轨迹中提前分析出用户需求，针对性沟通，提高成交率。'
                },
                {
                    tit: '知识库',
                    text: '优秀案例可通过知识库学习，集中培训，有效提升对于多参数产品及产品使用特点支撑销售及服务。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/zz/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/zz/1.png'),
                    tit: '营销自动化',
                    text: '从各渠道收集新的线索，将其分配给销售人员并设置跟进任务，提醒到销售人员。随时设置提醒以跟进新的潜在客户，管理新的销售机会，运行销售预测。对接手机端，创建营销活动，动态获取信息。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zz/2.png'),
                    tit: '客户管理',
                    text: '跟踪客户的购买历史，分析未来二次销售和交叉销售的机会；通过客户和联系人寻找潜在销售机会从而推动新产品或服务的销售，记录与客户沟通的详细过程。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zz/3.png'),
                    tit: '多维度数据统计',
                    text: '简单直观的图像化项目简报，让企业对客户的来源渠道、跟踪管理、销售数据、业绩排行了如指掌。提升运行效率，分析企业经营中遇到的问题，为决策者提供决策依据。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zz/4.png'),
                    tit: '信息自动留存',
                    text: '客户数据、沟通信息等自动留存至CRM系统 所有投放所得数据线索自动沉淀至CRM，并反哺投放的持续优化  各渠道沟通信息自动保存，留存客户各种记录。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zz/5.png'),
                    tit: '销售精细化管理',
                    text: '设置销售任务，为每个销售人员设立销售指标额，并把每日销售额沉淀下来，全公司可见，激发员工的工作积极性，不断提高工作效率，以期达成销售目标，进而提升公司业绩。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zz/6.png'),
                    tit: '售后服务管理',
                    text: '通过服务申请、服务计划、服务工单、服务回访、服务投诉、服务关怀的全面管理，帮助企业实现高效、低成本、高质量的服务同时，提升客户满意度和企业盈利能力。 '
                }
            ]
        }
    },
    {
        color:'#716d6f',
        banner: {
            img: require('./../../assets/img/caseImg/zx/top.png'),
            tit: '装修行业',
            lis: ['获客、渠道、服务、管理，防客户流失，', '规范使用业务流程，客户服务提升满意度。']
        },
        sec1: {
            img: require('./../../assets/img/caseImg/zx/sec1.png'),

            lis: [
                {
                    tit: '线索获取难成本高',
                    text: '业务人员成本高，出单率低。有偿获得客户源，利润低，更有可能合作不成打水漂。甚至将自己的公司变成了其他平台的项目经理，得不偿失。'
                },
                {
                    tit: '资源利用率低，客户体验差',
                    text: '缺乏科学、合理的销售资源分配与客户归属判定机制，客户资源使用混乱。部门之间配合不到位，项目沟通困难，客户投诉率高。'
                },
                {
                    tit: '项目管理不到位',
                    text: '工地分散、情况复杂、难以掌控；项目经理和施工人员游离在监控范围之外，工期无法保障，工程质量多有偏差。'
                },
                {
                    tit: '私单跑单严重',
                    text: '客户动态无法及时了解，客户跟进不及时，服务质量上不去，公司人员流动频繁，私单和跑单问题严重。'
                } 
            ]
        },
        sec2: {
            img1: require('./../../assets/img/caseImg/zx/sec2.1.png'),
            img2: require('./../../assets/img/caseImg/zx/sec2.2.png'),
            img3: require('./../../assets/img/caseImg/zx/sec2.4.png'),
            lis: [
                {
                    tit: '客户跟进',
                    text: '展示意向客户、签约客户、沉默客户、流失客户的客户信息，以及客户跟进的进程，通过今日已联系、今日待联系、重点客户等维度维护。'
                },
                {
                    tit: '签到功能',
                    text: '员工频繁外出拜访，却少见成效。实时定位销售位置，便于员工管理，沟通内容随时看。'
                },
                {
                    tit: '通话记录录音',
                    text: '通过对销售人员的通话记录录音，通话效率分析进行工作考核，有效提高效率和积极性。'
                },
                {
                    tit: '全渠道营销',
                    text: '多渠道引流，通过表单营销等对外展示公司资质、施工工艺、建材产品、促销活动等。'
                }
            ]
        },
        sec3: {
            img: require('./../../assets/img/caseImg/zx/7.png'),
            lis: [
                {
                    icon:  require('./../../assets/img/caseImg/zx/1.png'),
                    tit: '统一管理客户信息',
                    text: '帮助团队记录、积累沉淀，形成客户资源库；完善的信息共享，让团队达到快速的协同；建立客户资源的更优化分配机制，让客户资源达到更大利用率。有效帮助企业避免员工离职造成的客户流失等问题。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zx/2.png'),
                    tit: '协同办公',
                    text: '装修装饰行业公司文件、公告通知、任务目标上传下达，保证消息的及时、准确传递与反馈。待办事务提醒、工作日志、请假、出差、用款、费用报销及自定义表单等业务流程审批、设备资产、办公用品、车辆管理、公文流转等。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zx/3.png'),
                    tit: '构建清晰的统计报表',
                    text: '简单直观的图像化项目简报，让企业对客户的来源渠道、跟踪管理、销售数据、业绩排行了如指掌。提升运行效率，分析企业经营中遇到的问题，为决策者提供决策依据。 '
                },
                {
                    icon: require('./../../assets/img/caseImg/zx/4.png'),
                    tit: '信息自动留存',
                    text: '客户数据、沟通信息等自动留存至CRM系统 所有投放所得数据线索自动沉淀至CRM，并反哺投放的持续优化  各渠道沟通信息自动保存，留存客户各种记录。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zx/5.png'),
                    tit: '销售管理',
                    text: '实时查看业绩、工单、回款等数据，便于管理者进行目标管理。通过智能数据分析，让策略不再“盲投”，帮助管理者实时监控业务进度科学制定、调整企业策略。'
                },
                {
                    icon: require('./../../assets/img/caseImg/zx/6.png'),
                    tit: '售后服务管理',
                    text: '工程竣工后，进入售后服务阶段，定期回访、及时掌握保修期限、保修内容、客户满意度。 项目施工、人力用工、材料出入库、财务支出收入、售后服务等全部存档，需要的时候一键调用。'
                }
            ]
        }
    },
] 