<!--  -->
<template>
<div>
  <div class="banner"></div>
  <div class='wrap news-detail'>
    <div class="addr">
      <router-link to='/'>首页</router-link> > <router-link to="/newsList.do" @click.native="refresh('/newsList.do')">新闻中心</router-link> > 正文
    </div>
    <div class="con">
        <h4>{{detailObj.newsTitle}}</h4>
        <div class="author">
          <em>{{detailObj.publishTime}}&nbsp;&nbsp;&nbsp; {{detailObj.newsAuthor}}</em> 
          <span v-for="(item,index) in detailObj.tagNameList" :key="index" @click="toNewsList(item.tagId)">{{item.tagName}}</span>
        </div>
        <div class="con-text">
          <img :src="detailObj.newsImgUrl" alt="">
          <div class="rich-text-content" v-html="detailObj.content"></div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import Api from '@api/news'
import {mapState,mapActions } from 'vuex'
export default {
  name:'',
  components: {},
  props:{},
  data() {
     return {
        detailObj:{}
     };
  },
 metaInfo () {
    return {
      title:this.detailObj.newsTitle,
      meta: [{                 // set meta
      name: 'keyWords',
      content: this.detailObj.keywords,
    },{                 // set meta
      name: 'description',
      content: this.detailObj.newsDesc,
    }]
    }
  },
  watch: {},
  created() {
    this.getDetail({newsId:this.$route.query.id})
  },
  mounted() {},
  //方法集合
  methods: {
      getDetail(params){
        Api.fetchDetail(params).then((res)=>{
          this.detailObj = res.data.data
        })
      },
      toNewsList(tagId){
        this.$router.push({path:'/news',query:{tagId:tagId}})
      },
      refresh(url){
        if(url == "/newsList.do") {
          window.location.href = window.location.origin + url;
        }
      }
  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  a{color:#333;}
  .banner{
    height:215px;
    background:url('../../assets/img/newsBanner.png') center center no-repeat;
  }
  .news-detail{
    .addr{
      margin:20px 0;
      font-size:12px;
    }
    .con{
        margin-top:60px;
        color:#333;
        h4{
          font-size:36px;
          margin-bottom:20px;
          text-align:center;
        }
        .author{
          display:flex;
          justify-content: center;
          align-items: center;
          font-size:12px;
          *{
            display: block;
            color:#808080;
            font-size:12px;
          }
          font-size:12px;
          span{
            font-size:12px;
            margin-left:20px;
            color:#44b5f2;
            padding:5px 20px;
            border-radius:15px;
            background:#e1f3fd;
            cursor: pointer;
          }
        }
      .con-text{
        margin-top:50px;
        line-height:40px;
        font-size:14px;
      }
    }
  }
</style>