var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dx"},[_c('Banner',{attrs:{"bgImg":_vm.bannerImg,"bgcolor":"#1cacfa"}},[_c('div',{staticClass:"banner-text"},[_c('h4',[_vm._v("电销线路")]),_c('ul',[_c('li',[_vm._v("拥有多条优质线路，规避封号风险，")]),_c('li',[_vm._v("企业呼叫中心、业务系统统一接入运营商中继线，")]),_c('li',[_vm._v("多种通信方式任选择。")])])]),_c('apply-dialog')],1),_c('div',{staticClass:"sec1"},[_c('div',{staticClass:"tit"},[_vm._v("企蜂线路优势")]),_c('ul',_vm._l((_vm.sec1List),function(item,index){return _c('li',{key:index},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('h2',[_vm._v(_vm._s(item.h2))]),_c('p',[_vm._v(_vm._s(item.p))])])}),0)]),_c('button',{staticClass:"applayBtnRed",style:({
      color: '#5b94f8',
      backgroundColor: '#fff',
      border: '2px solid #5b94f8',
    }),on:{"click":function($event){return _vm.$store.commit('CHANGE_MODAL_TRUE')}}},[_vm._v("\n    开通申请\n  ")]),_c('div',{staticClass:"sec2"},[_c('div',[_c('div',{staticClass:"tit"},[_vm._v("高效解决四个方面问题")]),_c('div',{staticClass:"sec2_content"},[_c('ul',_vm._l((_vm.sec2List),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item))])}),0),_c('img',{attrs:{"src":require("../../assets/img/caseImg/dx/center.png"),"alt":""}})])])]),_c('div',{staticClass:"sec3"},[_c('div',{staticClass:"tit"},[_vm._v("智能化呼叫中心")]),_c('ul',_vm._l((_vm.sec3List),function(item,index){return _c('li',{key:index},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('p',[_vm._v(_vm._s(item.text))])])}),0)]),_c('button',{staticClass:"applayBtnRed",style:({
      color: '#5b94f8',
      backgroundColor: '#fff',
      border: '2px solid #5b94f8',
    }),on:{"click":function($event){return _vm.$store.commit('CHANGE_MODAL_TRUE')}}},[_vm._v("\n    开通申请\n  ")]),_c('apply-dialog-btn',{attrs:{"modelVisible":_vm.$store.state.applyModal},on:{"cancle":function($event){return _vm.$store.commit('CHANGE_MODAL_FALSE')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }