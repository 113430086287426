<!-- 外呼 和 一些产品的左右布局-->
<template>
    <div class="out-module" :style="{'flex-direction':flexDrect}">
        <div>
          <h4 v-if="type==true">{{title}}</h4>
          <ul>
            <li v-for="(item,index) in salesSerText" :key="'ss'+index">
              <h5>{{item.title}}</h5>
              <p>{{item.text}}</p>
            </li>
          </ul>
        </div>
        <div>
          <img :src="topicIMg" :alt="imgAlt" :title="imgTitle">
        </div>
    </div>
</template>

<script>
import { salesService } from "../json"
export default {
  name:'',
  components: {},
  props:{
    salesSerText:{
      type:Array,
      default:[]
    },
    topicIMg:String,
    flexDrect:{
      type:String,
      default:'row'
    },
    title:String,
    imgAlt:{
      type:String,
      default:''
    },
    imgTitle:{
      type:String,
      default:''
    },
    type: Boolean
  },
  data() {
     return {
        salesServiceText:[]
     };
  },
  computed: {},
  watch: {},
  created() {
      this.salesServiceText = salesService
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .out-module{
    margin-top:64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4{
      padding:2px 0 0 45px;
      width:238px;
      height:84px;
      background:url("../../../assets/img/out-t-bg.png") 0 0 no-repeat;
      font-size:30px;
      color:#f0f9fe;
      box-sizing: border-box;
    }
    img{
      display:block;
      // margin-top:70px;  
      width:100%;
    }
    ul{
      width:465px;
      margin-right:20px;
      li{
        h5{
          font-size:24px;
          color:#333;
          line-height:40px;
          // margin-top:10px;
          margin: 20px 0 20px;
        }
        P{
          font-size:16px;
          line-height:36px; 
        }
      }
    }
  }
</style>