<!-- 行业案例-电子商务行业 -->
<template>
  <div class="box">
    <CaseDemo :mydata="caseDataList[2]" :topTextColor='topTextColor'></CaseDemo>
  </div>
</template>

<script>
import Vue from "vue";

import CaseDemo from "./../../components/CaseDemo";
import { caseDataList } from "./caseDataList.js";

export default {
  data() {
    return {
      caseDataList,
      // caseDataList:caseDataList,
      topTextColor:'#5e69a9'
    };
  },
  components: {
    CaseDemo,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='sass' scoped>

</style>