<template>
  <div class="">
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> 
      <div class="btnOnlineCall"><apply-dialog></apply-dialog></div>
    </Banner>
    <div class="callProblem content-wrap">
      <h5>企业被这些问题困扰</h5>
      <ul>
        <li v-for="(item,index) in probleList" :key="index">
          <img :src="item.icon" alt="" class="img" />
          <img :src="item.hoverIcon" alt="" class="hoverImg">
          <span>{{item.title}}</span>
          <p>{{item.text}}</p>
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class ='hyxCall content-wrap'>
      <h5>慧营销电销软件</h5>
      <p>公司电销救星，十年行业深耕，10w+客户</p>
      <img src="../../assets/img/onlineCall-img1.png" alt="">
      <ul>
        <li>
              提高接通率
        </li>
        <li>
          管理销售工作更高效
        </li>
        <li>
          提高销售业绩
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class="jiej-problem">
        <h5>慧营销电销软件解决方案</h5>
        <p>多年经验值得信赖</p>
        <div class="content-wrap">
          <ul>
            <li v-for="(item,index) in jiejList" :key="index">
              <span>{{item.title}}</span>
              <p>{{item.text}}</p>
            </li>
          </ul>
        </div>
    </div>
    <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <apply-dialog-btn
        :modelVisible="$store.state.applyModal"
        @cancle="$store.commit('CHANGE_MODAL_FALSE')"
      ></apply-dialog-btn>
  </div>
</template>
<script>
import Banner from '@/modudel/Banner.vue'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
    name: "onlineCall",
    components: {Banner,ApplyDialog},
    props:{},
    data() {
      return {
        bannerImg:require('../../assets/img/onlineCall-banner.jpg'),
        probleList:[{title:'天天为封卡而烦恼',text:'三大运营商对手机外呼频率加强管控，使得电话经常被封号，销售无法工作',icon: require("../../assets/img/prob-icon1.png"),hoverIcon: require("../../assets/img/prob-icon-hover1.png")},
        {title:'经常被挂电话，成交率低',text:'由于无法使用电话卡，导致只能使用各种接通率不高的线路，不稳定，降低成交率',icon: require("../../assets/img/prob-icon2.png"),hoverIcon: require("../../assets/img/prob-icon-hover2.png")},
        {title:'电销成本极高',text:'购买多部工作手机、电话卡，价格被抬高，但最后封号问题还不能解决',icon: require("../../assets/img/prob-icon3.png"),hoverIcon: require("../../assets/img/prob-icon-hover3.png")}],
        jiejList:[
        {title:'企业通信畅达无忧',text:'为企业提供基于固话，手机软硬件一体化解决方案，支持移动、联通、电信三大运营商，支持4G卡双通道，无延迟切换，提供开放的API接口，方便接入第三方软件。'},
        {title:'高清通话接通率高，通话实时录音存储',text:'实现一键拨号，接通率高，信号质量有保障，高品质通话，无杂音，不断线。通话实时录音，通话报表数据实时分析。'},
        {title:'过滤无效电话风险客户',text:'系统批量导入电话号码，自动过滤空号、无应答、关机等无效号码，将拨通的有效号码自动分配给坐席人员。即可节省时间，提高营销效率，又可增加成交机率，扩大市场份额。'},
        {title:'7*12小时技术服务',text:'客服中心，全国统一，7*12小时技术支持，技术咨询免费，统一定价，公开透明。'},
        {title:'配合CRM系统使用客户资源数据统计',text:'兼有CRM管理功能，坐席分配管理，坐席话务报表，客户管理，通话时长统计，录音等一应俱全，系统免费使用。'}]
      }
    },
    created(){},
    mounted(){},
    methods: {}
}
</script>

<style lang="scss" scoped>
  .content-wrap{
    margin:0 auto;
    width:1200px;
  }
  .callProblem{
    h5{
      font-size:30px;
      color:#333;
      line-height:135px;
      text-align: center;
    }
    ul{
      display: flex;
      li{
        width:33.333%;
        color:#a6a7a7;
        background:#f4f4f4;
        padding:54px 35px;
        height:390px;
        border-top:9px solid #fff;
        border-bottom:19px solid #fff;
        box-sizing: border-box;
        img.img{
          display:block;
          height:124px;
          margin:0 auto;
        }
        img.hoverImg{display:none}
        span{
          display: block;
          font-size:24px;
          text-align: center;
          margin-top:50px;
          margin-bottom:10px;
        }
        p{
          display: none;
        }
      }
      li:hover{
        background:url(../../assets/img/problem-hover-bg.png) center top no-repeat;
        border:none;
        height:390px;
        img.img{
          display:none
        }
        img.hoverImg{
          display:block;
          margin:0 auto;
        }
        span{color:#fff;}
        p{
          display: block;
          font-size:16px;
          color:#fff;
          text-align: center;
        }
      }
    }
  }
  .hyxCall{
     h5{
      font-size:30px;
      color:#333;
      text-align: center;
      margin-top:80px;
      margin-bottom:10px;
    }
    p{
      font-size:20px;
      color:#535354;
      text-align: center;
      margin-bottom:76px;
    }
    img{
      display:block;
      margin:0 auto;
    }
    ul{
      display:flex;
      margin:25px auto 0;
      width:700px;
      justify-content: space-between;
      li:first-child{
        padding-left:10px;
      }
    }
  }
  .jiej-problem{
     background:url(../../assets/img/onlineCall-img2.png) center top no-repeat;
     height:1042px;
     h5{
      font-size:30px;
      color:#fff;
      text-align: center;
      padding-top:80px;
      margin-bottom:10px;
    }
    p{
      font-size:20px;
      color:#fff;
      text-align: center;
      margin-bottom:76px;
    }
    ul{
      display:flex;
      flex-wrap: wrap;
       margin-top:145px;
       color:#666;
      li{
        width:378px;
        margin-right:32px;
        margin-bottom:85px;
        text-align: center;
        box-sizing: border-box;
        padding:0 35px;
        span{
          margin:0 auto;
          display:block;
          width:240px;
          font-size:24px;
          text-align: center;
          height:80px;
        }
        p{
          font-size:18px;
          color:#666
        }
      }
      li:nth-child(4){
        margin-left:200px;
      }
      li:nth-child(3){
        margin-right:0;
      }
    }
  }
</style>
<style lang="scss">
  .btnOnlineCall .apply-btn{
    margin-top:350px;
    color:#fff;
    border-color:#fff; 
    font-size:16px;
    height:50px;
    line-height:50px;
    border-width:1px;
    border-radius:5px;
    margin-left:20px;
  }
</style>
