export const productBanners = {
  t5: require("../../../src/assets/img/t5-banner.png"),
};

//t5
export const t5 = {
  t5Complex:{
    title:" 我们总能想的多一点",
    url:require("../../../src/assets/img/t5-img1.png"),
    text:''
  },
  loopListProd:{
    topic:'',
    list:[{
      title:'便捷安装、高效沟通',
      src:require("../../../src/assets/img/t5-img2.png"),
      li:['◆ 傻瓜式安装，即插即拔，1分钟即可安转完毕，轻松使用','◆ 支持电话、短信、微信、旺旺、QQ等多种聊天方式','◆ 支持随时查看通话记录、文字聊天内容','◆ 自动拨号、智能挂机']
    },
    {
      title:'通信功能强',
      src:require("../../../src/assets/img/t5-img3.png"),
      li:['◆ 支持移动、电信、联通4G全网通','◆ 支持四卡双通道，智能切换无感知','◆ 性能稳定无延时，无杂音，通话效果佳','◆ 客户来电未接听，短信助手来提醒']
    },
    {
      title:'业务系统完美整合',
      src:require("../../../src/assets/img/t5-img4.png"),
      li:['◆ 完美整合公司的营销类、服务类、人事类产品','◆ 基于第三方系统，提供一体化的解决方案，用户体验佳','◆ 支持公共云、私有云、本地云的第三方系统的整合']
    },
    {
      title:'微信功能',
      src:require("../../../src/assets/img/t5-img5.png"),
      li:['◆ 支持个人微信、企业微信，实现企业管理微信好友','◆ 结合业务系统，实现客户数据和微信数据的整合','◆ 企业基于业务系统发起微信通话、在线聊天','◆ 企业实时查看客户的微信通话记录、聊天记录']
    },
  ]
  },
  t5Fun:{
    topic:'主要功能',
    list:['微信通话','微信聊天','通话记录','微信好友管理','未接电话','电话转接','自动录音','多卡切换'],
  }
}