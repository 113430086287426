<!--  -->
<template>
  <div class='offer-what'>
    <h4 class="whyTitle">我们为渠道提供什么</h4>
    <ul class="whyImg">
      <li>
          <h5>
            <img src="@img/whatwegive_parttoppic1.png" alt="" class="img">
            <img src="@img/whatwegive_parttoppic1_hover.png" alt="" class="imgHover"> 培训支持</h5>
          <div>
            ●产品培训：教练式的产品售前、使用培训<br />
            ●业务扶持：提供销售模式、销售话术、解<br />
              决方案、销售实战等业务扶持<br />
            ●专项培训：定期举办产品售前、售后渠道<br />
              培训
          </div>
      </li>
      <li>
          <h5><img src="@img/whatwegive_parttoppic2.png" alt="" class="img" />
          <img src="@img/whatwegive_parttoppic2_hover.png" alt="" class="imgHover"/>
          市场支持</h5>
          <div>
            ●网络推广：针对全国市场提供搜索推广<br />
              网络广告视频推广以及自媒体推广<br />
            ●线下推广：提供会展、圈子、产品发布会等<br />
              线下推广活动
          </div>
      </li>
      <li>
          <h5><img src="@img/whatwegive_parttoppic3.png" alt=""  class="img" />
          <img src="@img/whatwegive_parttoppic3_hover.png" alt=""  class="imgHover" />售后支持</h5>
          <div>
            ●7x12小时顾问式客服一对一服务<br />
            ●提供技术支持、远程协作、问题处理等服务<br />
            ●提供安装、使用、维护标准化作业指导规范
          </div>
      </li>
      <li>
          <h5><img src="@img/whatwegive_parttoppic4.png" alt=""  class="img" />
          <img src="@img/whatwegive_parttoppic4_hover.png" alt="" class="imgHover" />运营支持</h5>
          <div>
            ●协助代理商完成运营销模式、阶段性营销计划销售目标执行等方面的管理支持<br />
            ●提供销售、市场、运营等规范运行支持
          </div>
      </li>
      <li>
          <h5><img src="@img/whatwegive_parttoppic5.png" alt="" class="img">
          <img src="@img/whatwegive_parttoppic5_hover.png" alt="" class="imgHover">物料支持</h5>
          <div>
             ● 包括但不限于产品单页、产品手册、营销<br />
            宝典、会议物料、产品视频、H5页面等
          </div>
      </li>
      <li>
          <h5><img src="@img/whatwegive_parttoppic6.png" alt="" class="img">
          <img src="@img/whatwegive_parttoppic6_hover.png" alt="" class="imgHover">奖励支持</h5>
          <div>
            ●销售返点、促销奖励、代理商PK定期评选优<br />
            秀代理商，给予现金奖励及产品赠送
          </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name:'',
  components: {},
  props:{},
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .whyImg{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    li{
      margin-bottom:20px;
      width:380px;
      height:350px;
      border:1px solid #dbdbdd;
      box-sizing: border-box;
      h5{
        display: flex;
        justify-content: center;
        align-items: center;
        $height:110px;
        line-height:$height;
        background:#e5e6e6;
        font-size:18px;
        font-weight: bold;
        img{
          margin-right:10px;
            position: relative;
            z-index:1;
        }
        img.imgHover{
          display:none;
        }
      }
      div{
        margin:30px auto 0;
        width:280px;
        font-size:14px;
        line-height: 36px;
      }
    }
    li:hover{
        // box-shadow: 0px 3px 20px 1px #44b5f2;
        border:3px solid #44b5f2;
        img.imgHover{
          display:block;
        }
        img.img{
          display:none;
        }
      h5{
        background:#44b5f2;
        color:#fff;
      }
    }
  }
</style>