
export const  prodAdvantage= [
  {
    'title':'快速部署',
    'text':'傻瓜式安装，1分钟即可轻松上手快捷使用'
  },
  {
   'title':'成本更低',
   'text':'无需购置服务器，无需专业运维人员按需添加营销人员',
  },
  {
    'title':'通信稳定',
    'text':'支持4G全网通与SIP固话信号无干扰，清晰稳定'
  },
  {
    'title':'高效工作',
    'text':'日均通话量达到600-800人次自动拨号，智能挂机'
  }
]

export const salesService= [
  {
    'title':'传统营销方式效率低',
    'text':'中小企业传统的上门推广、发传单的销售模式，受到地域性、人力的限制，很难在短期内快速的挖掘意向客户'
  },
  {
   'title':'快速筛选客户线索',
   'text':'慧营销电销系统，为中小企业提供高效的电话营销服务，告别低效的销售方式，用户通过电销系统，可以方便的拨打电话、使用话术，快速的筛选客户线索，挖掘有价值的意向客户，跟进客户并创造高价值客户',
  },
  {
    'title':'高效管理销售工作',
    'text':'管理者也通过电销系统，方便的管理每一个销售的工作，从而不断优化、提升销售的工作'
  }
]

export const afterSaleList = [
  {
    'title':'自动关怀、提升满意度',
    'text':'定期发送关怀短信，电话回访客户，方便客服了解客户的需求'
  },
  {
   'title':'提升服务的效率和口碑',
   'text':'标准化的售后服务流程，降低了企业对员工的培训成本，提升了员工维护客户的质量，统一的客户回访话术，提升了客户对企业服务的满意度和服务口碑',
  },
  {
    'title':'提升管理的质量和成果',
    'text':'实时了解运营客服人员的工作安排和工作内容，通过不断的跟进工作，指导工作进而优化运营服务人员的工作效率，提高运营客服人员的工作质量和成果转化'
  }
]

export const imporProdlist = ['智能弹屏','来电助手','号码筛选','挂机短信','数据报表','通话记录','自动录音','智能分配','自动连拨','一键拨号','屏幕取号','开场白','电话转接',
'信息安全','知识库']