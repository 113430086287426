<template>
	<div class="container page404">
    <img src="../assets/images/404.png">
    <p>您所访问的页面不存在了</p>
    <router-link to="/">返回首页</router-link>
	</div>
</template>
<script>
export default {
	name: "about",
	props: {},
	data() {
		return {
      
		};
	},
	created() {},
	mounted() {
    setTimeout(()=>{
      this.$router.push({ path: '/' });
    },10000)
  },
	methods: {},
};
</script>
<style lang="scss" scoped>
.page404{

  text-align: center;
  img{
    display: block;
    margin:20px auto 79px;
    width:434px;
    width:299px;
  }
  p{
    font-size: 18px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
    line-height: 36px;
    margin-bottom:36px;
  }
  a{
    font-size: 18px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    color: #4246fc;
    letter-spacing: 0.5px;
  }
}
</style>
