<template>
  <div class="indexBannerScroll">
    <div v-if='bannerList.length > 1'> 
      <Carousel >
      <Carousel-item v-for="(item,index) in bannerList" :key="index">
        <div class="indexBanner" :style="{'background-image':'url('+item.bannerImg+')'}">
          <div style="margin:0 auto;width:1200px;">
            <div class="banner-text">
              <h2 :class="{'colo333':index==3,'color11ee':index==2}">{{ item.title }}</h2>
              <p :class="{'colo333':index==3}" v-html="item.text"></p>
            </div>
            <button
              v-if="index == 0 || index == 1"
              class="btnDefault applayBtnHomeBanner mt70"
              @click="$store.commit('CHANGE_MODAL_TRUE')"
            >在线开通</button>
            <button
            v-if="index == 3"
              class="btnDefault applayBtnAiszBanner mt70"
              @click="$store.commit('CHANGE_MODAL_TRUE')"
            >在线开通</button>
            <button
            v-if="index == 2"
              class="btnDefault aiszBnnerBtn mt70"
              @click="$store.commit('CHANGE_MODAL_TRUE')"
            >在线开通</button>
          </div>
        </div>
      </Carousel-item>
    </Carousel>   
  </div>
  
  <div v-else> 
    <div v-for="(item,index) in bannerList" :key="index">
      <div class="indexBanner" :style="{'background-image':'url('+item.bannerImg+')'}">
        <div style="margin:0 auto;width:1200px;">
          <div class="banner-text">
            <h2>{{ item.title }}</h2>
            <p v-html="item.text"></p>
          </div>
          <button
            class="btnDefault applayBtnHomeBanner mt50"
            @click="$store.commit('CHANGE_MODAL_TRUE')"
          >在线开通</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { Carousel,Button,CarouselItem} from 'iview'
export default {
  name: "",
  components: {
    Carousel,
    Button,
    CarouselItem
  },
  props: {
    bannerList:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
    };
  },
  created() {},
  mounted() {},
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.indexBanner{
   width:100%;
   height: 578px;
   background-position:center top;
   background-repeat: no-repeat;
}
.banner-text {
  padding-top: 130px;
  h2 {
    margin-bottom: 42px;
    font-size: 48px;
    color: #fff;
    line-height: 28px;
    font-weight: bold;
  }
  p{
    font-size:20px;
    color: #fff;
    line-height: 40px;
  }
  .colo333{
    color:#333
  }
  .color11ee{
    color: #11eee1;
  }
}

</style>
<style >
  .indexBannerScroll .ivu-carousel-dots li button{
    background:#fff;
  }   
</style>
