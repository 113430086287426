<!--  -->
<template>
  <div class='softDown'>
      <!-- <h4>{{title}}</h4> -->
      <ul>
        <li v-for="(item,index) in listText" :key = "index">
          <div class="flex mod">
            <img v-if="title=='慧营销APP'" src="../../../assets/img/android.png" alt="">
            <img v-else src="../../../assets/img/windows.png" alt="">
            <div>
              <h5>{{item.prodName}}</h5>
              <p>&nbsp;&nbsp;&nbsp;版本号：{{item.fileVersion }} <br />软件大小：{{item.fileSize}}<br />更新日期：{{item.inputTime}}</p>
            </div>
          </div>
          <div v-if="item.button"><a class="down-btn" :href="item.fileUrl">立即下载</a></div>
          <div v-else><img :src="item.fileUrl" alt=""></div>
        </li>
      </ul>
  </div>
</template>
<script>
export default {
  
  name:'',
  components: {},
  props:{
    title:String,
    listText:{
      type:Array
    },
    qrCode:{
      type:Boolean,
      default:false
    }
  },
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .flex{
    display: flex;
      justify-content: space-between;
      align-items: center;
  }
.softDown{
  margin-top:46px;
  h4{
    height:26px;
    line-height:26px;
    padding-left:15px;
    border-left:3px solid #44b5f2;
    margin-left:5px;
  }
  ul{
    width:100%;
    li{
      margin-top:20px;
      @extend .flex;
      border:1px solid #e6e6e6;
      a.down-btn{
        display:block;
        width:160px;
        height:60px;
        line-height: 60px;
        text-align: center;
        color:#fff;
        background:#44b5f2;
        border-radius:8px;
        font-size:16px;
        margin-right:65px;
      }
    }
    .mod{
      h5{
        font-size:18px;
        color:#666;
      }
      p{
        margin-top:10px;
        font-size:12px;
        color:#808080;
        line-height:30px;
      }
      span{
        display:block;
        margin-top:30px;
        font-size:12px;
        color:red
      }
      img{margin-right:22px;}
    }
  }
 
}
</style>