import { render, staticRenderFns } from "./BannerSwiper.vue?vue&type=template&id=16b97719&scoped=true&"
import script from "./BannerSwiper.vue?vue&type=script&lang=js&"
export * from "./BannerSwiper.vue?vue&type=script&lang=js&"
import style0 from "./BannerSwiper.vue?vue&type=style&index=0&id=16b97719&prod&lang=scss&scoped=true&"
import style1 from "./BannerSwiper.vue?vue&type=style&index=1&id=16b97719&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b97719",
  null
  
)

export default component.exports