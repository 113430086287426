<!--关于我们 -->
<template>
<div>
  <!-- <Banner :bgImg="bannerImg" bgcolor="#2679ec">
  </Banner> -->
  <div class="aboutBanner">
    <p>一站式数智化运营服务商</p>
  </div>
  <div class='wrap'>
    <div>
      <h4 class="whyTitle">我们是谁</h4>
      <div  class="who">
          <img src="@img/about-img1.png" alt="">
          <p>浙江企蜂是一家聚焦企业营销服务的一站式数智化运营服务商，为企业提供“获客—跟进—转化—签单—售后”一体化专业解决方案。公司总部坐落在杭州，目前公司员工规模一百
多人。经历了十余年高速发展，用户遍布全国各地。企蜂拥有雄厚的技术研发实力和成熟的产品框架体系，自主研发各类软硬件产品，拥有多项国家发明专利及知识产权成果，获国家高新技术企业认证。经过互联网领域多年深耕，在行业中形成强大核心竞争力。

<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企蜂始终以“大数据分析能力+市场洞察能力”驱动业务增长，形成系统+数据+Al+ 专业运营服务的全链路闭环，基于多场景垂直化应用，PaaS+SaaS 化生态云服务实现业务信息的无
缝互通，消除数据壁垒，赋能企业经营管理，为企业降本增效及爆发性增长提供强有力保障，助力企业数字化转型。</p>

     </div>
      <div class="who-text">
         天行健，君子以自强不息。企蜂与广大中小微企业一样，在以云计算、大数据、“互联网+”的时代背景下开拓和发展企业。这是一个新的机遇，同时也是新的挑战，企业如何转变、转型以适应新的发展成为新的重大问题。 过去或许失之于PC互联网时代，但是现在有机会崛起于新的浪潮，决胜于今天的“互联网+”。今天的企业经营目的不是降低风险，而是需要自由和速度。而恰恰“互联网+”和互联网化的转型，需要自由和速度。企蜂通信的责任和使命就是帮助企业经营更加自由、灵活、敏捷、高效，帮助企业在“互联网+”时代下成功实现组织高效、业绩倍增和超越客户满意度。企蜂人深知自己的路才刚刚开始，相信有您的陪伴，我们并不孤独。感谢一路有您陪伴，让我们一道在这个新时代取得丰硕的成绩。
      </div>
    </div>
    <div class="culture" id="culture">
         <h4 class="whyTitle">我们的文化</h4>
        <img src="@img/about-img3.png" alt="">
        <div class="">
            <p><span>蜜蜂情怀</span>坚强勇敢 喜乐感恩精诚合作 孜孜不倦无私奉献 全力以赴</p>
            <p><span>匠人精神</span>专注品质 精益求精严谨细致 坚持不懈力求完美 实现价值</p>
        </div>
        <img src="@img/about-img5.png" alt="">
    </div>
    <div class="conact" id="conact">
      <h4 class="whyTitle">联系我们</h4>
       <div class="conact-style line">
         <h3>浙江企蜂信息技术有限公司</h3>
         <p>联系人：姚先生     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：18958188698<br />地址：浙江省杭州市西湖区翠苑街道古翠路80号浙江科技产业大厦12楼</p>
       </div>
       <!-- <div class="flex conact-fen">
          <div class="conact-style">
            <h3>杭州分公司</h3>
            <p>联系人：姚先生    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：18958188698<br />地址：浙江省杭州市西湖区古翠路80号浙江科技产业大厦12楼</p>
          </div>
          <div class="conact-style">
            <h3>上海分公司</h3>
            <p>联系人：姚先生    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：18958188698<br />地址：上海市虹口区天宝路578号飘鹰世纪大厦1307</p>
          </div>
          <div class="conact-style">
            <h3>湖南分公司</h3>
            <p>联系人：姚先生    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：18958188698<br />地址：长沙市建湘路32号天心城市广场北栋2806</p>
          </div>
       </div> -->
    </div>
  </div>
</div>  
</template>

<script>
import Banner from '@/modudel/Banner.vue'
export default {
  name:'',
  components: {
    Banner
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/aboutBanner.png'),
        queryId:''
     };
  },
  computed: {},
  watch: {},
  created() {
     this.queryId = this.$route.query.id
  },
  mounted() {
    if(this.queryId){
        document.querySelector('#'+this.queryId).scrollIntoView(true)
    }
    
  },
  //方法集合
  methods: {
      // scrollPos(id){
      //   const returnEle = document.querySelector(id);
      //   if (!!returnEle) {
      //     returnEle.scrollIntoView(true);
      //   }
      // }
  },
}
</script>
<style lang='scss' scoped>
.aboutBanner{
  background:url('../../assets/img/aboutBanner.png') center no-repeat;
  height:567px;
  p{
    font-size:35px;
    padding-top:300px;
    text-align: center;
    color:#fff;
  }
}
  //@import url(); 引入公共css类
  .flex{
    display: flex;
  }
  .who{
    @extend .flex;
    p{
      margin:20px 0 0 70px;
      font-size:14px;
      color:#000;
      line-height:36px;
      text-indent: 20px;
    }
  }
  .who-text{
    margin-top:60px;
    padding:30px;
    width:100%;
    height:368px;
    background:url("../../assets/img/about-img2.png") no-repeat;
    text-indent: 30px;
    color:#fff;
    font-size:14px;
    line-height:48px;
    box-sizing: border-box;
  }
  .culture{
    div{
      width:1200px;
      height:250px;
      background:url('../../assets/img/about-img4.png') no-repeat;
      display:flex;
      justify-content: space-between;
    }
    p{
      padding:40px 30px;
      width:205px;
      color:#333;
      font-size:16px;
      box-sizing: border-box;
      line-height:36px;
      span{
        display:block;
        font-size:24px;
        
      }
    }
  }
  .conact{
    .conact-style{
      font-size:18px;
      line-height:48px;
      padding:34px 0 20px 0;
      h3{
        font-size:30px;
      }
    }
    .line{
      border-bottom:1px solid #dedede;
    }
    .conact-fen{
      flex-wrap: wrap;
        .conact-style{
          width:50%;
        }
    }
  }
</style>
