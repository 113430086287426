<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="qfmpBanner.bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>{{ qfmpBanner.h3 }}</h4>
        <ul>
          <li v-for="(item, index) in qfmpBanner.ul" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="qfmpMd1">
      <h1 class="bigTitle">{{ qfmpMd1.title }}</h1>
      <img :src="qfmpMd1.imgsrc" />
    </div>
    <div class="qfmpMd2">
      <h1 class="bigTitle" v-html="qfmpMd2.title"></h1>
      <ListMoDule
        :listdata="qfmpMd2.list"
        listClassName="qfmpmod2"
      ></ListMoDule>
      <div class="btnArea">
        <img :src="qfmpMd2.bgimg" />
      </div>
    </div>
    <div class="qfmpMd3">
      <h1 class="bigTitle">{{ qfmpMd3.title }}</h1>
      <ul>
        <li v-for="(item, index) in qfmpMd3.list" :key="index">
          <img :src="item.imgsrc" />
          <div class="text">
            <h3>{{ item.h4 }}</h3>
            <div v-for="(it, idx) in item.plist" :key="idx">
              <span>{{ it.span || "" }}</span>
              <p>{{ it.p }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="btnArea"><apply-dialog :buttonColor="'blueline'"></apply-dialog></div>
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ListMoDule from "@/list.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import Swiper from "@views/product/components/robotSwiper.vue";
import { qfmp } from "@public/static/json/htmlContent";
export default {
  data() {
    return {
      qfmpBanner: qfmp.banner,
      qfmpMd1: qfmp.modular1,
      qfmpMd2: qfmp.modular2,
      qfmpMd3: qfmp.modular3,
    };
  },
  components: {
    Banner,
    ApplyDialog,
    Swiper,
    ListMoDule,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    // list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.qfmpMd1 {
  width: 1200px;
  margin: 0 auto;
  img {
    display: block;
    margin: 80px auto 0;
  }
}
.crm4 {
  ul {
    margin: 80px auto 0;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 582px;
      height: 155px;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 15px;
      padding: 20px 0 0 35px;
      h3 {
        font-size: 28px;
        color: #362f59;
      }
      p {
        margin-top: 20px;
        color: #362f59;
      }
    }
  }
}
.qfmpMd3 {
  width: 1200px;
  margin: 100px auto 0;
  h1 {
    color: #333;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
  }
  ul {
    margin-top: 100px;
    li {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      margin-bottom: 100px;
      img {
        margin-top: 50px;
      }
      h3 {
        font-size: 28px;
        margin-bottom: 20px;
        // background: linear-gradient(41deg,#09bbfe 0%, #1684f4 100%);
        // border-radius: 30px 0px 30px 0px;
        color: #333333;
        width: fit-content;
      }
      .text {
        width: 550px;
        span {
          margin-top: 20px;
          padding-left:25px;
          background:url("../../assets/images/qfmp/icon5.png") left center no-repeat;
          color: #1684f4;
          display: block;
        }
        p {
          font-size: 16px;
          color: #808080;
          padding-left:25px;
       
        }
      }
    }
    li:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
}
.crm5 {
  .tp {
    font-size: 18px;
    color: #999;
    text-align: center;
    margin-top: -20px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
</style>