<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="callCenter1.bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>{{ callCenter1.h3 }}</h4>
        <ul>
          <li v-for="(item, index) in callCenter1.ul" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="sec1">
      <h1>{{ callCenter2.title }}</h1>
      <img :src="callCenter2.imgsrc" />
    </div>
    <!-- <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button> -->
    <div class="sec2">
      <div>
        <h1>{{ callCenter3.title }}</h1>
        <p class="tp">{{ callCenter3.text }}</p>
        <ul class="ul1">
          <li class="li1" v-for="(item, index) in callCenter3.ul" :key="index">
            <div>
              <img :src="item.icon" alt="" />
              <h2>{{ item.title }}</h2>
              <p>{{ item.p }}</p>
            </div>
            <ul>
              <li v-for="(item, index) in item.taglist" :key="index">
                {{ item }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-- <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">
      开通申请
    </button> -->
    <div class="sec3">
      <h1>{{ callCenter4.title }}</h1>
      <ul>
        <li v-for="(item, index) in callCenter4.ul" :key="index">
          <img class="text2 h2-active" :src="item.icon" alt="" />
          <div>
            <h2>{{ item.title }}</h2>
            <p v-for="(it, idx) in item.list" :key="idx">
              <span>&bull;</span>{{ it }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>
    <div class="sec4">
      <div>
        <h1>{{ callCenter5.title }}</h1>
        <ul class="ul1">
          <li class="li1" v-for="(item, index) in callCenter5.ul" :key="index">
            <img :src="item.icon" />
            <h3>{{ item.title }}</h3>
            <p>{{ item.p }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>

    <div class="sec5">
      <h1>{{ callCenter6.title }}</h1>
      <img :src="callCenter6.imgsrc" />
    </div>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>

    <div class="sec7">
      <h1>{{ callCenter7.title }}</h1>
      <ul>
        <li v-for="(item, index) in callCenter7.list" :key="index">
          <img :src="item.img" />
          <div>
            <h3>{{ item.h4 }}</h3>
            <p v-html="item.p"></p>
          </div>
        </li>
      </ul>
    </div>
    <div class="sec6">
      <h1>{{ callCmod8.title }}</h1>
      <Swiper></Swiper>
    </div>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import Swiper from "@views/product/components/robotSwiper.vue";
import { callCenter } from "@public/static/json/htmlContent";
export default {
  data() {
    return {
      callCenter1: callCenter.banner,
      callCenter2: callCenter.callCmod2,
      callCenter3: callCenter.callCmod3,
      callCenter4: callCenter.callCmod4,
      callCenter5: callCenter.callCmod5,
      callCenter6: callCenter.callCmod6,
      callCenter7: callCenter.callCmod7,
      callCmod8: callCenter.callCmod8,
    };
  },
  components: {
    Banner,
    ApplyDialog,
    Swiper,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    // list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
h1 {
  font-size: 36px;
  text-align: center;
  margin: 100px 0 50px;
}
.sec1 {
  width: 1200px;
  margin: 0 auto;
}
.sec2 {
  // background: url("./../../assets/img/callCenter/beijing-1.png") no-repeat;
  // background-size: 100% 100%;
  background-position: center;
  padding-top: 50px;
  div {
    width: 1200px;
    margin: 0 auto;
    h1 {
      color: #333;
      margin-bottom: 25px;
      margin-top: 80px;
      font-size: 30px;
    }
    p.tp {
      text-align: center;
      margin-bottom: 50px;
      font-size: 16px;
    }
    ul.ul1 {
      display: flex;
      justify-content: space-between;
      li.li1 {
        background-color: #fff;
        box-shadow: 0px 2px 10px 0px rgba(94, 105, 169, 0.26);
        border-radius: 8px;
        width: 370px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          width: 310px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 100px;
          }
          h2 {
            font-size: 22px;
            font-weight: bold;
            margin: 20px 0;
          }
          p {
            text-align: center;
            line-height: 30px;
            font-size: 18px;
          }
        }
        ul {
          // position: absolute;
          // bottom: 20px;
          margin-top: 20px;
          background-color: #eef2fc;
          padding: 20px 20px 10px;
          border-radius: 10px;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          li {
            font-size: 16px;
            padding-right: 15px;
            margin-bottom: 10px;
            color: #4d7ddd;
          }
        }
      }
    }
  }
}
.sec3 {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    justify-content: space-around;
    li {
      width: 595px;
      border: 1px solid #a9cbfe;
      display: flex;
      margin-bottom: 20px;
      padding: 30px 15px;
      border-radius: 8px;
      width: 49%;
      align-items: center;
      img {
        display: block;
        margin-right: 10px;
      }
      h2 {
        font-size: 16px;
        color: #526ffe;
        margin-bottom: 10px;
      }
      p {
        span {
          color: #265bdd;
          font-size: 28px;
          padding-right: 10px;
        }
        color: #666;
        line-height: 25px;
      }
    }
    li:hover {
      background: #f6f9ff;
      box-shadow: -1px 0px 8px 2px rgba(119, 141, 251, 0.31),
        0px 2px 8px 2px rgba(119, 141, 251, 0.31);
    }
  }
}
.sec4 {
  overflow: hidden;
  width: 100%;
  height: 550px;
  background: url("./../../assets/images/callCenter/tu2.png") no-repeat;
  padding-top: 60px;
  div {
    width: 1200px;
    margin: 0 auto;
    h1 {
      color: #fff;
      margin-top: 20px;
      font-size: 30px;
      font-weight: bold;
    }
    .ul1 {
      display: flex;
      justify-content: space-between;
      li {
        background: #fff;
        width: 290px;
        height: 334px;
        border-radius: 8px;
        box-shadow: -2px 0px 18px 2px rgba(119, 141, 251, 0.31);
        img {
          display: block;
          margin: 44px auto 0;
          width: 100px;
        }
        h3 {
          font-size: 20px;
          color: #1262d4;
          line-height: 70px;
          text-align: center;
          font-weight: bold;
        }
        p {
          margin: 0 33px;
          text-align: center;
        }
      }
    }
  }
}
.sec5 {
  width: 1200px;
  margin: 0 auto;
  img {
    margin-bottom: 100px;
  }
}
.sec6 {
  width: 1200px;
  margin: 0 auto;
}
.sec7 {
  width: 1200px;
  margin: 100px auto 0;
  h1 {
    color: #333;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    li:nth-child(odd) {
      flex-direction: row-reverse;
    }
    h3 {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}
</style>