import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'iview/dist/styles/iview.css';
import './assets/css/common.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import '../public/static/font/iconfont.css'
import MetaInfo from 'vue-meta-info';
import './utils/flexibility'
import ApplyDialogBtn from '@/modudel/index'

Vue.use(VueAwesomeSwiper)
Vue.use(MetaInfo)
Vue.use(ApplyDialogBtn)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
    // myBrowser();
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

function myBrowser() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    // 仅支持ie 10 以上版本
    if(fIEVersion < 10){
      alert("本网站暂不支持ie9及以下浏览器，请升级至ie10或使用其他浏览器打开")
    }
  }
}