<!-- 首页 -->
<template>
  <div class='home-container'>
    <BannerSwiper :bannerList="bannerList"></BannerSwiper>
    <HomeTitle :titleObj='homeMd1.titleObj'></HomeTitle>
    <div class="imgcenter"> 
      <img :src="homeMd1.img" alt="">
    </div>
    <HomeTitle :titleObj='homeMd2.titleObj'></HomeTitle>
    <ul class="con1200 ulflex-between listlicenter1 homMd2List"> 
      <li v-for="(item,index) in homeMd2.list" :key="index"> 
         <img :src="item.img" alt="">
         <h3>{{item.title}}</h3>
      </li>
    </ul>
    <div class="btnCenter">
      <button class="btnDefault applayBtnHome mt20" @click = "$store.commit('CHANGE_MODAL_TRUE')">了解更多</button>
    </div>
    <HomeTitle :titleObj='homeMd3.titleObj'></HomeTitle>
    <ul class="con1200 ulflex-between listlicenter2 homMd3List"> 
      <li v-for="(item,index) in homeMd3.list" :key="index"> 
         <img :src="item.img" alt="">
         <h3>{{item.title}}</h3>
         <p>{{item.text}}</p>
      </li>
    </ul>
    <ul class="con1200 ulflex-between2 liflexScrollLeft homMd4List"> 
      <li v-for="(item,index) in homeMd4.list" :key="index"> 
        <div>
          <h3>
            <span>{{item.title}}</span>
          </h3>
          <p v-html='item.text'></p>
          <button class="btnDefault applayBtnHome mt20" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
        </div>
        <img :src="item.img" alt="">
      </li>
    </ul>
    <HomeTitle :titleObj='homeMd5.titleObj'></HomeTitle>
    <div class="imgcenter"> 
      <img :src="homeMd5.img" alt="">
    </div>
    <HomeTitle :titleObj='homeMd6.titleObj'></HomeTitle>
    <div class="imgcenter mbt50"> 
      <img :src="homeMd6.img" alt="">
    </div>
    <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
  </div>
</template>
<script>
import HomeTitle from "./components/homeTitle.vue"
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import { index } from "@public/static/json/htmlContent";
import BannerSwiper from './components/BannerSwiper.vue'
import {mapState,mapActions } from 'vuex'
export default {
  name:'home',
  components: {
    BannerSwiper,
    ApplyDialog,
    HomeTitle
  },
  data() {
     return {
      bannerList:index.bannerList,
      homeMd1:index.homeMod1,
      homeMd2:index.homeMod2,
      homeMd3:index.homeMod3,
      homeMd4:index.homeMod4,
      homeMd5:index.homeMod5,
      homeMd6:index.homeMod6,
     };
  },
  computed: mapState([
    'headParams'
  ]),
  watch: {},
  created() {
    this.GetSystemData();
  },
  mounted() {

  },
  //方法集合
  methods: {
    ...mapActions([
      'GetSystemData', // 将 `this.increment()` 映射为 `this.$store.dispatch('increment')`
    ]),
  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
 .homMd2List{
  li{
    width:390px;
    height: 130px;
    margin-bottom:15px;
    h3{
      font-weight: bold; 
      color:#333;
      font-size:18px;
    }
  }
 }
 .homMd3List{
  margin-bottom:100px;
  li{
    width: 286px;
    height: 324px;
  }
}
.homMd4List{
  li{
    h3{
      position: relative;
      display: inline-block;
      margin-bottom:20px;
      span::after{
        content:'';
        width:100%;
        left:0;
        right:0;
        height:10px;
        border-radius: 10px;
        background:#3e7bf8;
        opacity: 0.17;
        position: absolute;
        display: inline-block;
        z-index:-1;
        bottom:3px;
      }
    }
  }
}
</style>