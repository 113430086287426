import axios from 'axios'

export default {
    fetchList(params) {
      return axios.post('/news_findNewsList.do', ObjectToUrlString(params) )
    },
    fetchHomeList(params) {
      return axios.post('/news_findHomeNewsList.do', {params})
    },
    fetchDetail(params) {
      return axios.get('/news_getDetail.do',{ params })       
    },
    fetchTagList(params) {
      return axios.get('/newsTag_findTagList.do',{ params })
    }
} 


function ObjectToUrlString(param) {
  let keys = Object.keys(param);
  let values = Object.values(param);
  let paramArray = [];
  for(let i=0;i<keys.length;i++) {
      paramArray.push(keys[i]+"="+values[i])
  }
  return paramArray.join("&");
}