<!--  -->
<template>
<div>
  <div v-if="titleStyle == true">
    <div class='topic' :style="{'width':titleW, 'margin-top':mgTop}">
      <h4>
        <span :style="{'width':lineW}"></span>
        <span class="title" >{{title}}</span>
        <span :style="{'width':lineW}"></span>
      </h4>
    </div>
    <p v-if="moduleText!==''" class="text">{{moduleText}}</p>
  </div>

  <div v-else class="titleS" :style="{'width':lineW}">
     <h4>{{title}}</h4>
     <p v-if="moduleText!==''" v-html="moduleText"></p>
  </div>
</div>  
</template>

<script>

export default {
  name:'Title',
  props:{
    title:String,
    lineW:String,
    moduleText:{
      type:String,
      default:''
    },
    mgTop:String,
    titleW:{
      type:String,
      default:"720px"
    },
    titleStyle:{
      type:Boolean,
      default:true
    }
  },
  components: {},
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .dian{
    position: absolute;
    bottom:-3px;
    content:'';
    width:5px;
    height:5px;
    border-radius:50%;
    background:#bfbfbf;
    z-index:100;  
  }
  .line{
    position: absolute;
    bottom:-6px;
    content:'';
    width:12px;
    height:1px;
    background:#bfbfbf;
    z-index:100;  
  }
  .topic{
    margin:0 auto 0;
    width:1200px;
    h4{
      // width:720px;
      margin:0 auto 20px;
      text-align: center;
      font-size:36px;
      color:#333;
      height:60px;
      display: flex;  
      span{
        position: relative;
        display: block;
        border-bottom:1px solid #bfbfbf;
      }
      span.title{
        margin-bottom:-5px;
        flex:1;
      }
      span.title::before{
        @extend .line;
        left:-7px;
        bottom:-4px;
        width:8px;
        transform: rotate(-45deg)
      }
      span.title::after{
        @extend .line;
          right:-7px;
          bottom:-4px;
          width:8px;
          transform: rotate(45deg)
        }
      span:first-child{
        margin-right:20px;
      }
      span:first-child::before{
          @extend .dian;
          left:-2px;
      }
      span:first-child::after{
          @extend .line;
          right:-10px;
          transform: rotate(45deg)
      }
      span:last-child{
        margin-left:20px;
      }
      span:last-child:before{
        @extend .dian;
        right:-1px;
      }
      span:last-child:after{
        @extend .line;
        left:-10px;
        transform: rotate(-45deg)
      }
    }
  }
  .text{
    width:920px;
    margin:0 auto 50px;
    text-align: center;
  }
  .titleS{
     margin:150px auto 0;
     text-align: center;
     h4{
      //  font-size:48px;
       font-size:40px;
      //  font-size:30px;
       line-height:60px;
       color:#333;
     }
     p{
       margin-top:20px;
       margin-bottom:50px;
      //  font-size:24px;
       font-size:22px;
      //  font-size:17px;
       color:#808080;
       line-height:48px;
     }
  }
  
</style>