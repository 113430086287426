<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosh"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap visitor-moudule'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="多渠道轻松获客"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg2" :moduleText="moduleText2" title="快速获客" lineW="1200px" :titleStyle="false" :imgTitle="imgJsonVisitors.img1.title" :imgAlt="imgJsonVisitors.img1.alt"></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg3" :moduleText="moduleText3" title="更多渠道" lineW="1200px" :titleStyle="false" :imgTitle="imgJsonVisitors.img2.title" :imgAlt="imgJsonVisitors.img2.alt"></tb-module>   
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>
  </div>
</template>
<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
import ImgList from './components/imgListModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    TbModule,
    ImgList,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/huiVisitorBanner.png'),
        moduleText2:'慧获客一种直接的获客方式，用户在渠道内（企查查、天眼查、启信宝、阿里巴巴、搜客宝）查看客户信息的同时，慧获客能直接获取信息，一次点击即可查看入库；提高搜索效率，帮助企业获取客户信息',
        moduleText3:'电商用户可以使用慧获客在阿里巴巴网站上获取网店资料，泛行业用户能够在企查查、天眼查、启信宝、搜客宝上直接搜客撞单快速入库',
        moduleImg1:require('../../assets/img/visitor-img1.png'),
        moduleImg2:require('../../assets/img/visitor-img2.png'),
        moduleImg3:require('../../assets/img/visitor-img3.png'),
        listText:[{'title':'多渠道聚合','text':'企查查、天眼查、启信宝、阿里巴巴、搜客宝'},{'title':'使用便捷','text':'轻松一点，快速获客'} ,{'title':'客户查重','text':'直接进行撞单查询'}],
        imgJsonVisitors: this.$store.state.imgJson.huiVisitors
        
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
</style>
<style>
  .visitor-moudule .titleS p{
    font-size:18px;
  }
  .btnPosh .applay-btn{
    border-color:#fff;
  }
</style>