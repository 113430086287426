<!--  -->
<template>
  <div class="form">
    <Form ref="formValidate"  :model="formItem" :label-width="80" :rules="ruleValidate">
      <FormItem label="公司名称" prop="compName">
            <Input type="text" placeholder="请输入" v-model="formItem.compName" />
        </FormItem>
         <FormItem label="姓名" prop="contactName">
            <Input type="text" placeholder="请输入" v-model="formItem.contactName" />
        </FormItem>
        <FormItem label="手机号" prop="contactPhone" >
            <Input placeholder="请输入" v-model="formItem.contactPhone" />
        </FormItem>
        <FormItem label="邮箱" v-if="!channel" prop="contactMail">
            <Input placeholder="请输入" type="email" v-model="formItem.contactMail" />
        </FormItem>
        <FormItem label="城市" prop="city">
            <div class="seleFlex">
            <select v-model="formItem.areaProvince" name="" id="" @change="cityChange()" class="ivu-select ivu-select-selection ivu-select-placeholder">
              <option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</option> 
            </select>
            <select v-model="formItem.areaCity" name="" id="" class="ivu-select ivu-select-selection ivu-select-placeholder">
                <option v-for="item in cityArea" :value="item.value" :key="item.value">{{ item.value }}</Option>
              </select>
            </div>
        </FormItem> 
        <FormItem  label="需求说明" v-if="channel"  prop="applyDesc">
          <Input type="textarea" :rows="4" v-model="formItem.applyDesc" placeholder="请输入..." />
        </FormItem>
        <FormItem>
          <div class="flex">
            <Button type="primary" @click="handleSubmit('formValidate')">提交申请</Button>
          </div>
        </FormItem>
    </Form>
  </div>
</template>
<script>
import Api from '@api/apply'
import  {cityData}  from '../../utils/city'
import { Button, Input ,Select,Form,FormItem,Option,Cascader,Message } from 'iview'
import axios from 'axios'
export default {
  name:'applayForm',
  components: {
    Button,
    Input,
    Select,
    Form,
    FormItem,
    Option,
    Cascader,
    Message,
  },
  props:{
    channel:{
      type:Boolean,
      default:false
    }
  },
  data() {
     const checkPhone = (rule, value, callback) => {
        const reg =  /^(0?1[123456789]\d{9})$/
        if (value === '') {
          callback(new Error('请输入手机号码'));
        }else if(!reg.test(value)){
           callback(new Error('请输入正确的手机号码'));
        }
        callback();
      };
      const checkCity = (rule, value, callback) =>{
          if(value&& value.length==0){
            callback(new Error('请选择城市'));
          }else{
            callback()
          }
          callback()
      }
      return {
        formItem: {
          compName: '',
          contactName:'',
          contactPhone:'',
          areaProvince:'北京',
          areaCity:'东城区',
          applyDesc:'',
          contactMail:'',
          select:''
        },
        ruleValidate: {
          contactName:[{ required: true, message: '姓名不能为空', trigger: 'blur' }],
          contactPhone:[ {required: true, validator: checkPhone, trigger: 'blur' }],
          city:[ {required: true, validator: checkCity, trigger: 'change' }],
          contactMail:[{required: false,type:'email', message: '邮箱错误', trigger: 'blur' }],
          areaProvince:[{required: true, message: '请选择省份', trigger: 'change' }],   
          applyDesc:[{required: false, message: '请选择备注', trigger: 'blur' }],
        },
        cityList: [],
        cityArea:[]
    }
  },
  computed: {},
  watch: {},
  created() {
    this.cityList = Object.assign(cityData)
    if(this.channel){
      this.ruleValidate.compName=[{required: true, message: '公司名不能为空', trigger: 'blur' }]
    }
    this.getCityArea('北京')
  },
  //方法集合
  methods: {
    // 百度回传
    baiduBack(){
      const Base64 = require('js-base64').Base64
      //参数不能换行
      let params = {token:'WzuUwgUrYlPiSTdbi2TAddtqcGxdfAMs@RMGilBMYtt7boRZV1Ku0NadItqHY3Ba9',conversionTypes:{logidUrl:'http://www.qftx.com/?bd_vid=uANBIyIxUhNLgvw-I-t3nWTvrH7xndtkPWT1nWf4njRLnH01',newType:'3'}}
      const paramsNew = Base64.encode(JSON.stringify(params))
      Api.fetchBaidu({data:paramsNew}).then(function(res){
        console.log(res)
      }).catch(function(err){
        console.log(err)
      })
    },
     handleSubmit (name) {
      // this.baiduBack()
      //提交表单
       const channel = this.channel
       const params = this.formItem
        this.$refs[name].validate((valid) => {
            if (valid) {
              if(this.channel) {  // 为真的时候 是渠道代理
                delete params.contactMail
                Api.fetchSaveChannel(params).then((res)=>{
                  Message.success('提交成功');
                  // window._agl && window._agl.push(['track', ['success', {t: 3}]])
                  this.baiduBack()
                  this.$emit('closeDialog')
                  this.getCityArea('北京') 
                  this.$refs.formValidate.resetFields()
                })
              }else{
                delete params.applyDesc
                Api.fetchSaveCompany(params).then((res)=>{
                  Message.success('提交成功');
                  // window._agl && window._agl.push(['track', ['success', {t: 3}]])
                  this.baiduBack()
                  this.getCityArea('北京')
                  this.$emit('closeDialog')
                  this.$refs.formValidate.resetFields()
                })
              }
            }
        })
      },
      getCityList(){
        const that = this;
        this.cityList = []
        for(let i in cityData){
          that.cityList.push({
            value:cityData[i].value,
            lable:cityData[i].label
          })
        }
      },
      getCityArea(value){
        this.cityArea = []
        for(let i in cityData){
          if(cityData[i].value == value){
            this.cityArea = cityData[i].children
            this.formItem.areaCity =  this.cityArea[0].value
            this.formItem.areaProvince =  value
          }
        }
      },
      cityChange(){
        this.getCityArea(this.formItem.areaProvince)
      }
  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .form{
    margin:0 auto;
    width:650px;
  }
  .flex{
    display:flex;
    justify-content: center;
  }
  .seleFlex{display: flex;}
  .ivu-select-placeholder{
    width:200px;
    margin-right:5px;  
    height:30px;
    line-height:30px;
  }
</style>