<template>
	<div class="alltitlStyle">
		<h2 :class="['title', titleClass]">
			<span>{{ title }}</span>
		</h2>
		<p v-if="titleP" v-html="titleP"></p>
		<h2 v-if="subTitle" class="subTitle">
			<span>{{ subTitle }}</span>
		</h2>
	</div>
</template>
<script>
export default {
	name: "TitleAssembly",
	props: {
		title: "",
		titleClass: "",
		titleP:'',
		subTitle:''
	},
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>
<style lang="scss" scoped>
.subTitle{
	text-align: center;
	font-size: 30px;
}
.title {
	font-size: 30px;
	text-align: center;
	margin: 36px 0 15px 0;
	span {
		position: relative;
		padding: 0 28px;
		display: inline-block;
		max-width:599px;
		&::before,
		&::after {
			position: absolute;
			top: 16px;
			background: url(../../../assets/images/hyj/titleBg6.png) no-repeat;
			content: "";
			width: 19px;
			height: 18px;
		}
		&::before {
			left: 0;
		}
		&::after {
			right: 0;
		}
	}
}
// .titleStyle8{
// 	span {
// 		&::before,
// 		&::after {
// 			background: url(../../../assets/images/hyj/titleBg6.png) no-repeat;
     
// 		}
// 	}
// }

.alltitlStyle{
  margin-bottom:50px;
	p{
		margin:0 auto;
		font-size:16px;
		color:#808080;
		width:80%;
		text-align: center;
	}
}
</style>
