<template>
  <div class="cardList">
    <ul>
      <li
        v-for="(it, index) in listdata"
        :key="index"
        :style="{ 'background-image': 'url(' + it.img + ')' }">
        <div>
          <p>{{ it.title }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "cardList",
  components: {},
  props:{
      listdata: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    toprocenter(index){
      let path = ['/qgcard','/bmdcard','/yycard','/gpcard']
      this.$router.push({path:path[index]})
    }
  },
};
</script>

<style lang="scss">
.cardList {
  margin: 0 auto;
  width: 1200px;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      position: relative;
      width: 593px;
      height: 335px;
      margin-bottom:15px;
      div {
        cursor: pointer;
        display: none;
        top: 0;
        right: 0;
        width: 593px;
        height: 335px;
        background: rgba(0, 0, 0, 0.5);
        padding:42px 0 0 34px;
        box-sizing: border-box;
        p {
          width: 523px;
          height: 263px;
          color: #fff;
          border: 2px solid #9dadd2;
          border-radius: 10px;
          text-align: center;
          line-height: 263px;
          font-size:28px;

        }
      }
      &:hover{
        div{
            display: block;
        }
      }
    }
  }
}
</style>

