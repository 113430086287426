<template>
  <div class="footer">
    <div class="container-1200px">
      <div class="main-content">
        <div class="content-item products">
          <div class="title">更多应用</div>
          <ul class="list">
            <li v-for="item in products" :key="item.path">
              <a :href="item.path">{{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div class="content-item about">
          <div class="title">关于我们</div>
          <ul class="list">
            <li v-for="item in about" :key="item.path">
              <a :href="item.path">{{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div class="content-item contacts">
          <div class="title">联系方式</div>
          <ul class="list">
            <li v-for="item in contacts" :key="item.key">
              <!-- <i :class="item.icon"></i> -->
              <span>{{ item.text }}</span>
              <span>{{ item.value }}</span>
            </li>
          </ul>
        </div>
        <div class="content-item qrcode">
          <div class="title">售前客服</div>
          <div class="image">
            <!-- <img :src="qrcode[0].imgUrl" :alt="qrcode[0].key" /> -->
            <img src="@images/codeFooter.png" alt="" />
          </div>
          <div class="exp">
            <span>电话：18958188698</span>
            <span>周一到周日 8:30-18:00</span>
          </div>
        </div>
        <div class="content-item qrcode">
          <div class="title">售后客服</div>
          <div class="kfBtn">
            <a
              target="_blank"
              href="http://hfw.qifengyun.net/guestMessage/index#/conversation?channelId=fa974b74a76a484abb90f63eb2dc3255&orgId=qfkfzy&url=www.qifengyun.com"
              >在线联系客服入口</a
            >
            <p>点击上方入口咨询</p>
          </div>
          <div class="exp" style="font-size: 14px">
            <span>电话: 400-826-2277</span>
            <span>周一到周日 8:30-20:30</span>
          </div>
        </div>
        <div class="content-item qrcode">
          <div class="title">微信公众号</div>
          <div class="image">
            <!-- <img :src="qrcode[1].imgUrl" :alt="qrcode[1].key" /> -->
            <img src="@images/codeFooter2.png" alt="" />
          </div>
          <div class="tips">{{ qrcode[1].tips }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="copyright">
        <span v-for="(item,index) in copyright" :key="item.key">
          <span v-if="item.url">
            <a rel="nofollow" target="_blank" :href="item.url">{{item.value}}</a>
          </span>
          <span v-else-if="item.key == 'copyright'">{{item.value}}{{coryright}}</span>
          <span v-else>{{item.value}}</span>
          <span v-if="index != (copyright.length-1)">|</span>
        </span>
      </div> -->

    <div class="copy-right">
      <div>
        浙江企蜂信息技术有限公司
        <span>|</span>
        {{ copyright }}
        <span>|</span>
        <a rel="nofollow" target="_blank" href="https://beian.miit.gov.cn">浙ICP备19031541号-17</a>
      </div>
    </div>
  </div>
</template>
<script>
import { footer } from "@/global/json/json";
let { products, about, contacts, qrcode, links } = footer;
export default {
  data() {
    return {
      products,
      contacts,
      qrcode,
      links,
      copyright: "",
      links_show: true,
      about,
      isShowLink:false,
    };
  },
  created() {
    const now = new Date();
    const year = now.getFullYear()
    this.copyright = ' Copyright © 2009-' + year
  },
  mounted() {
     
  },
  methods: {
     
  },
};
</script>
  <style lang="scss"  scoped>
.footer {
  font-size: 12px;
  color: #aeb2cd;
  background-color: rgb(20, 22, 34);
  padding-top: 100px;
  // margin-top: 40px;

  .title {
    font-size: 16px;
    color: white;
    padding-bottom: 28px;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul.list > li {
    line-height: 48px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }

  .main-content {
    display: flex;
    margin-bottom: 60px;

    .products {
      width: 135px;
    }
    .contacts {
      padding-left: 60px;
      padding-right: 29px;
      ul.list > li {
        margin-bottom: 13px;
        span {
          display: block;
          line-height: 23px;
        }
        i {
          font-size: 18px;
          padding-right: 10px;
        }
      }
    }
    .qrcode {
      width: 270px;
      padding-left: 60px;
      // border-left: 1px solid rgba(40, 43, 52);
      .image {
        width: 120px;
        height: 120px;
        & > img {
          width: 100%;
        }
      }
      .kfBtn {
        a {
          display: block;
          width: 140px;
          height: 30px;
          background: #ffffff;
          border-radius: 4px;
          text-align: center;
          line-height: 30px;
          color: #141621;
          font-size: 14px;
        }
        p {
          margin-top: 15px;
          text-align: center;
          width: 140px;
        }
      }
      .exp {
        margin-top: 20px;
        span {
          display: block;
          // font-size:14px;
          line-height: 30px;
        }
      }
      .tips {
        line-height: 50px;
      }
    }
  }

  .about {
    width: 150px;
    text-align: center;
  }
}
$color80: #aeb2cd;
.copy-right {
  margin-top: 23px;
  padding-bottom: 30px;
  font-size: 14px;
  color: $color80;
  text-align: center;
  line-height: normal;
  font-size: 14px;
  a {
    display: inline;
    color: $color80;
  }
  a:hover {
    color: #2299ee;
  }
  span {
    padding: 0 10px;
  }
}
</style>
