<!--  -->
<template>
<div class="liList">
  <h4>{{title}}</h4>
  <p class="pTtext">{{titleText}}</p>
  <div class='show-module'  :style="{'background-image':'url('+bg+')'}">
      <ul>
        <li v-for="(text,index) in liText" :key="index">
          {{text}}
        </li>
      </ul>
  </div>
</div>  
</template>
<script>

export default {
  name:'',
  components: {},
  props:{
    title:String,
    titleText:String,
    bg:String,
    liText:{
      type:Array,
      default:[]
    }
  },
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .liList{
    .show-module{
      margin-top:30px;
      // height:778px;
      background-repeat:no-repeat;
      background-position:center center;
      background-size: contain;
      ul{
        display: flex;
        justify-content: space-around;
        flex-wrap:wrap;
        li{
          width:380px;
          text-align: center;
          padding-top:400px;
          height:430px;
          box-sizing: border-box;
        }
      }
    }
    h4{    
        text-align: center;
        font-size:36px;
        color:#333;
        height:60px;
        margin:80px 0 10px 0;
      }
      p{
        font-size:18px;
        color:#808080;
      }
    
  }
</style>