<template>
  <div class="container hyj-con">
    <Banner :banner="banner" />
    <TitleAssembly
      :title="hyjmd1.title"
      :titleClass="'titleStyle8'"
    ></TitleAssembly>
    <div class="hyj-md1">
      <p v-for="(item, index) in hyjmd1.list" :key="index">
        <span>
          {{ item }}
        </span>
      </p>
    </div>
    <div class="flexCenterBtn">
      <apply-dialog buttonColor='slot'>
        <template slot="btn">
          <button class="applyBtn" style="margin-top:50px;" >立即申请</button>
        </template>
      </apply-dialog>
    </div>
    <TitleAssembly
      :titleClass="'titleStyle8'"
      class="hyjTitle2"
      :title="hyjmd2.title"
    ></TitleAssembly>
    <!-- //tabs -->
    <div class="tabs">
      <Tabs :animated="false">
        <TabPane
          v-for="(it, idx) in hyjmd2.list"
          :label="(e)=>tabTitle(e,it)"
          :key="idx"
        >
          <!-- <span slot="extra">
            <img :src="it.icon" alt="" />
            <span style="color: #5348f7">{{ it.tabTitle }}</span>
          </span> -->
          <div class="tab-con" :style="{ 'background-color': it.bg }">
            <div>
              <p>{{ it.tabConList }}</p>
              <apply-dialog buttonColor='slot'>
                <template slot="btn">
                  <button class="applyBtn" >立即申请</button>
                </template>
              </apply-dialog>
            </div>
            <img :src="it.img" alt="" />
          </div>
        </TabPane>
      </Tabs>
    </div>
    <TitleAssembly
      :titleClass="'titleStyle8'"
      :title="hyjmd3.title"
    ></TitleAssembly>
    <div class="hyj-steps">
      <div v-for="(it, index) in hyjmd3.steps" :key="index">
        <span class="h-i">0{{ index + 1 }}</span>
        <span class="h-t">{{ it }}</span>
        <span class="h-icon" v-if="index < hyjmd3.steps.length - 1"></span>
      </div>
    </div>
    <div class="hyjmd3style">
      <ul>
        <li v-for="(item, index) in hyjmd3.list" :key="index">
          <img :src="item.img" :alt="item.alt" :title="item.imgTitle" />
          <h4>{{ item.h4 }}</h4>
          <p v-html="item.p"></p>
          <span>{{ item.span1 }}</span>
          <span>{{ item.span2 }}</span>
        </li>
      </ul>
    </div>
    <div class="flexCenterBtn">
      <apply-dialog buttonColor='slot'>
        <template slot="btn">
          <button class="applyBtn" >立即申请</button>
        </template>
      </apply-dialog>
    </div>
    <TitleAssembly
      :titleClass="'titleStyle8'"
      :title="hyjmd4.title"
    ></TitleAssembly>
    <ImgTextList :ITlistObj="hyjmd4.list" ulListStyle="hyjMd4style" />
    <div class="flexCenterBtn">
      <apply-dialog buttonColor='slot'>
        <template slot="btn">
          <button class="applyBtn" >立即申请</button>
        </template>
      </apply-dialog>
    </div>
    <div class="hyjmd5">
      <div class="contain-1200px">
        <TitleAssembly
          :titleClass="'titleStyle2'"
          :title="hyjmd5.title"
        ></TitleAssembly>
        <ImgTextList :ITlistObj="hyjmd5.list" ulListStyle="hyjMd5style" />
      </div>
      <div class="flexCenterBtn">
          <apply-dialog buttonColor='slot'>
            <template slot="btn">
              <button class="applyBtn colorwh" >立即申请</button>
            </template>
          </apply-dialog>
      </div>
    </div>
    <div class="hyjmd6">
      <div class="contain-1200px">
        <TitleAssembly
          :titleClass="'titleStyle8'"
          :title="hyjmd6.title"
        ></TitleAssembly>
        <ImgTextList :ITlistObj="hyjmd6.list" ulListStyle="hyjMd6style" />
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from "iview";
import TitleAssembly from "./components/TitleAssembly";
import Banner from "./components/Banner";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
// import UpTitleImgBtn from "@/components/layoutStyle/upTitleImgBtn";
import ImgTextList from "./components/ImgTextList";
import { hyj } from "@public/static/json/htmlContent";

export default {
  name: "hfw",
  props: {},
  components: {
    Banner,
    ApplyDialog,
    TitleAssembly,
    ImgTextList,
    Tabs,
    TabPane,
  },
  data() {
    return {
      banner: {},
      hyjmd1: hyj.md1,
      hyjmd2: hyj.md2,
      hyjmd3: hyj.md3,
      hyjmd4: hyj.md4,
      hyjmd5: hyj.md5,
      hyjmd6: hyj.md6,
    };
  },
  created() {
    this.banner = { ...hyj.banner };
  },
  mounted() {},
  methods: {
    handleTabs() {},
    tabTitle(h,it) {
      return h("div", [
        h('img',{attrs:{src:it.icon,class:'tabsIcon'}}),
        h("span", it.tabTitle ),
      ]);
    },
  },
};
</script>
<style lang="scss">
.footer {
  margin-top: 0;
}
.hyj-con {
  .titleStyle2 span {
    color: #fff;
  }
}
.hyj-con .banner {
  .bannerTitle {
    margin-bottom: 20px;
    color: #484848;
  }
  .bannerList {
    color: #484848;
    & p:first-child {
      font-weight: bold;
    }
    p {
      margin-bottom: 10px;
      font-size: 24px;
    }
  }
  p {
    span {
      color: #ff7800;
    }
  }
}
.bannerStyleHyj {
  border-color: #5348f7 !important;
  color: #5348f7 !important;
}
.hyjTitle2 {
  margin: 50px auto;
  .title span {
    max-width: 939px !important;
  }
}
</style>
<style lang="scss" scoped>
.hyj-con {
  .applyBtn {
    width:200px;
    height: 51px;
    font-size: 24px;
    border: 1px solid #5348f7;
    background: none;
    color: #5348f7;
    cursor: pointer;
    cursor: pointer;
    display: block;
    text-align: center;
    line-height: 51px;
    border-radius: 8px;
    margin-bottom:100px;
  }
  .applyBtn:hover {
    background: #5348f7;
    color: #fff;
  }
  .colorwh{
    background: none;
    border-color:#fff;
    color: #fff;
  }
  .hyjmd5btn {
    width: 180px;
    height: 51px;
    font-size: 24px;
    border: 1px solid #2048cb;
    background: none;
    color: #2048cb;
    cursor: pointer;
    display: block;
    border-color: #fff !important;
    color: #fff !important;
    margin-bottom: 20px;
  }
}
.hyj-md1 {
  margin: 100px auto;
  background: url("../../assets/images/hyj/tu1.png") top center no-repeat;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  height:500px;
  p {
    height: 74px;
    width: 500px;
    font-size: 18px;
    font-weight: 500;
    text-align: justifyLeft;
    color: #666666;
    line-height: 28px;
    box-sizing: border-box;
    span {
      display: block;
      width: 280px;
    }
  }
  & p:first-child {
    padding-left: 162px;
  }
  & p:nth-child(2) {
    padding-left: 437px;
    margin-top: 40px;
    span {
      width: 240px;
    }
  }
  & p:nth-child(3) {
    padding-left: 40px;
    padding-top: 26px;
    span {
      width: 310px;
    }
  }
  & p:nth-child(4) {
    padding-left: 490px;
    padding-top: 85px;
    span {
      width: 290px;
    }
  }
  & p:nth-child(5) {
    padding-left: 100px;
    padding-top: 85px;
  }
  & p:nth-child(6) {
    padding-left: 440px;
    padding-top: 190px;
    span {
      width: 250px;
    }
  }
  & p:last-child {
    padding-left: 160px;
    padding-top: 60px;
  }
}
.flexCenterBtn {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}

.ivu-tabs-nav{

}
.tabs {
  width: 1200px;
  margin: 0 auto 100px;
  .tabsIcon{
    margin-right:10px
  }
  img {
    width: 36px;
    height: 36px;
  }
  .tab-con {
    display: flex;
    justify-content: space-between;
    padding: 30px 50px;
    img {
      width: auto;
      height: auto;
    }
    p {
      margin-top:80px;
      width: 514px;
      font-size: 18px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: justifyLeft;
      color: #666666;
      line-height: 32px;
      margin-bottom: 37px;
    }
  }
}
/deep/ .ivu-tabs-tab{
  div{
    display: flex;
    align-items: center;
    img{
      margin-right:10px;
    }
  }
}
/deep/ .ivu-tabs-bar{
  border:none;
}
/deep/ .ivu-tabs-nav-scroll{
  display: flex;
  justify-content: center;
  font-size:22px;
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab{
  padding-bottom:20px;
}
/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color:#5348f7;
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab:hover{
  color:#5348f7;
}
/deep/ .ivu-tabs-ink-bar{
  background:#5348f7;
  height:4px;
  border-radius:5px;
}
/deep/ .ivu-tabs-bar{
  margin-bottom:0;
}
.hyjmd4List ul {
  display: flex;
  justify-content: space-between;
  li {
    width: 317px;
    box-shadow: 1px 1px 3px 3px #e1ecfe;
    border-radius: 10px;
    box-sizing: border-box;
    background: #fff;
    border: 3px solid #fff;
    div {
      padding: 0 25px;
    }
    h4 {
      font-size: 16px;
      color: #1163d3;
      margin-top: 30px;
      margin-bottom: 10px;
      text-align: center;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: #333;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 248px;
    }
  }
  li:hover {
    border: 3px solid #80b7f0;
  }
}
.hyjmd3style {
  margin: 0 auto 50px;
  width: 1200px;
  ul {
    display: flex;
    justify-content: space-around;
    li {
      width: 230px;
      text-align: center;
      border: 1px solid #395df9;
      box-sizing: border-box;
      padding: 37px 35px 62px;
    }
    img {
      width: 50px;
      height: 51px;
    }
    h4 {
      font-size: 22px;
      color: #333;
    }
    p {
      font-size: 16px;
      color: #666;
    }
    span {
      display: block;
      width: 160px;
      height: 32px;
      line-height: 32px;
      background: linear-gradient(103deg, #6fc4f5 0%, #5f52e2 100%);
      border-radius: 16px;
      font-size: 16px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
      mix-blend-mode: multiply;
    }
  }
}
.hyj-steps {
  margin: 60px auto;
  display: flex;
  justify-content: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .h-i {
    width: 36px;
    height: 36px;
    background: #5348f7;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 36px;
    font-size: 20px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  }
  .h-t {
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    margin: 0 10px;
    font-size: 22px;
  }
  .h-icon {
    display: block;
    width: 30px;
    height: 20px;
    background: url("../../assets/images/hyj/hyj-jt.png") center no-repeat;
    margin-right: 10px;
  }
}
.hyjmd5 {
  background: url("../../assets/images/hyj/bg.png") center no-repeat;
  height: 561px;
  padding-top:22px;
}
/deep/ .ant-tabs-bar {
  padding-left: 112px;
}
</style>
