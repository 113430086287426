<!--  -->
<template>
<div>
  <div class="banner"></div>
  <div class='wrap'>
     <div class="newsList">
        <ul>
          <li v-for="(item,index) in newsList" :key="index" @click="toNewsDetail(item.newsId)">
              <img :src="item.newsImgUrl" alt="">
              <div class="news-text">
                <h4>{{item.newsTitle}}</h4>
                <p>{{item.newsDesc}}</p>
                <div class="text-tags">
                  <span v-for="(item,index) in item.tagNameList" :key="index">{{item}}</span>
                </div>
                <div class="update">{{item.publishTime}}</div>
              </div>
          </li>
        </ul>
        <div class="page" v-show="pageShow">
        <Page :total="pageTotal" @on-change="pageChange"></Page>
        </div>
     </div>
     <div class="tags" id="tags" > 
        <p>热门标签</p>
        <div>
          <span v-for="(item,index) in tagList" :class="{'hover':tagId == item.tagId}" :key="index" @click="filterTag(item.tagId,index)">{{item.tagName}}</span>
        </div>
     </div>
  </div>
</div>
</template>
<script>
import { Page } from 'iview'
import Api from '@api/news'
export default {
  name:'',
  components: {
    Page
  },
  props:{},
  data() {
     return {
       newsList:[],
       pageTotal:10,
       tagList:[],
       pageShow:true,
       styleShow:true,
       tagId:''
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {
    this.tagId = this.$route.query.tagId || ''
    this.getNewsList({tagId:this.tagId});
    this.getTags();
  },
  //方法集合
  methods: {
    // 回顶部
    pageChange(page){
      const params={
        page:page,
        tagId:''
      }
      this.getNewsList(params)
      window.scrollTo(0,0); 
      // 通过页码获取数据
    },
    getNewsList(params={}) {
      this.newsList = []
      Api.fetchList(params).then(res=>{
        this.pageTotal = res.data.data.page.pageTotal*10
        this.newsList = res.data.data.data
        if( this.newsList.length == 0){
          this.pageShow = false;
        }
      })
    },
    toNewsDetail(id){
      window.location.href = '/news.do?id='+id;
    },
    getTags(params={}){
      Api.fetchTagList(params).then((res)=>{
        this.tagList = res.data.data;
      })
    },
    filterTag(tagId,index){
      const tags = document.getElementById('tags')
      const span = tags.getElementsByTagName('span')
      if(span[index].className == 'hover'){
          this.getNewsList()
          span[index].className = ''
      }else{
        const params={
          page:1,
          tagId:tagId
        }
        this.getNewsList(params)
        for(let i=0;i<span.length;i++){
          span[i].className = ''
        }
        span[index].className = 'hover'
      }
    }
  },
}
</script>
<style lang='scss' scoped>
  .banner{
    height:215px;
    background:url('../../assets/img/newsBanner.png') no-repeat center center;
  }
  .wrap{
    display:flex;
    justify-content: space-between;
  }
  .newsList{
    width:855px;
    li{
      margin-top:32px;
      display: flex;
      cursor: pointer;
      img{
          display:block;
          width:216px;
          height:144px;
        }
      .news-text{
        margin-left:24px;
        width:612px;
        color:#333;
        h4{
          font-size:18px;
          line-height:normal;
          margin-bottom:10px;
           cursor: pointer;
           text-overflow: ellipsis;
            white-space:nowrap;
            overflow:hidden;
            width:100%;
        }
        p{
          font-size:12px;
          color:#808080;
          line-height:24px;
           cursor: pointer;
        }
        .text-tags{
            color:#44b5f2;
            font-size:12px;
            display: flex;
            margin:10px 0;
            span{
              margin-right:15px;
              display:block;
              padding:7px 20px;
              background:#e1f3fd;
              border-radius:27px;
            }
        }
        .update{
          font-size:12px;
          color:#808080;
        }
      }
    }
    li:hover h4,li:hover p{
      color:#2d8cf0;
    }
  }
  .tags{
    margin-top:25px;
    width:250px;
    p{
      position: relative;
      font-size:16px;
    }
    div{
      display:flex;
      flex-wrap:wrap;
      span{
        font-size: 12px;
        display: block;
        margin:5px 0;
        padding:3px 8px;
        color:#fff;
        background:#54c0fc;
        border-radius:11px;
        margin-right:16px;
        cursor: pointer;
        border:1px solid #fff;
        box-sizing: border-box;
      }
      span:hover,span.hover{
        border:1px solid #54c0fc;
        background:#fff;  
        color:#54c0fc;
      }
    }
  }
  .page{
    margin-top:50px;
    width:100%;
    display:flex;
    justify-content: center;
  }
  .cancelHover div span.hover{
    background:#54c0fc;
    border:1px solid #fff;
  }
</style>