<!-- 左右文字图片布局-->
<template>
<div class="lrText">
    <h4 class="whyTitle">{{title}}</h4>
    <p class="pTtext">{{titleText}}</p>
    <div v-for="(item,index) in jsonObj" :key="'jb'+index">
      <div v-if="index%2==0" class="product-module" >
        <div>
          <h5 :style="item.titleNotShow ? 'background: none' : null">{{item.title}}</h5>
          <p v-html="item.text"></p>
        </div>
        <div>
          <img :src="item.imgSrc" :alt="item.imgAlt" :title="item.imgTitle">
        </div>
      </div>
      <div v-else class="product-module" style="flex-direction:row-reverse">
         <div>
          <h5>{{item.title}}</h5>
          <p v-html="item.text"></p>
        </div>
        <div>
          <img :src="item.imgSrc" :alt="item.imgAlt" :title="item.imgTitle">
        </div>
      </div>
    </div>
</div>    
</template>

<script>
export default {
  name:'',
  components: {},
  props:{
    jsonObj:{
      type:Array,
      default:[] 
    },
    title:String,
    titleText:String
  },
  data() {
     return {
     };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .product-module{
    margin-top:64px;
    display: flex;
    justify-content: space-between;
    h5{
      padding:2px 0 0 20px;
      width:317px;
      height:84px;
      background:url("../../../assets/img/out-t-bg2.png") 0 0 no-repeat;
      font-size:30px;
      color:#f0f9fe;
      box-sizing: border-box;
      margin-top:20px;
    }
    img{
      display:block;
      // margin-top:70px;  
      width:100%;
    }
    p{
      width:465px;
      font-size:16px;
      line-height:60px;
      line-height:40px;
    }
  }
 
</style>
<style lang='scss'>
.lrText .whyTitle{
  margin-bottom:25px;
}
</style>