import { render, staticRenderFns } from "./UseScene.vue?vue&type=template&id=363ec548&scoped=true&"
import script from "./UseScene.vue?vue&type=script&lang=js&"
export * from "./UseScene.vue?vue&type=script&lang=js&"
import style0 from "./UseScene.vue?vue&type=style&index=0&id=363ec548&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363ec548",
  null
  
)

export default component.exports