<!--  -->
<template>
  <div class=''>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5">
        <div class="banner-text">
          <h4>应用中心+CRM+智能通信</h4>
          <p>一站式企业综合营销服务SaaS平台引领智慧营销新时代<span>渠道代理火热征集中...</span></p>
        </div>
        <div class="agentBtn">
          <apply-dialog btnText="申请加盟" :channel="true"></apply-dialog>
        </div>
    </Banner>
    <div class="wrap">
      <Why></Why>
      <button class="applayBtnRed"  @click = "$store.commit('CHANGE_MODAL_TRUE')">申请加盟</button>
      <offerWhat></offerWhat>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">申请加盟</button>
      <entry-zixu></entry-zixu>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">申请加盟</button>
      <apply-dialog-btn :channel="true" btnText="申请加盟" :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>
  </div>
</template>

<script>
// import { fetch } from '../utils/fetch'
import Banner from '@/modudel/Banner.vue'
import applayForm from '@/modudel/ApplayForm'
import Why from './components/Why'
import offerWhat from './components/OfferWhat'
import entryZixu from './components/EntryZixu'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'home',
  components: {
    Banner,
    Why,
    offerWhat,
    entryZixu,
    applayForm,
    ApplyDialog
  },
  data() {
     return {
        bannerImg:require('../../assets/img/agentBanner.png'),
     };
  },
  computed: {},
  watch: {},
  created() {
    
  },
  mounted() {
  },
  //方法集合
  methods: {
    getNews(){
        // fetch('url').then(()=>{

        // })
    },
    getProblem(){

    }

  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .banner-text{
    padding-top:90px;
    color:#fff;
    h4{
      margin-bottom:10px;
      font-size:36px;
      line-height:74px;;
    }
    p{
      font-size:18px;
      line-height:40px;
      span{
      display: block;
      color:#fde200
    }
    }
  }


</style>
<style>
  .agentBtn .apply-btn{
    margin-top:180px;
  }
</style>
