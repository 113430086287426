<!--  -->
<template>
  <div>
       <router-view/>
  </div>
</template>

<script>
export default {
  name:'',
  components: {
  },
  props:{},
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' >
  //@import url(); 引入公共css类
  .btnPos .apply-btn{
    width:l82px;
    margin-top:381px;
    color:#333;
    border-color:#000;
    font-size:16px;
  }
</style>