<!--  -->
<template>
  <div class=''>
      <Title title="使用场景" :moduleText="moduleText" :titleStyle="false" lineW="1200px"></Title>
      <div class="use-scence" :style="{'background-image':'url('+usrSceneBg+')'}">
        <ul class="scence">
          <li v-for="(item,index) in scenceText" :key="index">
             <p class="title">{{item.title}}</p>
             <div class="hoverStyle">
               <div>
                <p>{{item.title}}</p>
                <span>{{item.text}}</span>
               </div>
             </div>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import Title from '@/modudel/Title.vue'
export default {
  name:'',
  components: {
    Title
  },
  props:{
    usrSceneBg:String,
    moduleText:String,
    scenceText:{
      type:Array,
      default:[]
    }
  },
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .use-scence{
    width:1198px;
    height:550px;
    background-repeat: no-repeat;
    background-position: center top;
    .scence{
      width:100%;
      display:flex;
      justify-content: space-between;
      align-items: flex-end;
      color:#fff;
      text-align: center;
      li{ 
        width:375px;
        height:550px;
        position: relative;
        overflow:hidden;
        p{
          font-size:24px;
          
        }
        p.title{
          width:100%;
          background:rgba(0,0,0,0.5);
          height:50px;
          position: absolute;
          bottom:0;
          left:0;
           transition: all 0.1s;
        }
        .hoverStyle{
          position: absolute;
          top:550px;
          left:0;
          background:rgba(0,0,0,0.5);
          width:375px;
          height:550px;
          display:flex;
          justify-content: center;
          align-items: center;
          transition: all 0.8s;
          div{
            width:240px;
            height:140px;
            border:3px solid #fff;
            box-sizing: border-box;
            padding:20px 28px;
          }
          span{
            display:block;
            font-size:18px;
            border-top:2px solid #fff;
            padding-top:10px;
            margin-top:10px;
          }
        }
      }
      li:nth-child(2){
       margin-left:5px; 
      }
      li:hover{
        .hoverStyle{
          top:0;
          left:0;
        }
        p.title{
          bottom:-50px;
        }
      }
    }
  }
</style>