<!--  -->
<template>
  <div class='' >
     <!-- <button class="apply-btn" :class="{applyBtnred:btnRed}" @click="formDialog()">{{btnText}}</button> -->
      <Modal 
         v-model="modelVisible"
         width="800"
         :mask-closable="false"
         @on-cancel="cancel"
         class="modal">
        <p slot="header" class="modalTitle">
          <span>{{btnText}}</span>
          <!-- <i class="iconfont icon-shanchu" @click="cancel()"></i> -->
        </p>
        <applay-form :channel="channel" ref="form" @closeDialog="cancel"></applay-form>
        <div slot="footer" class="footer">
        </div>
      </Modal>
  </div>
</template>
<script>
import { Modal } from 'iview'
import ApplayForm from './ApplayForm.vue'
export default {
  name:'', 
  components: {
    Modal,
    ApplayForm
  },
  props:{
    btnText:{
      type:String,
      default:'开通申请'
    },
    channel:{
      type:Boolean,
      default:false,
    },
    // btnRed:{
    //   type:Boolean,
    //   default:false,
    // },
    modelVisible:{
      type:Boolean,
      default:false,
    }
  },
  data() {
     return {
        dialogV:false,
     };
  },
  computed: {},
  created() {
      // this.dialogV = this.modelVisible
  },
  mounted() {
  },
  //方法集合
  methods: {
    cancel(){
      // this.modelVisible = false;
      this.$refs.form.$refs.formValidate.resetFields()
      this.$emit('cancle')
    }
  }
}
</script>
<style lang='scss'>
  //@import url(); 引入公共css类
  .modalTitle{
    color:#333;
    font-size:18px;
    text-align: center;
    position: relative;
    i{
      position:absolute;
      top: 5px;
      right:10px;
      font-size:26px;
      color:#999;
      cursor: pointer;
    }
  }
  .modal .ivu-modal-footer{
     border-top:none;
  }
   .apply-btn{
    margin-top:35px;
    width:180px;
    $height:60px;
    line-height: $height;
    $background-color:#fff;
    background-color:rgba(255,255,255,0);
    border:2px solid $background-color;
    text-align: center;
    border-radius:10px;
    font-size:16px;
    color:$background-color;
    cursor: pointer;
  }
  .applyBtnred{
    background:#f07cbd;
    border-color:#f07cbd;
    font-size:24px;
    margin:30px auto 0;
    width:180px;
    display:block;
  }
</style>