<template>
  <div class="Compatibility">
    <div class="Compatibility-icon">
      <img src="/static/images/icon@2x.png" />
    </div>
    <div class="Compatibility-tip">
      本网站暂不支持ie9及以下浏览器
      <br />请升级至ie10或使用其他浏览器打开
    </div>

    <div class="CompatibilityBrowsers">
      <div class="CompatibilityBrowsers-item" v-for="(item,index) in browserImages" :key="index">
        <div class="CompatibilityBrowsers-icon">
          <img :src="'/static/images/'+item.imgName"/>
        </div>
        <div class="CompatibilityBrowsers-text">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      browserImages: [
        {
          name:"chrome",
          imgName: "chrome.png"
        },
        {
          name:"360浏览器(极速模式)",
          imgName: "360.png"
        },
        {
          name:"firefox",
          imgName: "firefox.png"
        },
        {
          name:"猎豹浏览器",
          imgName: "liebao.png"
        },
        {
          name:"更多其他浏览器",
          imgName: "more.png"
        }
      ]
    }
  }
}
</script>


<style scoped>
.Compatibility {
  width: 1008px;
  margin: 0 auto;
  padding: 16px;
}

.Compatibility-icon {
  width: 112px;
  height: 83px;
  margin: 64px auto;
}

.Compatibility-icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.Compatibility-tip {
  font-size: 24px;
  @mixin bold;
  text-align: center;
}

.CompatibilityBrowsers {
  overflow: hidden;
  margin-top: 64px;
}

.CompatibilityBrowsers-item {
  position: relative;
  float: left;
  width: 20%;
  padding: 16px 0;
}

a.CompatibilityBrowsers-item {
  text-decoration: none;
  color: #3e7ac2;
}

.CompatibilityBrowsers-divider {
  position: absolute;
  right: 0;
  top: 24px;
  height: 64px;
  border-right: 1px solid #ebeef5;
}

.CompatibilityBrowsers-icon {
  width: 64px;
  height: 64px;
  margin: 0 auto;
}

.CompatibilityBrowsers-icon img {
  width: 100%;
  display: block;
}

.CompatibilityBrowsers-text {
  font-size: 14px;
  margin-top: 32px;
  text-align: center;
}
</style>
