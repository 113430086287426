<template>
  <div>
    <div class="complexProd">
      <h4>{{obj.title}}</h4>
      <p>{{obj.text}}</p>
      <img :src="obj.url" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    obj: Object
  }
};
</script>
<style scoped>
.complexProd{
  text-align: center;
  margin-bottom:80px;
}
.complexProd img{
  display:block;
  margin:80px auto 0;
}
.complexProd p{
  color: #808080;
  font-size:16px;
  line-height: 60px;
  text-align: center;
  margin-top:30px;
}
.complexProd h4{
  font-size:30px;
  line-height: 60px;
  color:#333;
  font-weight: normal;
}
</style>
