<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="crmModBanner.bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>{{ crmModBanner.h3 }}</h4>
        <ul>
          <li v-for="(item, index) in crmModBanner.ul" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <div class="crm1">
      <h1 class="bigTitle">{{ crmMod1.title }}</h1>
      <img :src="crmMod1.imgsrc" />
    </div>

    <div class="crm2">
      <div>
        <h1 class="bigTitle">{{ crmMod2.title }}</h1>
        <p class="tp">{{ crmMod2.p }}</p>
        <ul class="ul1">
          <li class="li1" v-for="(item, index) in crmMod2.list" :key="index">
            <div>
              <img :src="item.imgsrc" alt="" />
              <h2>{{ item.h4 }}</h2>
            </div>
            <ul>
              <li v-for="(item, index) in item.taglist" :key="index">
                {{ item }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>

    <div class="crm3">
      <h1 class="bigTitle">{{ crmMod3.title }}</h1>
      <ul>
        <li v-for="(item, index) in crmMod3.list" :key="index">
          <img :src="item.imgsrc" />
          <div style="width: 450px">
            <h3>{{ item.h4 }}</h3>
            <p v-for="(it, idx) in item.plist" :key="idx">
              <span>{{ it.span || "" }}</span
              >{{ it.p }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="btnArea">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </div>

    <div class="crm4">
      <h1 class="bigTitle">{{ crmMod4.title }}</h1>
      <ul>
        <li
          v-for="(item, index) in crmMod4.list"
          :key="index"
          :style="{ 'background-image': 'url(' + item.imgsrc + ')' }"
        >
          <h3>{{ item.h3 }}</h3>
          <p>{{ item.p }}</p>
        </li>
      </ul>
    </div>
    <div class="crm5">
      <h1 class="bigTitle">{{ crmMod5.title }}</h1>
      <p class="tp">{{ crmMod5.p }}</p>
      <img :src="crmMod5.imgsrc" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import Swiper from "@views/product/components/robotSwiper.vue";
import { crm } from "@public/static/json/htmlContent";
export default {
  data() {
    return {
      crmModBanner: crm.banner,
      crmMod1: crm.modular1,
      crmMod2: crm.modular2,
      crmMod3: crm.modular3,
      crmMod4: crm.modular4,
      crmMod5: crm.modular5,
    };
  },
  components: {
    Banner,
    ApplyDialog,
    Swiper,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    // list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.crm1 {
  width: 1200px;
  margin: 0 auto;
  img {
    display: block;
    margin: 0 auto;
  }
}
.crm2 {
  // background: url("./../../assets/img/callCenter/beijing-1.png") no-repeat;
  // background-size: 100% 100%;
  background-position: center;
  padding-top: 50px;
  .tp {
    font-size: 18px;
    color: #999;
    margin-top: 20px;
    text-align: center;
  }
  div {
    width: 1200px;
    margin: 0 auto;
    h1 {
      color: #333;
      margin-bottom: 25px;
      margin-top: 80px;
      font-size: 30px;
    }

    ul.ul1 {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      li.li1 {
        background-color: #fff;
        border-radius: 8px;
        width: 180px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          width: 310px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 100px;
          }
          h2 {
            font-size: 28px;
            margin: 20px 0;
          }
        }
      }
    }
  }
}
.crm4 {
  ul {
    margin: 80px auto 0;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 582px;
      height: 155px;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 15px;
      padding: 20px 0 0 35px;
      h3 {
        font-size: 28px;
        color: #362f59;
      }
      p {
        margin-top: 20px;
        color: #362f59;
      }
    }
  }
}
.crm3 {
  width: 1200px;
  margin: 100px auto 0;
  h1 {
    color: #333;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
  }
  ul {
    margin-top: 100px;
    li {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      margin-bottom: 100px;
    }
    li:nth-child(odd) {
      flex-direction: row-reverse;
    }
    img {
      margin-top: 20px;
    }
    h3 {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
      background: linear-gradient(41deg, #09bbfe 0%, #1684f4 100%);
      border-radius: 30px 0px 30px 0px;
      color: #fff;
      width: fit-content;
      padding: 0 20px;
    }
    p {
      margin-top: 20px;
      font-size: 16px;
      color: #808080;
      span {
        color: #1684f4;
      }
    }
  }
}
.crm5 {
  .tp {
    font-size: 18px;
    color: #999;
    text-align: center;
    margin-top: -20px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
</style>