<!--  -->
<template>
  <div style="margin-bottom: -130px">
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosc"><apply-dialog :buttonColor="'white'"></apply-dialog></div></Banner>
    <div class='wrap card-module' style="margin-bottom: 80px;">
      <ProductTileList title="产品中心" titleText="服务创造价值 存在造就未来" :productList="productList"/>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="我们的优势"></img-list>
      <p-lr-module title="企业通信畅达无忧"  :jsonObj="jsonObj"></p-lr-module> 
      <tb-module :moduleImg="moduleImg3" :moduleText="moduleText3" title="客户案例" lineW="1200px" :titleStyle="false" :imgTitle="saleImg.img6.title" :imgAlt="saleImg.img6.alt"></tb-module>
    </div>
    <Banner :bgImg="bannerImg2" bgcolor="#cde1f5"> <div class="btnPosc"><apply-dialog :buttonColor="'blue'"></apply-dialog></div></Banner>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import ImgList from './components/imgListModule4'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
import ProductTileList from './components/ProductTileList'
import PLrModule from './components/ProductLrModule'
import TbModule from '@/modudel/TbModule.vue'
export default {
  name:'',
  components: {
    Banner,
    ImgList,
    ApplyDialog,
    ProductTileList,
    PLrModule,
    TbModule
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/sale-bannar1.png'),
        bannerImg2:require('../../assets/img/sale-bannar2.png'),
        moduleImg1:require('../../assets/img/sale3.png'),
        listText:[
          {'title':'通话线路','text':'拥有全国大部分城市的通讯资<br/>源线路互联互<br/>备，通信稳定性高'},
          {'title':'类型多样','text':'支持手机号，固话，电销软件<br/>支持CDMA、GSM网络制式；支持<br/>移动、联通、电信三大运营<br/>商；支持连接SIP话机'},
          {'title':'接口灵活','text':'支持多种接入方式，中断sip线路<br/>对接、网关设备对<br/>接、400电话接入等'},
          {'title':'通信费低','text':'根据不同企业用户的个性活<br/>动需求，提供<br/>多种优化的通信套餐'}
        ],
        productList: [
          {
            title:'电话销售专用',
            content:'电话销售专用，适合销售使<br > 用的电销卡与套餐，归属<br />地定制全国地区随便打',
            imgSrc:require('../../assets/img/sale1.png'),
            'imgTitle':this.$store.state.imgJson.sale.img2.title,
            'imgAlt':this.$store.state.imgJson.sale.img2.alt
          },
          {
            title:'定制归属地电销',
            content:'电话销售专用，全国归属地定制',
            imgSrc:require('../../assets/img/sale2.png'),
            'imgTitle':this.$store.state.imgJson.sale.img3.title,
            'imgAlt':this.$store.state.imgJson.sale.img3.alt
          },
        ],
        jsonObj:[
          {
            title:'',
            'text':'• 为企业提供基于固话、手机的软硬一体化的通信解决方案<br>• 支持移动、联通、电信三大运营商<br>• 支持4G全网通，双卡智能切换<br>• 提供开放的API接口，快捷方便接入第三方软件',
            titleNotShow: true,
            imgSrc:require('../../assets/img/sale4.png'),
            'imgTitle':this.$store.state.imgJson.sale.img5.title,
            'imgAlt':this.$store.state.imgJson.sale.img5.alt},
        ],
        moduleImg3:require('../../assets/img/home-module8.png'),
        moduleText3:'超过100000家企业使用我们产品和服务',
        saleImg: this.$store.state.imgJson.sale
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
</style>
<style lang='scss'>
.card-module1{
  .out-module h4{
      background:url('../../assets/img/out-t-bg2.png') no-repeat;
      width:316px;
      padding-left:30px;
  }
  .out-module ul{
    width:445px;
  }
  .out-module img{
    margin-top:0;
  }
}
.card-module{
  .titleS p{
    font-size:18px;
    line-height:40px;
  }
}
</style>
<style lang='scss' >
  //@import url(); 引入公共css类
  .btnPosc .apply-btn{
    width:l82px;
    margin-top:418px;
    border-color:#000;
    color:#333;
  }
</style>
