<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosPo"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap pos-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="多种场景轻松满足"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <p-lr-module title="海报传播 • 裂变增长" :jsonObj="jsonObj"></p-lr-module> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <use-scene :usrSceneBg="usrSceneBg" :scenceText="scenceText" :moduleText="moduleText3"></use-scene> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
// import TbModule from '@/modudel/TbModule.vue'
import ImgList from './components/imgListModule'
import PLrModule from './components/ProductLrModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
import UseScene  from './components/UseScene.vue'
export default {
  name:'',
  components: {
    Banner,
    // TbModule,
    ImgList,
    PLrModule,
    ApplyDialog,
    UseScene
    
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/posterBanner.png'),
        moduleText3:'当商家拥有一定存量的粉丝启动量，即可开启活动。如教育行业可以利用自身产品特性以课程作为活动奖品，吸引用户参与，可以实现老用户带新用户，来获取课程奖励，新用户被课程所吸引也会参与。从而达到商家品牌曝光，高意向客户获取等目的。',
        moduleImg1:require('../../assets/img/pos-img1.png'),
        usrSceneBg:require('../../assets/img/pos-img4.png'),
        listText:[{'title':'海报任务','text':'通过海报来清晰传达任务'},{'title':'专属海报','text':'新粉丝关注公众号获取自己的专属传播海报'} ,{'title':'吸引沉淀','text':'新粉丝被吸引和沉淀在公众号内进行转换'}],
        jsonObj:[
          {title:'一款公众号涨粉工具','text':'以奖品为诱因激励粉丝推荐公众号给好友关注，以此实现公<br />众号涨粉引流。好友被邀请关注后，好友同步收到奖品激励<br />也会参与活动，这样就开启了下一波推荐，从而达到不断传<br />播形成裂变。',imgSrc:require('../../assets/img/pos-img2.png'),'imgTitle':this.$store.state.imgJson.poster.img1.title,'imgAlt':this.$store.state.imgJson.poster.img1.alt},
          {title:'转发海报粉丝暴涨','text':'商家设置好奖品与粉丝需要邀请的人数，粉丝在公众号内获<br />得专属海报，粉丝转发海报给好友，邀请好友关注。好友关<br />注后也可以引导参与，然后进行循环达到粉丝暴涨。',imgSrc:require('../../assets/img/pos-img3.png'),'imgTitle':this.$store.state.imgJson.poster.img2.title,'imgAlt':this.$store.state.imgJson.poster.img2.alt}
        ],
        scenceText:[
          {'title':'教育行业','text':'关心 关爱 责任'},
          {'title':'餐饮行业','text':'创新 创意 美味'},
          {'title':'服务行业','text':'贴心 贴己 顾全'}
        ]
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
</style>
<style lang='scss'>
.pos-module1{
  .out-module h4{
      background:url('../../assets/img/out-t-bg2.png') no-repeat;
      width:316px;
      margin-top:50px;
      padding-left:30px;
  }
  .out-module ul{
    width:445px;
  }
  .out-module img{
    margin-top:0;
  }
}
.btnPosPo .apply-btn{
    color:#fff;
    border-color:#fff;
   width:l82px;
    margin-top:420px;
    font-size:16px;
 }
</style>