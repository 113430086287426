<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosw"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap wx-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="沟通从未如此愉快"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <p-lr-module title="沟通高效 • 更多可能" :jsonObj="jsonObj"></p-lr-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button> 
      <use-scene :usrSceneBg="usrSceneBg" :scenceText="scenceText" :moduleText="moduleText3"></use-scene>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import ImgList from './components/imgListModule'
import PLrModule from './components/ProductLrModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
import UseScene  from './components/UseScene.vue'
 export default {
  name:'',
  components: {
    Banner,
    ImgList,
    PLrModule,
    ApplyDialog,
    UseScene
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/wxBanner.png'),
        moduleText3:'随着电话营销的方式越来越多的受到政策的管控，企业的销售模式会更多的借助微信来与客户沟通，降低电销频率，提升沟通效率、降低电销成本；企业通信电话营销联系到客户，客户表示意向后，销售成功添加客户微信，系统保护销售客户的归属权，销售通过微信与客户沟通，客户信息安全、沟通信息完整保留。',
        moduleImg1:require('../../assets/img/wx-img1.png'),
        moduleImg3:require('../../assets/img/wx-img5.png'),
        listText:[{'title':'沟通多样化','text':'图片、视频、语音等、智能招呼'},{'title':'精细化管理','text':'沟通信息完整保存、无缝对接CRM'} ,{'title':'客户关怀','text':'关注朋友圈、群发客户消息'}],
        jsonObj:[
          {title:'随时沟通 • 便捷高效','text':'• 手机APP和电脑端数据同步，历史沟通数据完整呈现<br />• 用户可以通过文字、图片等多种方式与客户发起沟通信息交流无障碍；常用话术可以快捷的发送，无需编辑文字，选中即刻发送',imgSrc:require('../../assets/img/wx-img2.png'),'imgTitle':this.$store.state.imgJson.wxMarket.img1.title,'imgAlt':this.$store.state.imgJson.wxMarket.img1.alt},
          {title:'客户关怀 • 智能推送','text':'• 随时关注客户的朋友圈，用户可以随时发送朋友圈信息<br />• 制定客户关怀计划，系统智能提醒、只能发送客户关怀',imgSrc:require('../../assets/img/wx-img3.png'),'imgTitle':this.$store.state.imgJson.wxMarket.img2.title,'imgAlt':this.$store.state.imgJson.wxMarket.img2.alt},
           {title:'行业话术 • 提效降本','text':'• 根据行业不同，制定不同的行业话术<br />• 便于用户根据不同客户的属性，快捷、高效的与用户进行沟通<br />• 提升工作效率，降低人力成本',imgSrc:require('../../assets/img/wx-img4.png'),'imgTitle':this.$store.state.imgJson.wxMarket.img3.title,'imgAlt':this.$store.state.imgJson.wxMarket.img3.alt}
        ],
        usrSceneBg:require('../../assets/img/wx-img5.png'),
        scenceText:[
          {'title':'金融服务行业','text':'保障 保险 收益'},
          {'title':'互联网','text':'创新 创意 科技'},
          {'title':'医疗行业','text':'安心 安全 专业'}
        ]
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
</style>
<style lang='scss'>
.wx-module1{
  .out-module h4{
      background:url('../../assets/img/out-t-bg2.png') no-repeat;
      width:316px;
      margin-top:50px;
  }
  .out-module ul{
    width:445px;
  }
  .out-module img{
    margin-top:0;
  }
  .titleS p{
    font-size:18px;
  }
}
.wx-module .titleS p{
  font-size:18px;
}
.btnPosw .apply-btn{
  border-color:#000;
  margin-top:450px;
  color:#333;
  font-size:16px;
}
</style>