<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>企蜂传媒</h4>
        <ul>
          <li class="tit1">全面高效的媒体推广平台</li>
          <li>全网媒体覆盖</li>
          <li>一站式发布</li>
          <li>高效传播曝光</li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>

    <div class="sec1">
      <h5>多重推广能力，高效实现您的推广目标</h5>
      <ul>
        <li>
          <h2>15000+</h2>
          <p>优质网络媒体</p>
        </li>
        <li>
          <h2>100000+</h2>
          <p>自媒体博主</p>
        </li>
        <li>
          <h2>海量可选</h2>
          <p>媒体发布套餐</p>
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class="sec2">
      <img src="./../../assets/img/qfcm1.png" alt />
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class="sec3">
      <h5>
        一站式软文自主发稿平台
        <br />低成本、高曝光、成长迅速
      </h5>
      <ul>
        <li v-for="(item,index) in list0" :key="index">
          <h3>{{item.tit}}</h3>
          <p>{{item.text}}</p>
        </li>
      </ul>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class="qftxResource">
      <div class="container-1200px">
        <h5>整合全网优质媒体营销资源投放渠道</h5>
        <h4>集新闻发稿、软文推广、小红书、微信、微博、抖音、快手等渠道为一体</h4>
        <ul>
          <li v-for="(item,index) in list" :key="index">
            <img :src="item.src" alt />
            <div>
              <h6>{{item.title}}</h6>
              <p>{{item.p}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <button class="applayBtnRed" @click="$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
    <div class="ouerFun">
      <div class="container-1200px">
        <h5>企蜂传媒的优势</h5>
        <ul>
          <li v-for="(item,index) in list2" :key="index">
            <div>
              <h6>{{item.title}}</h6>
              <p>{{item.p}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <apply-dialog-btn
      :modelVisible="$store.state.applyModal"
      @cancle="$store.commit('CHANGE_MODAL_FALSE')"
    ></apply-dialog-btn>
  </div>
</template>
<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
export default {
  data() {
    return {
      bannerImg: require("../../assets/img/qfcmBanner.png"),
      list0: [
        {
          tit: "海量发布媒体资源",
          text:
            "拥有国内15000多家媒体资源，覆盖大多数网络媒体、专业及行业媒体、国内大型户外网站、地方信息港、专业网络门户等多样式的媒体形式。",
        },
        {
          tit: "多维度筛选媒体",
          text:
            "客户可进行频道分类、综合门户、覆盖区域、新闻源、入口级别、发布时间等进行筛选。根据预算随意组合，选择适合企业的软文套餐。",
        },
        {
          tit: "信息传播更快速",
          text:
            "在分秒之间把信息迅速精准投放到指定网络媒体，让信息快速传播，轻松进行品牌口碑关键词布局。",
        },
        {
          tit: "地域覆盖面更广",
          text:
            "采用新闻传播模式进行推广可以产生频繁的新闻曝光率。另外，大量地域媒体 ，可以根据您的地域需求，发布辐射到各行业的精准媒体上。",
        },
        {
          tit: "增加品牌背书",
          text:
            "新闻形式推广，迅速、低成本地提高企业，产品的形象，树立企业正面形象。提升公众对企业产品及服务认知度，塑造良好品牌形象，提高销售转化率。",
        },
        {
          tit: "产品方便搜索展示",
          text:
            "第一时间出现在百度、360搜索等搜索引擎的新闻搜索结果中，并使其排名靠前，使得公司介绍和产品能在被潜在客户看到，提升销量。",
        },
      ],
      list: [
        {
          src: require("./../../assets/img/qfcm-img2.png"),
          title: "网络媒体",
          p:
            "全国2000多家网络媒体优质资源一网打尽；为企业提供软文推广、软文发布软文代写的一站式软文营销服务",
        },
        {
          src: require("./../../assets/img/qfcm-img3.png"),
          title: "微信",
          p:
            "3万多个优质微信自媒体公众号可覆盖200多个行业、500多个领域。依据用户画像，制定专项投放策略。所有媒体主均通过实名认证，消费明细可查，投放省心、安心，更放心",
        },
        {
          src: require("./../../assets/img/qfcm-img4.png"),
          title: "小红书",
          p:
            "各行业创意推广方案、海量真实资源、高转化成功案例，为您打造高效的小红书KOL推广",
        },
        {
          src: require("./../../assets/img/qfcm-img5.png"),
          title: "微博",
          p:
            "大数据精准人群定向，筛选出真正能强化目标品牌个性的微博小红书，为广告主代言，发布微博“软”广告，全面覆盖全网6亿微博用户",
        },
        {
          src: require("./../../assets/img/qfcm-img6.png"),
          title: "短视频",
          p:
            "拥有优质的短视频资源和出色的内容生产能力，享有渠道方更优惠的价格与各种流量扶持政策，让广告主的传播更有优势。",
        },
        {
          src: require("./../../assets/img/qfcm-img7.png"),
          title: "直播",
          p:
            "上万优质直播网红大号资源，覆盖200+行业500+领域多维度自由投放，网红直播一体化服务 ，为品牌营销赋能",
        },
      ],
      list2: [
        {
          title: "多渠道投放",
          p:
            "软文支持小红书、微信、网络媒体、微博等多个渠道投放，为您带来有效的品牌曝光度",
        },
        {
          title: "海量优质资源",
          p: "15000+网络媒体资源，10万+新媒体资源，海量资源为您保证投放效果",
        },
        {
          title: "直达目标群体",
          p: "根据品牌特性及目标人群分析，保证投放效果的高效实现",
        },
        {
          title: "高性价比",
          p: "超全渠道资源，绝对价格优势，帮您节省50%以上推广成本",
        },
        {
          title: "智能投放",
          p: "大数据智能匹配资源，多维度标签筛选精准投放 ",
        },
        {
          title: "流程规范",
          p: "合同、发票等流程规范，资金安全有保证",
        },
      ],
    };
  },
  components: {
    Banner,
    ApplyDialog,
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style lang='scss' scoped>
.sec1 {
  h5 {
    font-size: 30px;
    font-weight: normal;
    color: #333;
    text-align: center;
    margin: 70px 0;
  }
  ul {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    li {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #ccc;
      h2 {
        font-size: 36px;
        color: #ff7167;
        margin-bottom: 20px;
      }
      p {
        font-size: 26px;
      }
    }
    li:last-of-type{
        border: none;
    }
  }
}
.sec2 {
  img {
    width: 100%;
  }
}
.sec3 {
  h5 {
    font-size: 30px;
    font-weight: normal;
    color: #333;
    text-align: center;
    margin: 70px 0;
  }
  ul {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li:hover{
      border: solid 2px #2a74fc;
      h3{
        background-color: #2299ee;
        color: #fff;
      }
    }
    li {
      width: 374px;
      height: 320px;
      border: solid 1px #e7eaed;
      margin-bottom: 40px;
      box-sizing: border-box;
      overflow: hidden;
      h3 {
        height: 100px;
        line-height: 100px;
        background-color: #f4f6fb;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        border-bottom: solid 1px #e7eaed;
        text-align: center;
      }
      p {
        height: 220px;
        line-height: 30px;
        padding: 40px 60px 0;
        text-align: center;
        font-size: 16px;
        background: url(./../../assets/img/qfcmback_1.png) no-repeat;
        background-size: 256px 155px;
        background-position: 120px 64px;
      }
    }
    li:nth-of-type(2) {
      p {
        background: url(./../../assets/img/qfcmback_2.png) no-repeat;
        background-size: 256px 155px;
        background-position: 120px 64px;
      }
    }
    li:nth-of-type(3) {
      p {
        background: url(./../../assets/img/qfcmback_3.png) no-repeat;
        background-size: 256px 155px;
        background-position: 120px 64px;
      }
    }
    li:nth-of-type(4) {
      p {
        background: url(./../../assets/img/qfcmback_4.png) no-repeat;
        background-size: 256px 155px;
        background-position: 120px 64px;
      }
    }
    li:nth-of-type(5) {
      p {
        background: url(./../../assets/img/qfcmback_5.png) no-repeat;
        background-size: 256px 155px;
        background-position: 120px 64px;
      }
    }
    li:nth-of-type(6) {
      p {
        background: url(./../../assets/img/qfcmback_6.png) no-repeat;
        background-size: 256px 155px;
        background-position: 120px 64px;
      }
    }
  }
}
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
  }
  li.tit1 {
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.qftxResource {
  background: #fafcfe;
  //   padding-bottom: 150px;
  overflow: hidden;
  h5 {
    font-size: 30px;
    font-weight: normal;
    color: #333;
    text-align: center;
    margin: 70px 0 20px;
  }
  h4 {
    font-size: 24px;
    color: #666666;
    text-align: center;
    margin-bottom: 70px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      position: relative;
      width: 550px;
      height: 368px;
      div {
        position: absolute;
        top: 145px;
        left: 60px;
        text-align: center;
        h6 {
          font-size: 24px;
          color: #333;
          font-weight: normal;
          margin-bottom: 40px;
        }
        p {
          width: 215px;
          text-align: center;
          font-size: 12px;
          color: #808080;
          line-height: 20px;
        }
      }
    }
  }
}
.ouerFun {
  height: 765px;
  background: url(./../../assets/img/qfcm-img10.png) no-repeat center 0;
  margin-bottom: -50px;
  h5 {
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    height: 190px;
    line-height: 190px;
    color: #fff;
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li:hover {
      background: #9688dd;
      color: #fff;
      div{
        h6:after {
          background: #fff;
        }
      }
    }
    li {
      width: 386px;
      height: 240px;
      margin-bottom: 20px;
      //   color: #fff;
      background: url(./../../assets/img/qfcmback.png) no-repeat;
      background-size: 386px 240px;
      div {
        width: 326px;
        height: 180px;
        margin: 30px 0 0 30px;
        h6 {
          position: relative;
          font-size: 18px;
          text-align: center;
          line-height: 60px;
          font-weight: 400;
        }
        h6:after {
          position: absolute;
          bottom: -4px;
          left: 45%;
          content: "";
          height: 2px;
          width: 34px;
          background: #9688dd;
        }
        p {
          padding-top: 28px;
          font-size: 14px;
          //   color: #fff;
          margin: 0 10px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>