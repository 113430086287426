<template>
  <div :class="['listComment', listClassName]">
    <ul>
      <li v-for="(it, index) in listdata" :key="index">
        <img :src="it.img" />
        <div class="text">
          <h4>{{ it.h4 }}</h4>
          <p>{{ it.p }}</p>
        </div>
        <slot></slot>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "listVue",
  components: {},
  props: {
    listdata: {
      type: Array,
      default: [],
    },
    listClassName: {
      type: String,
      default: "listIndex",
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.listComment {
  margin: 0 auto;
  width: 1200px;
  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      background: #fff;
      display: flex;
      border-radius: 5px;
    }
    li:hover {
      box-shadow: 0px 0px 10px 2px #dee7ef, -1px -1px 10px 2px #dee7ef;
    }
    h4 {
      font-size: 20px;
      color: #333333;
    }
    p {
      color: #999;
      font-size: 14px;
      text-align: justify;
    }
  }
}
.listIndex2 {
  margin-bottom: 60px;
  li {
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    width: 290px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    align-items: center;
    height: 440px;
    overflow: hidden;
    img {
      margin: 53px auto 20px;
      width: 100px;
      height: 100px;
    }
    div {
      padding: 0 15px;
    }
    h4 {
      font-size: 20px;
      margin-top: 10px;
      text-align: center;
      color: #313131;
    }
    p {
      color: #505050;
      text-align: center;
      height: 90px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  li:hover {
    &:after {
      position: absolute;
      top: 0;
      left: 36px;
      width: 227px;
      height: 8px;
      background: #1c63ef;
      content: "";
      border-radius: 5px;
    }
    .btnStyle {
      background: #1c63ef;
      color: #fff !important;
    }
    .applayBtnBlue{
        background:#3E7BF8;
        color:#fff;
    }
  }
}
.listDxxl2 {
  justify-content: space-between;
 
  li {
    width: 587px;
    height: 214px;
    align-items: center;
    border:1px solid #A9CBFE;
    margin-bottom:10px;
    box-sizing: border-box;
    padding-left:10px;
    img{
      display: block;
      width:118px;
      height:118px;
    }
    .text {
      padding-left:10px;
      padding-right:50px;
      width:100%;
      p {
        color: #666;
        font-size:16px;
      }
      h4 {
        font-size:20px;
        color:#1262D4;
        font-weight: bold;
      }
    }
  }
  li:hover{
    background:#F6F9FF;
  }
}
.listDxxl3{
 justify-content: space-around;
 flex-wrap: wrap;
 li{
   width: 385px;
   height: 270px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border:1px solid #E5E5E5;
   margin-bottom:25px;
   p{
     color:#505050;
     width:290px;
     text-align: center;
     font-size:16px;
   }
   img{
     display: block;
     width:100px;
     height:100px;
     margin-bottom:20px;
   }
 }
}
.qfmpmod2{
  margin-top:100px;
  margin-bottom:150px;
  ul{
    display: flex;
    justify-content: space-between;
    margin-top:96px;
    li{
      position: relative;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width:291px;
      border-radius: 10px;
      // box-shadow: 1px 1px 5px 2px #f3f3fb,-1px -1px 5px 2px #f3f3fb;
      box-sizing: border-box;
      padding:87px 37px 28px;
      text-align: center;
      box-sizing: border-box;
      border:1px solid #A9CBFE;
      img{
        display: block;
        margin:0 auto;
      }
      h4{
       margin:20px 0;
       color:#235fdb;
       font-size:20px;
      }
    }
    li:hover:after{
      content:'';
      position:absolute;
      top:0px;
      left:50%;
      height:3px;
      width:52px;
      display: block;
      background:#526efd;
      transform: translate(-26px,0);
    }
  }
}
.listIndex5 {
  justify-content: space-between;
  li {
    width: 385px;
    height: 175px;
    border-radius: none;
    margin-bottom: 15px;
    .text {
      padding: 30px;
      background: #eee;
      width:100%;
      box-sizing:border-box;
      p {
        margin-top: 30px;
        line-height: 28px;
        color: #666;
      }
      h4 {
        position: relative;
      }
      h4:after {
        position: absolute;
        left: 0;
        top: 45px;
        content: "";
        width: 40px;
        height: 4px;
        background: #3e7bf8;
      }
    }
    img {
      display: none;
    }
  }
  li:hover {
    box-shadow: none;
    .text {
      background: #3e7bf8;
    }
    h4:after {
      background: #fff;
    }
    h4,
    p {
      color: #fff;
    }
  }
}

</style>

