<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPos"></div></Banner>
    <div class='wrap'>
      <div class="ip-module">
       <Title title="电信级设备" moduleText="源于大型交换系统支持平行扩容" :titleStyle="false" lineW="900px"></Title>
       <img src="@img/ip-img1.png" :alt="imgJsonIp.img1.alt" :title="imgJsonIp.img1.title">
       <div class="posTitle">
       <Title title="融合的媒体资源" moduleText="支持IP媒体承载和处理" :titleStyle="false" lineW="1200px"></Title>
       </div>
      <img src="@img/ip-img2.png" :alt="imgJsonIp.img3.alt" :title="imgJsonIp.img3.title"/>
      <div class="https-style">
          <div>
            <h4>可靠的交换协议</h4>
            <p>
              支持可编程受控交换或自动交换<br />
              支持SIP网络通信协议<br />
              同一设备内支持ANS标准
            </p>
          </div>
          <div><img src="@img/ip-img3.png" :alt="imgJsonIp.img3.alt" :title="imgJsonIp.img3.title"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import Title from '@/modudel/Title.vue'
export default {
  name:'',
  components: {
    Banner,
    Title
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/ipSwitchbanner.png'),
        imgJsonIp: this.$store.state.imgJson.ipSwitch
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .ip-module{
    position: relative;
    .posTitle{
      position: absolute;
      top:500px;
      left:0;
    }
    .https-style{
      margin-top:100px;
      display: flex;
      justify-content: space-between;
      h4{
        font-size:48px;
        margin-bottom:30px;
      }
      p{
        font-size:24px;
        line-height:60px;
      }
    }
  }
</style>