<template>
    <div class="index618">
      <div class="banner"></div>
      <img src="../../assets/img/pc-img2-618.png" alt="">
      <img src="../../assets/img/pc-img3-618.png" alt="">
      <img src="../../assets/img/pc-img4-618.png" alt="">
    </div>
</template>

<script>
export default {
    name: "",
    components: {},
    props:{},
    data() {
        return {}
    },
    created(){},
    mounted(){},
    methods: {}
}
</script>

<style lang="scss" scoped>
  .index618{
    width:100%;
    background:linear-gradient(to right,#c95ff9,#7967fb);/*new gradient for Webkit*/
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  .banner{
    background:url("../../assets/img/pc-img1-618.png") center top no-repeat;
    padding-top:1317px;
  }
  .index618 img{
    display:block;
    margin:0 auto;
  }
</style>
