<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5">
     <div class="btnPosh"><apply-dialog></apply-dialog></div>
    </Banner>
    <apply-dialog></apply-dialog>
    <div class='wrap hot-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="热点文章植入轻松裂变广告"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <p-lr-module title="热点文章 • 快速分享" :titleText="titleText1" :jsonObj="jsonObj"></p-lr-module> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg3" :moduleText="moduleText3" title="使用场景" lineW="1200px" :titleStyle="false" :imgTitle="this.$store.state.imgJson.hot.img3.alt" :imgAlt="this.$store.state.imgJson.hot.img3.alt"></tb-module>    
    </div> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
       
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
import ImgList from './components/imgListModule'
import PLrModule from './components/ProductLrModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    TbModule,
    ImgList,
    PLrModule,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/hotBanner.png'),
        moduleText3:'销售朋友圈素材可从此应用获取，通过文字植入广告的特性。能够让品牌广告更容易形成传播，此应用适用于微信内存在大量客户资源的销售行业。',
        moduleImg1:require('../../assets/img/hot-img1.png'),
        listText:[{'title':'广告制作','text':'快捷广告制作，支持外部落地页'},{'title':'热点文章','text':'获取媒体高热度文章，形成用户高的关注度'} ,{'title':'曝光引流','text':'以热点文章带动广告曝光，带动流量转化'}],
        titleText1:'热点推广是基于微信文章的一种改造页，用户可植入带有品牌特性的广告图片',
        jsonObj:[
          {title:'热点文章 • 快速转化','text':'通过文章的自带热度，用户打开即可看到广告形成转换。系<br />统每日会推荐不同分类的热度文章，并展示该文章的目标群<br />体，简单的操作流程，就能形成更加有效的曝光。',imgSrc:require('../../assets/img/hot-img2.png'),'imgTitle':this.$store.state.imgJson.hot.img1.title,'imgAlt':this.$store.state.imgJson.hot.img1.alt},
          {title:'方便客户 • 传播品牌','text':'基于微信文章改造，易于终端客户方便、快捷的了解品牌，<br />熟悉企业相关的服务产品；文章本身自带传播属性，通过在<br />好友、朋友圈之间传播，能将广告品牌传播的更加深远。',imgSrc:require('../../assets/img/hot-img3.png'),'imgTitle':this.$store.state.imgJson.hot.img1.title,'imgAlt':this.$store.state.imgJson.hot.img1.alt}
        ],
        moduleImg3:require('../../assets/img/hot-img4.png'),
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
</style>
<style lang='scss'>
.hot-module1{
  .out-module h4{
      background:url('../../assets/img/out-t-bg2.png') no-repeat;
      width:316px;
      padding-left:30px;
  }
  .out-module ul{
    width:445px;
  }
  .out-module img{
    margin-top:0;
  }
}
.hot-module{
  .titleS p{
    font-size:18px;
    line-height:40px;
  }
}
</style>
<style>
  .btnPosh .apply-btn{
     width:l82px;
    margin-top:382px;
    border-color:#000;
    color:#333;
   
  }
</style>
