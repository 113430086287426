<!--  -->
<template>
  <div class='whySelect'>
    <h4 class="whyTitle">加盟资历</h4>
    <p class="whyText">企蜂通信为渠道授权代商量身打造一整套的成长帮扶计划。通过多样化的市场、销售、服务、培训及技术支持，帮助授权代理商快速成长，成为企蜂授权区域销售和服务中心，满足当地日益增长的市场需求，并以开放、融合、共创的合作理念，与各授权代理商共享市场资源，共同为终端用户提升企业营销力，实现三方共赢！</p>
     <img src="@img/what-img.png" :alt="imgJsonAgent.img1.alt" :title="imgJsonAgent.img1.tittle">
  </div>
</template>
<script>
export default {
  name:'',
  components: {},
  props:{},
  data() {
     return {
        imgJsonAgent: this.$store.state.imgJson.busAgent
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .whySelect{
    p.whyText{
      font-size:18px;
      color:#333;
      line-height:48px; 
    }
    img{
      display:block;
      margin:45px auto 0;
      width:1095px;
    }
  }
</style>