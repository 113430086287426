<!--  -->
<template>
  <div>
    <Banner :bgImg="dxkbanner.bannerImg" bgcolor="#cde1f5">
      <div class="dxk-banner-text">
        <h4>{{ dxkbanner.h3 }}</h4>
        <ul>
          <li v-for="(item, index) in dxkbanner.ul" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>
    <h1 class="bigTitle">{{ dxk1.title }}</h1>
    <div class="dxkMod1">
      <ul>
        <li v-for="(item, index) in dxk1.ul" :key="index">
          <h3>{{ item.title }}</h3>
          <p>{{ item.p }}</p>
        </li>
      </ul>
    </div>
    <h1 class="bigTitle" v-html="dxk2.title"></h1>
    <ListMoDule :listdata="dxk2.list" listClassName="listIndex2">
      <apply-dialog :buttonColor="'blueline'"></apply-dialog>
    </ListMoDule>
    <h1 class="bigTitle" v-html="dxk3.title"></h1>
    <CardList :listdata="dxk3.list" />
    <div class="btnArea"><apply-dialog :buttonColor="'blueline'"></apply-dialog></div>
    <div class="dxkMod4">
      <h1 class="bigTitle" v-html="dxk4.title"></h1>
      <ul>
        <li v-for="(item, index) in dxk4.list" :key="index">
          <p v-html="item.p"></p>
        </li>
      </ul>
    </div>
    <h1 class="bigTitle" v-html="dxk5.title"></h1>
    <ListMoDule :listdata="dxk5.list" listClassName="listIndex5"></ListMoDule>
    <div class="btnArea"><apply-dialog :buttonColor="'blueline'"></apply-dialog></div>

    <h1 class="bigTitle" v-html="dxk6.title"></h1>
    <div class="btnArea">
        <img :src="dxk6.img" alt="">
    </div>
  </div>
</template>

<script>
import Banner from "@/modudel/Banner.vue";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import ListMoDule from "@/list.vue";
import CardList from "@/cardList.vue";

import { dxk } from  "@public/static/json/htmlContent";
export default {
  name: "",
  components: {
    Banner,
    ApplyDialog,
    ListMoDule,
    CardList,
  },
  props: {},
  data() {
    return {
      dxkbanner: dxk.banner,
      dxk1: dxk.modular1,
      dxk2: dxk.modular2,
      dxk3: dxk.modular3,
      dxk4: dxk.modular4,
      dxk5: dxk.modular5,
      dxk6: dxk.modular6,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  //方法集合
  methods: {},
};
</script>
<style lang='scss' scoped>
.dxk-banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
    // list-style: inside;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.bigTitle {
  margin: 100px 0 40px;
  font-size: 30px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.dxkMod1 {
  margin: 0 auto 1px;
  background: url("../../assets/images/dxk/img1.png") center no-repeat;
  width: 1169px;
  height: 736px;
  padding-top: 105px;
  ul {
    margin-left: 760px;
    li {
      height: 140px;
      width: 385px;
      margin-bottom: 20px;
      h3 {
        font-size: 20px;
        color: #3f85ff;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    li:nth-child(2) {
      margin-bottom: 35px;
    }
    li:nth-child(3) {
      margin-bottom: 45px;
    }
  }
}
.btnArea {
  margin: 50px auto;
  display: flex;
  justify-content: center;
}
.dxkMod4 {
  padding-top: 1px;
  background: url("../../assets/images/dxk/img2.png") center no-repeat;
  height: 438px;
  .bigTitle {
    margin-top: 70px;
  }
  ul {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    li {
      margin-top: 110px;
      width: 280px;
      margin-left: -10px;
      p {
        font-size: 16px;
        color: #333;
        text-align: center;
      }
    }
    li:last-child {
      margin-right: 10px;
    }
  }
}
</style>

