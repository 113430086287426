<!--  -->
<template>
  <div class='moudle'>
      <div style="margin-top:80px">
      <Title :title="title" :lineW="lineW" :moduleText="moduleText" :titleStyle="titleStyle"></Title>
      </div>
      <div class="img-area">
         <img :src="moduleImg" :alt="imgAlt" :title="imgTitle">
      </div>
  </div> 
</template>

<script>
import Title from './Title.vue'
export default {
  name:'TbModule',
  components: {
    Title
  },
  props:{
    moduleImg:String,
    moduleText:String,
    title:String,
    lineW:String,
    titleW:String,
    titleStyle:{
      type:Boolean,
      default:true
    },
    imgTitle:{
      type:String,
      default:''
    },
    imgAlt:{
      type:String,
      default:''
    }

  },
  data() {
     return {

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  }
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .dian{
    position: absolute;
    bottom:-3px;
    content:'';
    width:5px;
    height:5px;
    border-radius:50%;
    background:#bfbfbf;
    z-index:100;  
  }
  .line{
    position: absolute;
    bottom:-6px;
    content:'';
    width:12px;
    height:1px;
    background:#bfbfbf;
    z-index:100;  
  }
  .moudle{
    margin:0 auto;
    width:1200px;
    .img-area{
      img{
        max-width:1200px;
        display: block;
        margin:0 auto;
      }
    }
    p{
      width:920px;
      margin:0 auto 50px;
      text-align: center;
    }
  }

</style>