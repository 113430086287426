<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"> <div class="btnPosc"><apply-dialog></apply-dialog></div></Banner>
    <div class='wrap card-module'>
      <img-list :moduleImg="moduleImg1" :listText="listText" mouduleTitle ="聚集人脉秒变营销宠儿"></img-list>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <p-lr-module title="电子名片 • 一秒传播"  :jsonObj="jsonObj"></p-lr-module> 
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg3" :moduleText="moduleText3" title="使用场景" lineW="1200px" :titleStyle="false" :imgTitle="imgJsonCard.img4.title" :imgAlt="imgJsonCard.img4.alt"></tb-module>    
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
      </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
import ImgList from './components/imgListModule'
import PLrModule from './components/ProductLrModule'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'',
  components: {
    Banner,
    TbModule,
    ImgList,
    PLrModule,
    ApplyDialog
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/cardBanner.png'),
        moduleImg1:require('../../assets/img/card-img1.png'),
        listText:[{'title':'名片模板化','text':'不同行业、不同模板、自由选择'},{'title':'定制推广','text':'企业门户定制、品牌服务推广'} ,{'title':'客户管理','text':'沟通、筛选、跟进、开发'}],
       jsonObj:[
          {title:'制作简单 • 立体多维','text':'• 模板化的个性名片，风格多样化适用于不同行业和用途<br />•呈现企业的产品信息、公司信息、销售人员信息，并可以根据用户实际情况调整个人、产品以及公司信息的布局和内容',imgSrc:require('../../assets/img/card-img2.png'),'imgTitle':this.$store.state.imgJson.card.img1.title,'imgAlt':this.$store.state.imgJson.card.img1.alt},
          {title:'推广引流 • 提升品牌','text':' • 基于微信10亿的用户流量，借助一定的推广策略实现快速的传播，高效的寻找目标客户<br />• 实时了解客户的关注点，精确获取客户的浏览轨迹，获取高意向客户<br />• 以名片统一的风格、统一的企业介绍、产品介绍，更好的推广企业的品牌文化，提升品牌价值',imgSrc:require('../../assets/img/card-img3.png'),'imgTitle':this.$store.state.imgJson.card.img2.title,'imgAlt':this.$store.state.imgJson.card.img2.alt},
           {title:'客户跟进 • 科学管理','text':'• 通过微信渠道传播，快速发现目标客户<br />• 访客咨询、浏览信息会及时通知销售，销售第一时间了解相关提醒，及时跟进客户、沟通客户，保留完成沟通记录<br />• 根据访客的行为自动分类，同步高意向客户进入CRM系统，进行有效跟进与管理',imgSrc:require('../../assets/img/card-img4.png'),'imgTitle':this.$store.state.imgJson.card.img3.title,'imgAlt':this.$store.state.imgJson.card.img3.alt}
        ],
        moduleText3:'轻松打造个人IP，一键分享至好友、群、朋友圈，裂变式传播；智能跟踪客户轨迹，分析客户购买意向；企业打造动态移动官网，多维度的品牌宣传，立体化的企业形象展示。',
        moduleImg3:require('../../assets/img/card-img5.png'),
        imgJsonCard: this.$store.state.imgJson.card

     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
</style>
<style lang='scss'>
.card-module1{
  .out-module h4{
      background:url('../../assets/img/out-t-bg2.png') no-repeat;
      width:316px;
      padding-left:30px;
  }
  .out-module ul{
    width:445px;
  }
  .out-module img{
    margin-top:0;
  }
}
.card-module{
  .titleS p{
    font-size:18px;
    line-height:40px;
  }
}
</style>
<style lang='scss' >
  //@import url(); 引入公共css类
  .btnPosc .apply-btn{
    width:l82px;
    margin-top:418px;
    border-color:#000;
    color:#333;
  }
</style>