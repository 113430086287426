<template>
  <div>
    <div class="topicBg">
      <span class="leftBg"></span>
      <span class="title">{{title}}</span>
      <span class="rightBg"></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: String
  }
};
</script>
<style scoped>
.topicBg{
  display: flex;
  height:94px;
}
.topicBg .leftBg{
  display: block;
  width:31px;
  height:94px;
  background:url("../../../assets/img/title-img1.png") left top no-repeat;
}
.topicBg .rightBg{
  display: block;
  width:31px;
  height:51px;
  background:url("../../../assets/img/title-img2.png") left top no-repeat;
}
.topicBg .title{
  height:51px;
  font-size:24px;
  color:#fff;
  font-weight: bold;
  background:#66cdf5;
  text-align: center;
  line-height: 51px;
  background-image: linear-gradient(to right,#45aceb, #66cdf5);
}
</style>
