<!--  -->
<template>
<div class="moudule9">
  <div  class="list-data">
    <h3><router-link to="/newsList.do" @click.native="refresh('/newsList.do')">新闻中心</router-link></h3>
    <ul>
      <li v-for="(item,index) in newsList" :key="index">
        <a :href="'/news.do?id=' + item.newsId">{{item.newsTitle}}</a>
      </li>
    </ul>
  </div>
  <!-- <div  class="list-data">
    <h3><router-link to="/newsList.do" @click.native="refresh('/newsList.do')">行业资讯</router-link></h3>
    <ul>
      <li v-for="(item,index) in newsList" :key="index">
        <a :href="'/news.do?id=' + item.newsId">{{item.newsTitle}}</a>
      </li>
    </ul>
  </div> -->
  <!-- <div  class="list-data">
    <h3><a target="_blank" href="http://help.qftx.net:9941/#/">常见问题</a></h3>
    <ul>
      <li v-for="(item,index) in problemList" :key="index">
        <a  target="_blank" :href="item.url" rel="nofollow">{{item.quesTitle}}</a>
      </li>
    </ul>
  </div> -->
</div>  

</template>

<script>
import Api from '@api/system'
import News from '@api/news'
export default {
  name:'',
  components: {},
  props:{
  },
  data() {
    return {
      problemList:[],
      newsList:[{'name':'慧营销与中国电信联手构建云服务 为企业管理强势赋能企业管理强势赋能企业管理强势赋能企业管理强势赋能','url':'www.baidu.com'},{'name':'慧营销与中国电信联手构建云服务 为企业管理强势赋能','url':'www.baidu.com'}]
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getHelp();
    this.getNews();
  },
  mounted() {

  },
  //方法集合
  methods: {
    getHelp(){
      Api.fetchHelpList().then((res)=>{
        this.problemList = res.data.data.list
      })
    },
    getNews(){
      News.fetchHomeList().then((res)=>{
        this.newsList = res.data.data
      })
    },
    refresh(url){
      if(url == "/newsList.do") {
        window.location.href = window.location.origin + url;
      }
    }
  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  a{
    color:#333;
  }
  .moudule9{
    // display: flex;
    margin:0 auto;
    width:1200px;
    // justify-content: space-around
  }
  .list-data{
    margin-top:94px;
    h3{
      font-size:36px;
      color:#333;
      line-height:50px;
      margin-bottom:10px;
    }
    ul{
      font-size:18px;
      width:475px;
      li{
        padding-right:20px;
        width:100%;
        text-overflow: ellipsis;
        white-space:nowrap;
        overflow:hidden;
        line-height:40px;
        color:#333;
        box-sizing: border-box;
      }
      li:hover,a:hover{
        color:#44b5f2;
      }
    }
  }
</style>