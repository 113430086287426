import axios from 'axios'

export default {
    fetchHelpList(params) {
        return axios.get('/help_list.do',{ params })
    },
    fetchLinksList(params) {
        return axios.get('/link_getLinks.do',{ params })
    },
    fetchParamsList(params) {
        return axios.get('/param_getParams.do',{ params })
    }
}