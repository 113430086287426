<!--  -->
<template>
  <div class='wrap'>
    <div  class="module" >
      <h4>{{mouduleTitle}}</h4>
      <div class="modulec-con"  :style="{'background-image':'url('+moduleImg+')'}">
            <!-- <tb-module :moduleImg="moduleImg" moduleText=" " :title="mouduleTitle" lineW="1200px" :titleStyle="false"></tb-module> -->
            <ul>
              <li v-for="(item,index) in listText" :key="index">
                <h5>{{item.title}}</h5>
                <p class="content" v-html="item.text"></p>
              </li>
            </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TbModule from '@/modudel/TbModule.vue'
export default {
  name:'',
  components: {
    TbModule
  },
  props:{
    listText:{
      type:Array,
      default:[]
    },
    moduleImg:String,
    moduleText:{
      type:String,
      default:' '
    },
    mouduleTitle:String,
  },
  data() {
     return {
     };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
    .module{
    position: relative;
    h4{    
      text-align: center;
      font-size:36px;
      color:#333;
      height:60px;
      margin:80px 0 60px 0;
      }
    .modulec-con{
      background-position: center center;
      background-repeat: no-repeat;
      background-size:contain;
      width: 1200px;
      height:530px;
      color: #000;
      ul{
        display: flex;
        justify-content: space-between;
        width:1200px;
        padding-top:280px;
      }
      li{
        width:50%;
        color:#333;
        font-size:12px;
        text-align: center;
        h5{
          font-size:26px;
          color: #000;
        }
        .content{
          color: #666;
          margin-top: 20px;
          line-height: 32px;
        }
      }
    }
  }
</style>