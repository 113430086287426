<template>
  <div class="banner" :style="{'background-image':'url('+banner.bannerImg+')'}">
    <div class="container-1200px">
      <p class="bannerTitle">{{banner.title}}</p>
       <div class="bannerList" :class="[type=='robot'?'robotBannerList':'']">
         <p v-for="(item,index) in banner.list" :key="index" v-html="item"></p>
       </div>
        <div class="baBtn">
          <!-- <DivButton v-if="type=='agent'" type="primary" :agent="true">申请加盟</DivButton> -->
          <apply-dialog :buttonColor="'#fff'"></apply-dialog>
        </div>
    </div>
  </div>
</template>
<script>
import ApplyDialog from "@/modudel/ApplyDialog.vue";
export default {
  name:'banner',
  props:{
    banner:{
      type:Object,
      default:{}
    },
    type:{
      type:String,
      default:''
    }
  },
  components:{ApplyDialog},
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>

  .banner{
    background-repeat: no-repeat;
    background-position: top center;
    height:575px;
    color:#fff;
  }
  .bannerList{
    height:230px;
    font-size:24px;
    line-height:35px;
    // padding-left:20px;
    p{
      margin-bottom:23px;
    }
  }
  .bannerTitle{
    padding-top:90px;
    font-size:44px;
    font-weight: bold;
    margin-bottom:34px;
  }
  .bannerBtn{
    width:180px;
    height:51px;
    font-size:24px;
    border:1px solid #fff;
    background:none;
    color:#fff;
    cursor: pointer;
  }
  .bannerBtn:hover{
    background:none;
    color:none;
  }
  .baBtn{
    margin-top:20px;
  }
  .bannerStyle2{
    background:#fff;
    border-radius: 30px;
    color:#fb7322;
 
  }
  .robotBannerList{
  display: flex;
  flex-wrap: wrap;
  width: 424px;
  height: 104px;
  margin-bottom: 126px;
  p{
    width: 200px;
    height: 40px;
    border-radius: 24px;
    margin-bottom: 16px;
    margin-left: 12px;
    font-size: 20px;
    border: 1px solid #fff;
    text-align: center;
    span{
    }
  }
}
</style>
