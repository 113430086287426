<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#2299ee">
        <div class="banner-text">
          <h4>电销系统</h4>
          <p>一个盒子一张卡，外呼就是那么简单高效</p>
          <ul>
            <li>支持4G全网通卡</li>
            <li>支持SIP固话号码</li>
            <li>安装便捷、快速接入</li>
            <li>信号稳定，音质清晰</li>
          </ul>
        </div>
        <apply-dialog></apply-dialog>
    </Banner>
    <div class='wrap'> 
      <div class="out-module1">
         <img src="@img/out-img1.png" :alt="imgJsonOutbound.img1.alt" :title="imgJsonOutbound.img1.title">
          <div class="applyBtnredPos">
            <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
          </div>
         <p>还在等什么？赶快拿起手中的电话，赶紧联系我们吧！<span>18958188698</span></p>
      </div>
      
      <div class="out-module2">
          <h3 class="outTitle">产品优势</h3>
          <ul>
            <li v-for="(item,index) in prodAdvantList" :key="'f'+index">
              <h4>{{item.title}}</h4>
              <p>{{item.text}}</p>
            </li>
          </ul>
      </div>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <div class="out-module3">
        <h3 class="outTitle">适用场景</h3>
        <Module title="销售服务" :salesSerText="salesSerText" :topicIMg="topicIMg1" :imgTitle="imgJsonOutbound.img2.title" :imgAlt="imgJsonOutbound.img2.alt"></Module>
        <Module title="售后服务" :salesSerText="afterSaleList" flexDrect="row-reverse" :topicIMg="topicIMg2" :imgTitle="imgJsonOutbound.img3.title" :imgAlt="imgJsonOutbound.img3.alt"></Module>
      </div>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <div class="out-module4">
          <h3 class="outTitle">产品主要功能</h3>
          <ul>
            <li v-for="(item,index) in imporProdlist" :key="'i'+index">{{item}}</li>
          </ul>
      </div>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
      <div style="margin-top:80px;">
        <news-problem></news-problem>
      </div>
    </div>
    <apply-dialog-btn
      :modelVisible="$store.state.applyModal"
      @cancle="$store.commit('CHANGE_MODAL_FALSE')"
    ></apply-dialog-btn>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import { prodAdvantage,salesService,afterSaleList,imporProdlist } from "./json"
import Module from "./components/module"
import newsProblem from '@/modudel/newsOrProblem.vue'
import ApplyDialog from '@/modudel/ApplyDialog.vue'
export default {
  name:'home',
  components: {
    Banner,
    Module,
    newsProblem,
    ApplyDialog
  },
  data() {
     return {
        bannerImg:require('../../assets/img/outBanner.png'), 
        topicIMg1:require('../../assets/img/out-img3.png'), 
        topicIMg2:require('../../assets/img/out-img4.png'), 
        prodAdvantList:[],
        salesSerText:[],
        afterSaleList:[],
        imporProdlist:[],
        imgJsonOutbound: this.$store.state.imgJson.outbound
     };
  },
  computed: {},
  watch: {},
  created() {
     this.prodAdvantList = prodAdvantage;
     this.salesSerText= salesService
     this.afterSaleList = afterSaleList
     this.imporProdlist = imporProdlist
  },
  mounted() {

  },
  //方法集合
  methods: {
    openForm(){
      this.modelVisible = true
    },
    cancle(){
      this.modelVisible = false
    }
  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .wrap{
    margin:0 auto ;
    width:1200px;
    display: flex;
    flex-wrap:wrap;
  }
  .p{
      color:#808080;
      line-height:30px;
  }
  .banner-text{
    padding-top:120px;
    color:#fff;
    h4{
      font-size:40px;
      
      line-height:80px;
    }
    li{
      // height:50px;
      font-size:18px;
      line-height:36px;
    }
    p{
      font-size:20px;
      margin-bottom:40px;
    }
  }

  .outTitle{ 
    margin:150px 0 70px 0;
    text-align: center;
    font-size:43px;
    color:#333;
  }
  .out-module1{
      position: relative;
      margin-top:155px;
      .applyBtnredPos{
        width:222px;
        position: absolute;
        bottom:118px;left:50%;
        transform: translate(-50%,0)
      }
      p{
        width:100%; 
        position: absolute;
        bottom:0;left:0;
        $height:122px;
        line-height:$height;
        text-align:center;
        color:#fff;
        font-size:24px;
        span{
          font-size:30px;
          color:#000;
          font-weight: bold;
        }

      }
  }
  .out-module2{
    ul{
      display:flex;
      width:1200px;
      height:340px;
      padding-top: 178px;
      box-sizing: border-box;
      background:url('../../assets/img/out-img2.png') 0 0 no-repeat;  
      li{
        padding:0 40px;
        width:280px;
        
        margin-right:28px;
        text-align: center;
        box-sizing: border-box;
        h4{
          font-size:24px;
          color:#fff;
          line-height:64px;
        }
        p{
          color:#fff;
          font-size:16px;
          line-height:30px;
        }
      }
      li:last-child{
        margin-right:0;
      }
    
    }
  }
  .out-module4{
    margin-bottom:50px;
    width:100%;
    ul{
      @extend .wrap;  
      margin:140px auto 0;
      width:1100px;
      height:424px;
      background:url('../../assets/img/fun-icon.png') center center no-repeat;  
      // justify-content: space-between;
      li{
        box-sizing: border-box;
        width:80px;
        height:173px;
        padding-top:90px;
        text-align: center; 
        font-size:16px;
        margin-right:175px;
        
      }
      li:nth-child(5n+5) {
          margin-right: 0;
      }
    }
  }

</style>