<template>
  <div>
    <div class="zqnbanner">
      <div class="zqnBox">
        <ApplyDialog></ApplyDialog>
      </div>
    </div>
    <div class="container-1200px">
      <div class="zanMd2">
        <TitleAssembly :titleObj="zqnmodular2" />
        <ul>
          <li v-for="(item, index) in zqnmodular2.list" :key="index">
            <img :src="item.icon" :alt="item.alt" />
            <h4>{{ item.title }}</h4>
            <p>{{ item.p }}</p>
          </li>
        </ul>
      </div>
      <div class="zqnBtn">
         <apply-dialog mycolor="#235fdb" ></apply-dialog>
      </div>
       <div class="zanMd3">
        <TitleAssembly :titleObj="zqnmodular3" />
        <ul>
          <li v-for="(item, index) in zqnmodular3.list" :key="index" >
            <img :src="item.icon" :alt="item.alt" />
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.p }}</p>
            </div>
          </li>
        </ul>
      </div>
       <div class="zqnBtn">
         <apply-dialog mycolor="#235fdb" ></apply-dialog>
      </div>
       <div class="titleImgMod4 titleImgModO">
      <TitleAssembly :titleObj="zqnmodular4" />
      <ul>
        <li v-for="(item, index) in zqnmodular4.list" :key="index">
          <div class="liDiv">
            <img :src="item.img" :alt="item.alt" />
            <div>
              <h4>{{ item.h4 }}</h4>
              <p>{{ item.p }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
     <div class="zqnBtn">
         <apply-dialog mycolor="#235fdb" ></apply-dialog>
      </div>
     <div class="titleImgMod4 titleImgMod5">
      <TitleAssembly :titleObj="zqnmodular5" />
      <ul>
        <li v-for="(item, index) in zqnmodular5.list" :key="index" :id="'zqn'+index">
          <div class="liDiv">
            <img :src="item.img" :alt="item.alt" />
            <div>
              <h4>{{ item.h4 }}</h4>
              <p v-for="(it, idx) in item.list" :key="idx" v-html="it"></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    </div>
  </div>
</template>

<script>
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import TitleAssembly from "@/zqn/TitleAssembly";
import { zqn } from "@public/static/json/htmlContent";
export default {
  name: "zqn",
  components: { ApplyDialog,TitleAssembly },
  props: {},
  data() {
    return {
      zqnmodular2: zqn.modular2,
      zqnmodular3: zqn.modular3,
      zqnmodular4: zqn.modular4,
      zqnmodular5: zqn.modular5,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.zqnbanner {
  height: 575px;
  background: url("../../assets/img/zqn/banner1.png") no-repeat center center;
}
.zqnBox {
  margin: 0 auto;
  padding-top: 320px;
  width: 1200px;
}
</style>
<style lang="scss">
.container-1200px{
  margin:0 auto;
  width:1200px;
}
.bannerSwiper {
  height: 575px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: left;
  color: #fff;
  h4 {
    font-size: 44px;
    font-weight: bold;
    color: #fff;
  }
  p {
    font-size: 24px;
    line-height: 46px;
  }
  .bannerSwipercon {
    height: 100%;
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    .btn {
      .btnApplyArea {
        width: 200px;
      }
      margin-top: 120px;
      button {
        border-color: #fff;
        color: #fff;
      }
    }
  }
}
.titleImgMod {
  margin-top: 50px;
  img {
    display: block;
    margin-top: 70px;
  }
}
.zanMd2 {
  margin-top: 7 0px;
  // margin-bottom:100px;
  ul {
    display: flex;
    justify-content: space-between;
    margin-top: 96px;
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width: 384px;
      border-radius: 10px;
      box-shadow: 1px 1px 5px 2px #f3f3fb, -1px -1px 5px 2px #f3f3fb;
      box-sizing: border-box;
      padding: 25px 37px 20px;
      text-align: center;
      box-sizing: border-box;
      img {
        display: block;
        margin: 0 auto;
      }
      h4 {
        margin-top: 40px;
        color: #235fdb;
        font-size: 20px;
      }
    }
    li:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 50%;
      height: 3px;
      width: 52px;
      display: block;
      background: #526efd;
      transform: translate(-26px, 0);
    }
  }
}

.titleImgMod4 {
  li .liDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    h4 {
      font-size: 18px;
      color: #1262d4;
      // font-weight: bold;
    }
    p {
      position: relative;
      color: #36373b;
      font-size: 14px;
      line-height: 28px;
      padding-left: 10px;
      span {
        color: #333;
        font-weight: bold;
      }
    }
    p:after {
      position: absolute;
      top: 6px;
      left: 0;
      content: "";
      background: #125bcc;
      width: 2px;
      height: 15px;
      display: block;
    }
  }
  li:nth-child(odd) {
    background: #f4f7fe;
    padding: 50px 0;
  }
  li:nth-child(even) .liDiv {
    flex-direction: row-reverse;
  }
}
.titleImgModO li:nth-child(odd) {
  background: none;
  padding: 0;
}
.zanMd3 {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    li {
      width: 40%;
      display: flex;
      align-items: center;
      h4 {
        color: #333;
        font-weight: bold;
      }
    }
  }
}
.zqnBtn{
  display:flex;
  justify-content: center;
}
</style>

