<!--  -->
<template>
  <div>
    <Banner :bgImg="bannerImg" bgcolor="#cde1f5"></Banner>
    <div class='wrap'>
      <tb-module :moduleImg="moduleImg1" :moduleText="moduleText1" title="即插即用" lineW="1200px" :titleStyle="false" :imgTitl="imgJsonTalk.img1.title" :imgALt="imgJsonTalk.img1.alt"></tb-module>
      <tb-module :moduleImg="moduleImg2" :moduleText="moduleText2" title="通话录音" lineW="1200px" :titleStyle="false" :imgTitl="imgJsonTalk.img2.title" :imgALt="imgJsonTalk.img2.alt"></tb-module>
      <tb-module :moduleImg="moduleImg3" :moduleText="moduleText3" title="通信网络" lineW="1200px" :titleStyle="false" :imgTitl="imgJsonTalk.img3.title" :imgALt="imgJsonTalk.img3.alt"></tb-module>
      <tb-module :moduleImg="moduleImg4" :moduleText="moduleText4" title=" 接口能力" lineW="1200px" :titleStyle="false" :imgTitl="imgJsonTalk.img4.title" :imgALt="imgJsonTalk.img4.alt"></tb-module>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
export default {
  name:'',
  components: {
    Banner,
    TbModule,
  },
  props:{},
  data() {
     return {
        bannerImg:require('../../assets/img/huiTalkBanner.png'),
        moduleText1:'傻瓜式的组装，无需说明即能独立完成无需安装任何驱动、系统',
        moduleText2:'实现自动录音，播放通话录音；通话声音清晰、信号稳定；抗干扰能力强',
        moduleText3:'支持CDMA、GSM网络制式；支持移动、联通、电信三大运营商；支持连接SIP话机',
        moduleText4:'开放的API接口；H型慧营销专用版支持慧营销系统；S型通用版对接第三方系统',
        moduleImg1:require('../../assets/img/talk-img1.png'),
        moduleImg2:require('../../assets/img/talk-img2.png'),
        moduleImg3:require('../../assets/img/talk-img3.png'),
        moduleImg4:require('../../assets/img/talk-img4.png'),
        imgJsonTalk: this.$store.state.imgJson.huiTalk
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
  
  
</style>
<style>
  .form-moudule .titleS p{
      font-size:18px;
  }
</style>