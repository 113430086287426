<template>
  <div class="list" :class="{'listBg':listBg}">
    <ul :class="ulListStyle" class="container-1200px">
      <li v-for="(item,index) in ITlistObj" :key="index" :style="{ 'background-color': item.bg||'' }">
        <img :src="item.img" :alt="item.alt" :title="item.imgTitle" />
        <h4 v-if="item.p1">{{item.p1}}</h4>
        <p v-if="item.p2" v-html="item.p2"></p>
        <p class="p3" v-if="item.p3" v-html="item.p3"></p>
        <p class="childrenList" v-if="item.childrenList" v-for="(it,ind) in item.childrenList" :key="ind">
          <!-- <b><span class="round"></span>{{ it.title }}：</b><span class="desp">{{ it.titleP }}</span> -->
          <span v-if="it.title">{{ it.title }}：</span>{{ it.titleP }}
        </p>
      </li>
    </ul>
  </div> 
</template>
<script>
export default {
  name:'imgTextList',
  props:{
    ITlistObj:{
      type:Array,
      default:[]
    },
    ulListStyle:{
      type:String,
      default:'itUlList'
    },
    listBg:{
      type:Boolean,
      default:false
    }
  },
  components:{},
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>

  //慧营家 md4
  .hyjMd4style{
      margin-top:0px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
         width:390px;
         height:173px;
         margin-bottom:20px;
         padding:30px 18px;
         box-sizing: border-box;
         h4{
          color:#333;
          font-weight: bold;
          padding-left:50px;
          font-size:22px;
         }
         p{
          color:#666666;
          font-size:16px;
          padding-left:50px;
         }
         img{
          float: left;
         }
      }
  }
  // 慧营家m5
.hyjMd5style{
  margin-top:50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li{
    box-sizing: border-box;
    color:#fff;
    width:240px;
    h4{
      color:#fff;
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      margin-bottom:10px;
    }
    p{
      font-size: 16px;line-height: 24px;
      text-align: center;
      letter-spacing: 0.24px;
    }
  }
}
// 慧营家md6
.hyjMd6style{
  margin-top:50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li{
    box-sizing: border-box;
    color:#fff;
    width:240px;
    background: url("../../../assets/images/hyj/bg2.png") top center no-repeat;
    width:594px;
    height:181px;
    padding:30px 50px;
    box-sizing: border-box;
    margin-bottom:15px;
    h4{
      font-weight: bold;
      text-align: left;
      color: #333333;
      font-size: 22px;
    }
    p{
      font-weight: 400;
      text-align: justifyLeft;
      color: #666666;
      font-size: 16px;
    }
  }
}
</style>
<style lang="less" scoped>

</style>